import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { InputTextareaComponent } from "../../input-textarea/input-textarea.component";
import { PatientCareStore } from "src/app/ocp/patient-care/services/patient-care.store";
import { StatusMessageService } from "../../status-message/status-message.service";
import { RxService } from "../../../services/rx.service";
import { RxOrderModel } from "../../../models/rx-order.model";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { ISearchService } from "../../patient-search/search.interface";
import { DiagnosisSearchService } from "src/app/ocp/patient-care/components/form-patient-diagnosis/diagnosis-search.service";
import { AdvancedSearch } from "../../advanced-search/advanced-search.component";
import { DiagnosisSearchResultModel } from "../../../models/diagnosis-search-results.model";
import * as _ from "lodash";
import { DiagnosisService } from "src/app/ocp/patient-care/components/form-patient-diagnosis/diagnosis.service";

@Component({
	selector: "ocp-form-erx-step-two",
	templateUrl: "./form-erx-step-two.component.html",
	providers: [{ provide: ISearchService, useClass: DiagnosisSearchService }],
	styleUrls: ["./form-erx-step-two.component.scss"]
})
export class FormErxStepTwoComponent implements OnInit {
	quantity: number;
	refills: number;
	daysSupply: number;
	MAX_PHARMACY_NOTES_LENGTH: number = 150;
	pharmacyNotesLengthExceeded: boolean = false;
	isDisplayingLengthError: boolean = false;

	@Input() isPreference: boolean;
	@Input() rx: RxOrderModel;
	@Input() showIcdSelect: boolean;
	@Input() isReadOnly: boolean = false;

	@Output() unitsChanged: EventEmitter<number> = new EventEmitter<number>();

	@ViewChild("pharmacyNotesInput")
	pharmacyNotesInput: InputTextareaComponent;

	@ViewChild("diagnosisSearch")
	diagnosisSearch: AdvancedSearch;

	@HostListener("window:keydown", ["$event"])
	keyEvent(event: KeyboardEvent) {
		let activeElId = null;
		try {
			activeElId = document.activeElement.parentNode.parentNode["id"];
			if (event.key == "#" && activeElId == "pharmacyNotes") {
				document.getElementById("preFormattedErxMenuTrigger").click();
			}
		} catch (ex) {
			return;
		}
	}

	primaryICDs: DiagnosisSearchResultModel[] = null;
	diseaseStates: string[] = [];
	icd: string;
	diseaseState: string;
	tableHeaderLabels: string[] = ["ICD", "Name", "Disease State"];

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return [this.icd, this.diseaseState].reduce((acc, val) => {
			return val && val.length > 0 ? ++acc : acc;
		}, 0);
	}

	get icdSelectInvalid(): boolean {
		return this.showIcdSelect && this.isScheduled && (!this.rx.ICD10 || this.rx.ICD10.trim().length === 0);
	}

	get isScheduled(): boolean {
		return this.rx.IsControl;
	}

	constructor(
		private _patientStore: PatientCareStore,
		private _statusMessageService: StatusMessageService,
		public rxService: RxService,
		public erxStore: ErxOrderStore,
		private _diagnosisService: DiagnosisService
	) {
		this.rxService.getPreFormattedERxOptions();
	}

	ngOnInit(): void {
		if (this.rx.Directions) {
			this.rx.Directions = this.rx.Directions.replace("INF", "");
			this.rx.Directions = this.rx.Directions.replace("***COVERED***", "");
			this.rx.Directions = this.rx.Directions.replace("***NON-COVERED***", "");
		}

		this._diagnosisService.getTopFiveDiagnosis().then((results: DiagnosisSearchResultModel[]) => {
			this.primaryICDs = results;
		});
	}

	insertPreformattedERxNote(noteText: string) {
		this.handlePreformattedNotInsertDerivation(noteText);
	}

	insertOppcBilling() {
		let [memberId, bin, pcn] = this._patientStore.getOppcBillingInfo();
		let oppcBillingStr = `Bin: ${bin}, PCN: ${pcn}, Member ID: ${memberId}`;
		this.handlePreformattedNotInsertDerivation(oppcBillingStr);
	}

	ignoreNextSearchFocus: boolean = false;
	icdSearchFocus() {
		if (!this.ignoreNextSearchFocus) {
			this.ignoreNextSearchFocus = false;
			this.diagnosisSearch.handleSearchResults(this.primaryICDs);
			let search = this.diagnosisSearch.searchText;
			if (!search || !search.value || search.value.length == 0) {
				this.diagnosisSearch.searching = true;
				this.diagnosisSearch.handleSearchResults(this.primaryICDs);
			}
		} else {
			this.ignoreNextSearchFocus = false;
		}
	}

	searchDisplayTextFn: Function = (diagnosis: DiagnosisSearchResultModel) => {
		return diagnosis.IcdCode + " - " + diagnosis.IcdName;
	};

	clearFilters() {
		this.icd = null;
		this.diseaseState = null;
		this.filterSearch();
	}

	changeDx() {
		this.rx.diagnosisId = null;
		this.rx.diagnosisName = "";
		this.rx.ICD10 = null;

		setTimeout(() => {
			this.icdSearchFocus();
		}, 300);
	}

	diagnosisSearchCleared() {
		this.rx.diagnosisId = null;
		this.rx.diagnosisName = "";
		this.rx.ICD10 = null;
		this.clearFilters();
		this.ignoreNextSearchFocus = true;
	}

	loadSelectedItem(selectedItem: DiagnosisSearchResultModel) {
		this.rx.ICD10 = selectedItem.IcdCode;
		this.rx.diagnosisName = selectedItem.IcdName;
		this.erxStore.getDosespotDiagnosis(selectedItem.IcdCode).then((id: number) => {
			if (id && id > 0) {
				this.rx.diagnosisId = id;
			} else {
				this.rx.diagnosisId = null;
				this.rx.ICD10 = null;
				this.rx.diagnosisName = "";
				this.diagnosisSearch.clearSearch();
				this._statusMessageService.changeStatusMessage("error", "Unable to find a matching DoseSpot diagnosis. Please try again.", 5000);
			}

			this.ignoreNextSearchFocus = false;
		});
	}

	filterSearch() {
		this.diagnosisSearch.filteredAutoCompleteResults = _.filter(this.diagnosisSearch.autocompleteResults, r => {
			return (
				(!this.icd || (r.IcdCode && r.IcdCode.toLowerCase().indexOf(this.icd.toLowerCase()) !== -1)) &&
				(!this.diseaseState || (r.DiseaseStateName && r.DiseaseStateName.toLowerCase().indexOf(this.diseaseState.toLowerCase()) !== -1))
			);
		});

		this.diseaseStates = _.reduce(
			_.map(this.diagnosisSearch.autocompleteResults, r => r.DiseaseStateName),
			(acc: string[], s: string) => {
				if (acc.indexOf(s) === -1) {
					acc.push(s);
				}
				return acc;
			},
			[]
		);
	}

	private handlePreformattedNotInsertDerivation(noteText: string) {
		let pNotes = this.rx.PharmacyNotes;
		let curIdx = (pNotes || "").length;

		if (curIdx == 0) {
			this.pharmacyNotesInput.writeValue("");
		} else {
			if (pNotes[curIdx - 1] == "#") {
				pNotes = pNotes.substring(0, curIdx - 1) + pNotes.substring(curIdx, pNotes.length);
			}

			this.pharmacyNotesInput.writeValue(pNotes);

			if (curIdx != 1) {
				noteText = `, ${noteText}`;
			}
		}

		this.pharmacyNotesInput.appendText(noteText);
	}

	checkIfNonZeroNumber(num: string): boolean {
		return +num > 0;
	}

	pharmacyNotesValChanged(val: string) {
		this.pharmacyNotesLengthExceeded = (val || "").length > this.MAX_PHARMACY_NOTES_LENGTH;

		if (this.pharmacyNotesLengthExceeded && !this.isDisplayingLengthError) {
			let notificationMs = 10000;

			this.isDisplayingLengthError = true;
			this._statusMessageService.changeStatusMessage("error", "Pharmacy notes length of 150 characters has been exceeded.", notificationMs);

			setTimeout(() => {
				this.isDisplayingLengthError = false;
			}, notificationMs);
		}
	}

	handleIcdSelectClick() {
		if (!this.filtering && !this.isReadOnly) {
			this.icdSearchFocus();
		}
	}
}
