import { Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener } from "@angular/core";
import { StatusMessageService } from "../../../../shared/components/status-message/status-message.service";
import { RxService } from "../../../../shared/services/rx.service";
import { CustomerService } from "src/app/ocp/shared/services/customer.service";
import { Clinician } from "../../../../shared/models/clinician-model";
import { UserService } from "src/app/ocp/shared/services/user.service";
import { PatientMedicationModel } from "src/app/ocp/shared/models/patient-medication.model";
import { UserInfoModel } from "src/app/ocp/shared/models/user-info.model";
import { InputTextareaComponent } from "src/app/ocp/shared/components/input-textarea/input-textarea.component";
import { PatientCareStore } from "../../../services/patient-care.store";
import { DiagnosisSearchResultModel } from "src/app/ocp/shared/models/diagnosis-search-results.model";
import { AdvancedSearch } from "src/app/ocp/shared/components/advanced-search/advanced-search.component";
import * as _ from "lodash";
import { ISearchService } from "src/app/ocp/shared/components/patient-search/search.interface";
import { DiagnosisSearchService } from "../../form-patient-diagnosis/diagnosis-search.service";
import { CustomerProfileModel } from "src/app/ocp/shared/models/customer-profile.model";
import { DiagnosisService } from "../../form-patient-diagnosis/diagnosis.service";

@Component({
	selector: "form-add-erx",
	templateUrl: "./form-add-erx.component.html",
	providers: [{ provide: ISearchService, useClass: DiagnosisSearchService }],
	styleUrls: ["./form-add-erx.component.scss"]
})
export class FormAddErxComponent implements OnInit {
	@ViewChild("pharmacyNotesInput")
	pharmacyNotesInput: InputTextareaComponent;

	@ViewChild("diagnosisSearch")
	diagnosisSearch: AdvancedSearch;

	@HostListener("window:keydown", ["$event"])
	keyEvent(event: KeyboardEvent) {
		let activeElId = null;
		try {
			activeElId = document.activeElement.parentNode.parentNode["id"];
			if (event.key == "#" && activeElId == "pharmacyNotes") {
				document.getElementById("preFormattedErxMenuTrigger").click();
			}
		} catch (ex) {
			return;
		}
	}

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return [this.icd, this.diseaseState].reduce((acc, val) => {
			return val && val.length > 0 ? ++acc : acc;
		}, 0);
	}

	get isScheduled(): boolean {
		return this.drug.DeaClass >= 2;
	}

	get icdSelectInvalid(): boolean {
		return this.showIcdSelect && this.isScheduled && !this.drug.ICD10;
	}

	MAX_PHARMACY_NOTES_LENGTH: number = 150;
	pharmacyNotesLengthExceeded: boolean = false;

	searchDisplayTextFn: Function = (diagnosis: DiagnosisSearchResultModel) => {
		return diagnosis.IcdCode + " - " + diagnosis.IcdName;
	};

	isEditing: boolean = false;
	tableHeaderLabels: string[] = ["ICD", "Name", "Disease State"];
	diseaseStates: string[] = [];
	icd: string;
	diseaseState: string;
	preformattedMenuKeydownActivated: boolean = false;
	saving: boolean = false;
	quantity: number;
	refills: number;
	daysSupply: number;
	useDefaultPharmacy: boolean = false;
	defaultPharmName: string = "";
	customerDefaultPharmacyNABP: string = "";
	primaryICDs: DiagnosisSearchResultModel[] = null;
	showIcdSelect: boolean;

	@Input() patientId: number;
	@Input() drug: PatientMedicationModel;

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

	clinicians: any[] = null;
	clinicianId: string;
	cliniciansLoading: boolean = true;

	constructor(
		public rxService: RxService,
		private _userService: UserService,
		private _statusMessageService: StatusMessageService,
		private _patientStore: PatientCareStore,
		private _customerService: CustomerService,
		private _diagnosisService: DiagnosisService
	) {
		this.rxService.getPreFormattedERxOptions();
		this.rxService.getDispenseUnitTypes();
	}

	ngOnInit() {
		this.drug = Object.assign(new PatientMedicationModel(), this.drug);

		if (this.drug.Directions) {
			this.drug.Directions = this.drug.Directions.replace("INF", "");
			this.drug.Directions = this.drug.Directions.replace("***COVERED***", "");
			this.drug.Directions = this.drug.Directions.replace("***NON-COVERED***", "");
		}
		this._customerService.getCustomerClinicians(this._userService.selectedCustomerId).subscribe(
			(results: Clinician[]) => {
				const options: any[] = [];
				for (let i = 0; i < results.length; i++) {
					options.push({ text: results[i].lastName + ", " + results[i].firstName, value: results[i].userId });
				}

				this.clinicians = _.orderBy(options, ['text']);
				this.cliniciansLoading = false;
			},
			err => (this.cliniciansLoading = false),
			() => (this.cliniciansLoading = false)
		);

		this._userService.userInfo.subscribe((u: UserInfoModel) => {
			this.defaultPharmName = u.PharmacyName;
			this.customerDefaultPharmacyNABP = u.CustomerDefaultPharmacyNABP;
		});

		this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
			if (customer) {
				this.showIcdSelect = customer.DxReqForErx;
			}
		});

		this._diagnosisService.getTopFiveDiagnosis().then((results: DiagnosisSearchResultModel[]) => {
			this.primaryICDs = results;
		});
	}

	ignoreNextSearchFocus: boolean = false;
	icdSearchFocus() {
		if (!this.ignoreNextSearchFocus) {
			this.ignoreNextSearchFocus = false;
			this.diagnosisSearch.handleSearchResults(this.primaryICDs);
			let search = this.diagnosisSearch.searchText;
			if (!search || !search.value || search.value.length == 0) {
				this.diagnosisSearch.searching = true;
				this.diagnosisSearch.handleSearchResults(this.primaryICDs);
			}
		} else {
			this.ignoreNextSearchFocus = false;
		}
	}

	clearFilters() {
		this.icd = null;
		this.diseaseState = null;
	}

	diagnosisSearchCleared() {
		this.drug.ICD10 = null;
		this.clearFilters();
		this.ignoreNextSearchFocus = true;
	}

	loadSelectedItem(selectedItem: DiagnosisSearchResultModel) {
		this.drug.ICD10 = selectedItem.IcdCode;
	}

	filterSearch() {
		this.diagnosisSearch.filteredAutoCompleteResults = _.filter(this.diagnosisSearch.autocompleteResults, r => {
			return (
				(!this.icd || (r.IcdCode && r.IcdCode.toLowerCase().indexOf(this.icd.toLowerCase()) !== -1)) &&
				(!this.diseaseState || (r.DiseaseStateName && r.DiseaseStateName.toLowerCase().indexOf(this.diseaseState.toLowerCase()) !== -1))
			);
		});

		this.diseaseStates = _.reduce(
			_.map(this.diagnosisSearch.autocompleteResults, r => r.DiseaseStateName),
			(acc: string[], s: string) => {
				if (acc.indexOf(s) === -1) {
					acc.push(s);
				}
				return acc;
			},
			[]
		);
	}

	insertPreformattedERxNote(noteText: string) {
		this.handlePreformattedNotInsertDerivation(noteText);
	}

	insertOppcBilling() {
		let [memberId, bin, pcn] = this._patientStore.getOppcBillingInfo();
		let oppcBillingStr = `Bin: ${bin}, PCN: ${pcn}, Member ID: ${memberId}`;
		this.handlePreformattedNotInsertDerivation(oppcBillingStr);
	}

	private handlePreformattedNotInsertDerivation(noteText: string) {
		let pNotes = this.drug.PharmacyNotes;
		let curIdx = (pNotes || "").length;

		if (curIdx == 0) {
			this.pharmacyNotesInput.writeValue("");
		} else {
			if (pNotes[curIdx - 1] == "#") {
				pNotes = pNotes.substring(0, curIdx - 1) + pNotes.substring(curIdx, pNotes.length);
			}

			this.pharmacyNotesInput.writeValue(pNotes);

			if (curIdx != 1) {
				noteText = `, ${noteText}`;
			}
		}

		this.pharmacyNotesInput.appendText(noteText);
	}

	selectClinician(e: any) {
		this.clinicianId = e;
	}

	submitErx() {
		this.saving = true;

		this.rxService
			.sendToDoseSpot({
				patientId: this.patientId,
				orderNumber: this.drug.OrderNumber,
				quantity: this.quantity,
				daysSupply: this.daysSupply,
				refills: this.refills,
				directions: this.drug.Directions,
				clinicianId: this.clinicianId,
				ndc: this.drug.NDC,
				drugName: this.drug.DrugName,
				prescriberId: this.clinicianId,
				pharmacyNotes: this.drug.PharmacyNotes,
				dispenseUnitId: this.drug.DispenseUnitId,
				icd10: this.drug.ICD10,
				customerDefaultPharmacyNABP: this.useDefaultPharmacy ? this.customerDefaultPharmacyNABP : null
			})
			.subscribe((result: any) => {
				this.saving = false;

				if (result.Success) {
					if (this.useDefaultPharmacy) {
						if (result.Result) {
							this._statusMessageService.changeStatusMessage("success", "Your eRx request has been submitted.");
						} else {
							this._statusMessageService.changeStatusMessage(
								"warning",
								"Your eRx request has been submitted, but could not be assigned to the default pharmacy. Please edit the created eRx to assign a pharmacy and complete your order.",
								20000
							);
						}
					} else {
						this._statusMessageService.changeStatusMessage("success", "Your eRx request has been submitted.");
					}
					this.closeModal.emit();
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage, 15000);
				}
			});
	}

	checkIfNonZeroNumber(num: any): boolean {
		return !!num && num != 0 && !isNaN(+num);
	}

	isDisplayingLengthError: boolean = false;
	pharmacyNotesValChanged(val: string) {
		this.pharmacyNotesLengthExceeded = (val || "").length > this.MAX_PHARMACY_NOTES_LENGTH;

		if (this.pharmacyNotesLengthExceeded && !this.isDisplayingLengthError) {
			let notificationMs = 10000;

			this.isDisplayingLengthError = true;
			this._statusMessageService.changeStatusMessage("error", "Pharmacy notes length of 200 characters has been exceeded.", notificationMs);

			setTimeout(() => {
				this.isDisplayingLengthError = false;
			}, notificationMs);
		}
	}
}
