import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { UserService } from "../shared/services/user.service";
import { takeUntil } from "rxjs/operators";
import { UserInfoModel } from "../shared/models/user-info.model";

@Component({
	selector: "account-layout",
	templateUrl: "./account-layout.template.html",
	styleUrls: ["./account-layout.scss"]
})
export class AccountLayoutComponent implements OnInit, OnDestroy {
	showErx: boolean = false;

	constructor(private _router: Router, private _userService: UserService) {}

	ngOnInit(): void {
		this._userService.userInfo.pipe(takeUntil(this.$destroyed)).subscribe((result: UserInfoModel) => {
			this.showErx = result.DoseSpotClinicianId > 0;
		});
	}

	private $destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy(): void {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
	}

	isActive(path: string): boolean {
		return this._router.url.indexOf(path) > -1;
	}
}
