import { takeUntil } from "rxjs/operators";
import { Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PatientService } from "../../../shared/services/patient.service";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { RxOrderModel } from "../../../shared/models/rx-order.model";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { DeliveryPhysicianDetailsComponent } from "./components/delivery-physician-details/delivery-physician-details.component";
import { UserInfoModel } from "../../../shared/models/user-info.model";
import { UserService } from "../../../shared/services/user.service";
import { PrescriberSearchResultModel } from "../../../shared/models/prescriber-search-result.model";
import { CustomerProfileModel } from "../../../shared/models/customer-profile.model";
import { CustomerService } from "../../../shared/services/customer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientCareStore } from "../../services/patient-care.store";
import { RxService } from "../../../shared/services/rx.service";
import { RxOrderSuccessModel } from "../../../shared/models/rx-order-success.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { Subject } from "rxjs";
import { AgentModel } from "../../models/agent-model";

@Component({
	selector: "new-profile-medication",
	templateUrl: "./new-profile-medication.template.html",
	styleUrls: ["./new-profile-medication.scss"]
})
export class NewProfileMedicationComponent implements OnInit, OnDestroy {
	isLoading: boolean = false;
	isEditing: boolean = true;
	hideDeliveryForm: boolean = false;
	stepOneComplete: boolean = false;
	screeningEnabled: boolean = false;
	agent: AgentModel = new AgentModel();
	rxOrders: RxOrderModel[] = [];
	modalTitle: string;
	subHeaderText: string;
	dialogueModalContent: string;
	primaryMedFormRemoved: boolean = false;
	prescriber: PrescriberSearchResultModel = new PrescriberSearchResultModel();
	customerName: string;
	loggedInUserName: string;
	isPrinted = false;
	selectedOrder: RxOrderModel;

	tempGpi: string;
	screenOption: string;

	patient: PatientInfoModel;
	createMode: string;

	private destroyed: Subject<void> = new Subject<void>();

	get ScreeningDialogueTitle(): string {
		if (this.screenOption === "allergy") {
			return "Allergy Warnings";
		} else if (this.screenOption === "drug-interaction") {
			return "Drug Interaction Warnings";
		} else if (this.screenOption === "order-failure") {
			return "Reason for Order Failure";
		}
	}

	@Output() pageClosed: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild("dialogueModal") dialogueModal: ModalComponent;

	@ViewChild("dpDetails") dpDetails: DeliveryPhysicianDetailsComponent;

	@ViewChild("screeningModal") screeningModal: ModalComponent;

	constructor(
		private _patientService: PatientService,
		private _userService: UserService,
		private _customerService: CustomerService,
		private _rxService: RxService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _store: PatientCareStore,
		private _statusMessageService: StatusMessageService,
		private _changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		this._store.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient: PatientInfoModel) => {
			if (!patient.patientId) return;

			this.createMode = this._route.snapshot.data["type"] == "intake" ? "orderIntake" : "nonDispensingOrder";

			this.patient = patient;

			this.init();
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	private init() {
		if (this.createMode === "nonDispensingOrder") {
			this.moveToStepTwo();
		}
		this._userService.userInfo.pipe(takeUntil(this.destroyed)).subscribe((result: UserInfoModel) => {
			this.loggedInUserName = result.FirstName + " " + result.LastName;
			this.screeningEnabled = result.IsScreeningEnabled;
		});
		this._customerService.getCustomerProfile(null).subscribe((customer: CustomerProfileModel) => {
			this.customerName = customer.Name;
		});
	}

	private checkOrders() {
		// check if all orders have been processed
		if (
			this.rxOrders.every((order: RxOrderModel) => {
				return order.orderProcessed;
			})
		) {
			// display success message and return to patient profile if all orders are successful.
			//  Otherwise, reduce the processed orders down to errors.
			if (
				this.rxOrders.every((order: RxOrderModel) => {
					return order.orderSuccess;
				})
			) {
				// show success message then go to patient profile.
				this._statusMessageService.changeStatusMessage("success", "All orders have been submitted for " + this.patient.firstName + " " + this.patient.lastName + ".");
				this.closePage();
			} else {
				// show general error message and filter out the successful orders
				this._statusMessageService.changeStatusMessage("error", "An error occurred while processing one or more orders.");

				this.rxOrders = this.rxOrders.filter((order: RxOrderModel) => {
					return !order.orderSuccess;
				});

				// reset the remaining orders.
				// this.rxOrders.forEach((order: RxOrderModel) => {
				// 	order.orderProcessed = false;
				// });
			}
		}

		this.isLoading = false;
	}

	prescriberSelected(selectedPrescriber: PrescriberSearchResultModel) {
		this.prescriber = selectedPrescriber;
	}

	closePage() {
		this._router.navigate(["../detail"], { relativeTo: this._route });
	}

	toggleDelPhysDetails(event) {
		let instance = event;

		if (instance === "summary") {
			this.hideDeliveryForm = true;
		} else if (instance === "form") {
			this.hideDeliveryForm = false;
		}
	}

	moveToStepTwo() {
		let self = this;

		setTimeout(function() {
			self.stepOneComplete = true;
			this.isEditing = true;
			let order: RxOrderModel = new RxOrderModel();
			order.PatientId = self.patient.patientId;
			self.rxOrders.push(order);
		}, 300);
	}

	addMedication() {
		this.isEditing = true;
		this.isPrinted = false;
		let order: RxOrderModel = new RxOrderModel();
		order.PatientId = this.patient.patientId;
		this.orderOrdersForPrint();
		if (this.primaryMedFormRemoved) {
			this.primaryMedFormRemoved = false;
			this.rxOrders.push(order);
		} else {
			this.rxOrders.push(order);
		}
	}

	remove(idx: number) {
		this.rxOrders.splice(idx, 1);
	}

	cancelOrder() {
		this.dialogueModal.open("xs");
		this.modalTitle = "Cancel Medication Order?";
		this.subHeaderText = "";
		this.dialogueModalContent = "Are you sure you want to cancel this medication order?";
	}

	submitMedicationOrder() {
		if (this.createMode === "orderIntake") {
			this.isPrinted = true;
			this.orderOrdersForPrint();
			window.print();
		} else {
			for (let i = 0; i < this.rxOrders.length; i++) {
				const order: RxOrderModel = this.rxOrders[i];

				this.isLoading = true;
				this._rxService.addOrder(order).then(
					(result: RxOrderSuccessModel) => {
						console.log("Add Order Success");
						order.orderProcessed = true;
						order.orderSuccess = true;
						this.checkOrders();
					},
					(reason: string) => {
						console.log("Add Order Failure");
						order.orderProcessed = true;
						order.orderSuccess = false;
						order.orderFailureReason = reason;
						this.checkOrders();
					}
				);
			}
		}
	}

	modalConfirm() {
		if (this.modalTitle === "Cancel Medication Order?") {
			this.confirmOrderCancellation();
		} else if (this.modalTitle === "Leave Without Printing?") {
			this.confirmDone();
		}
	}

	confirmOrderCancellation() {
		this.dialogueModal.dismiss();
		this.pageClosed.emit("closed");
		this.closePage();
	}

	done() {
		if (this.isPrinted) {
			this.confirmDone();
		} else {
			this.dialogueModal.open("xs");
			this.modalTitle = "Leave Without Printing?";
			this.subHeaderText = "";
			this.dialogueModalContent = "These orders have not been printed yet, do you want to leave without printing?";
		}
	}

	confirmDone() {
		this._router.navigate(["../detail"], { relativeTo: this._route });
	}

	showAddItem() {
		this.primaryMedFormRemoved = true;
		this.orderOrdersForPrint();
	}

	showScreening(obj: any) {
		this.tempGpi = obj.gpi;
		this.screenOption = obj.type;
		this.selectedOrder = obj.order;

		if (this.screenOption === "order-failure") {
			this.screeningModal.open("xs");
		} else {
			this.screeningModal.open("lg");
		}
	}

	private orderOrdersForPrint() {
		let orders = [];
		this.rxOrders.forEach(o => {
			if (!o.IsControl) orders.push(o);
		});
		this.rxOrders.forEach(o => {
			if (o.IsControl) orders.push(o);
		});
		this.rxOrders = orders;
	}
}
