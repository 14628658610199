// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
   			.enabled {
   				display: block;
   				padding-right: 17px;
   			}
   			.modal {
   				z-index: 99990;
   			}
   			.modal-xs {
   				width: 350px;
   			}
   		`, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/modal/modal.component.ts"],"names":[],"mappings":";MACM;OACC,cAAc;OACd,mBAAmB;MACpB;MACA;OACC,cAAc;MACf;MACA;OACC,YAAY;MACb","sourcesContent":["\n   \t\t\t.enabled {\n   \t\t\t\tdisplay: block;\n   \t\t\t\tpadding-right: 17px;\n   \t\t\t}\n   \t\t\t.modal {\n   \t\t\t\tz-index: 99990;\n   \t\t\t}\n   \t\t\t.modal-xs {\n   \t\t\t\twidth: 350px;\n   \t\t\t}\n   \t\t"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
