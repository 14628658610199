import { Component, Output, EventEmitter, ViewChild, Input, OnInit } from "@angular/core";
import { ISearchService } from "../../../shared/components/patient-search/search.interface";
import { RxMedicationsSearchService } from "../../../shared/services/rx-medications-search.service";
import { MedicationSearchResultModel } from "../../../shared/models/medication-search-result.model";
import { AdvancedSearch } from "src/app/ocp/shared/components/advanced-search/advanced-search.component";
import * as _ from "lodash";
import { UntypedFormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: "search-medications",
	providers: [{ provide: ISearchService, useClass: RxMedicationsSearchService }],
	templateUrl: "./search-medications.template.html",
	styleUrls: ["./search-medications.scss"]
})
export class SearchMedicationsComponent implements OnInit {
	@Input() initialText: string;

	@Output() onSearchCleared: EventEmitter<any> = new EventEmitter<any>();
	@Output() onItemSelect: EventEmitter<MedicationSearchResultModel> = new EventEmitter<MedicationSearchResultModel>();

	@ViewChild("searchControl") private searchControl: AdvancedSearch;

	strengthInput: UntypedFormControl = new UntypedFormControl();
	dosageFormInput: UntypedFormControl = new UntypedFormControl();
	gpi10Input: UntypedFormControl = new UntypedFormControl();

	tableHeaderLabels: string[] = ["Drug Name", "NDC", "Brand Name", "DEA Class"];

	dosageForms: string[] = [];
	filteredDosageForms: string[] = [];

	strengths: string[] = [];
	filteredStrengths: string[] = [];

	gpi10Names: string[] = [];
	filteredGpi10Names: string[] = [];

	private destroyed: Subject<void> = new Subject<void>();

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return [this.gpi10Input.value, this.dosageFormInput.value, this.strengthInput.value].reduce((acc, val) => {
			return val && val.length > 0 ? ++acc : acc;
		}, 0);
	}

	get strengthPlaceholder(): string {
		return this.strengthInput.value && this.strengthInput.value.length > 0 ? "" : "By Strength";
	}

	get gpi10Placeholder(): string {
		return this.gpi10Input.value && this.gpi10Input.value.length > 0 ? "" : "By GPI10 Name";
	}

	get dosageFormPlaceholder(): string {
		return this.dosageFormInput.value && this.dosageFormInput.value.length > 0 ? "" : "By Dosage Form";
	}

	constructor() { }

	ngOnInit() {
		if (this.initialText) {
			setTimeout(() => {
				this.setText(this.initialText);
			}, 0);
		}

		this.generateDropDownLists();

		this.strengthInput.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((val: string) => {
			this.filteredStrengths = this._filterSubAutoComplete(this.strengths, val);
			this.filterResults();
		});

		this.gpi10Input.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((val: string) => {
			this.filteredGpi10Names = this._filterSubAutoComplete(this.gpi10Names, val);
			this.filterResults();
		});

		this.dosageFormInput.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((val: string) => {
			this.filteredDosageForms = this._filterSubAutoComplete(this.dosageForms, val);
			this.filterResults();
		});
	}

	clear() {
		this.clearFilters();
		this.onSearchCleared.emit();
	}

	clearSearch() {
		this.searchControl.clearSearch();
	}

	clearFilters() {
		this.gpi10Input.setValue(null);
		this.strengthInput.setValue(null);
		this.dosageFormInput.setValue(null);
		this.filterResults();
	}

	filterResults() {
		this.searchControl.filteredAutoCompleteResults = _.filter(this.searchControl.autocompleteResults, med => {
			return (
				(!this.strengthInput.value ||
					(med.Strength && med.Strength.toLowerCase().indexOf(this.strengthInput.value.toLowerCase()) !== -1) ||
					(this.strengthInput.value === "Unknown" && (!med.Strength || med.Strength.length === 0))) &&
				(!this.gpi10Input.value ||
					(med.Gpi10Name && med.Gpi10Name.toLowerCase().indexOf(this.gpi10Input.value.toLowerCase()) !== -1) ||
					(this.gpi10Input.value === "Unknown" && (!med.Gpi10Name || med.Gpi10Name.length === 0))) &&
				(!this.dosageFormInput.value ||
					(med.DosageForm && med.DosageForm.toLowerCase().indexOf(this.dosageFormInput.value.toLowerCase()) !== -1) ||
					(this.dosageFormInput.value === "Unknown" && (!med.DosageForm || med.DosageForm.length === 0)))
			);
		});
	}

	generateDropDownLists() {
		if (!this.searchControl) return;
		const dropDownUniqueResults = _.reduce(
			this.searchControl.autocompleteResults,
			(acc, med) => {
				if (med.DosageForm) {
					if (acc.dosageForms.indexOf(med.DosageForm) === -1) {
						acc.dosageForms.push(med.DosageForm);
					}
				} else if (acc.dosageForms.indexOf("Unknown") === -1) {
					acc.dosageForms.push("Unknown");
				}

				if (med.Strength) {
					if (acc.strengths.indexOf(med.Strength) === -1) {
						acc.strengths.push(med.Strength);
					}
				} else if (acc.strengths.indexOf("Unknown") === -1) {
					acc.strengths.push("Unknown");
				}

				if (med.Gpi10Name) {
					if (acc.gpi10Names.indexOf(med.Gpi10Name) === -1) {
						acc.gpi10Names.push(med.Gpi10Name);
					}
				} else if (acc.gpi10Names.indexOf("Unknown") === -1) {
					acc.gpi10Names.push("Unknown");
				}

				return acc;
			},
			{ dosageForms: [], strengths: [], gpi10Names: [] }
		);

		this.dosageForms = this.filteredDosageForms = dropDownUniqueResults.dosageForms;
		this.strengths = this.filteredStrengths = dropDownUniqueResults.strengths;
		this.gpi10Names = this.filteredGpi10Names = dropDownUniqueResults.gpi10Names;
	}

	private _filterSubAutoComplete(vals: string[], filter: string): string[] {
		if (!filter || filter.length === 0) {
			return vals;
		}
		filter = filter.toLowerCase();

		return _.filter(vals, (val: string) => (val || "").toLowerCase().indexOf(filter) !== -1);
	}

	setText(text: string) {
		this.searchControl.searchText.reset(text, { emitEvent: false });
	}
}
