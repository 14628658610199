import {Component, Input} from "@angular/core";
import {DrugGroupSummaryModel} from "../../../../shared/models/drug-group-summary.model";

@Component ({
  selector: "lab-notes",
  templateUrl: "./lab-notes.template.html",
  styleUrls: ["./plan-of-treatment-tabs.scss"]
})

export class LabNotesComponent {
  @Input() drugSummary: DrugGroupSummaryModel[] = [];

  constructor() {

  }
}
