import { Component, Input, forwardRef, Output, EventEmitter, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, UntypedFormControl } from "@angular/forms";

@Component({
	selector: "input-min-number",
	templateUrl: "./input-min-number.template.html",
	styleUrls: ["./input-min-number.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputMinNumComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputMinNumComponent),
			multi: true
		}
	]
})
export class InputMinNumComponent implements ControlValueAccessor, OnInit, Validator {
	@Input() label: string;
	@Input() isRequired: boolean = false;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() overrideValidation: boolean = false;

	@Output() isDisabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() disableId: string;
	@Input() disabledLabel: string;
	@Input() isInvalid: boolean;
	@Input() maxLength: number;
	@Input() minValue: number = 0;

	value: number;

	ngOnInit() {
		setTimeout(() => {
			if (this.isRequired && ((!this.value && this.value !== 0) || this.value < this.minValue)) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 200);
	}

	propagateChange = (_: number) => {};

	inputChanged(value: number) {
		this.value = value;
		if (!this.overrideValidation) {
			if (this.isRequired && ((!this.value && this.value !== 0) || this.value < this.minValue)) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}

		if (this.value || this.value === 0) {
			this.propagateChange(this.value);
		} else {
			this.propagateChange(null);
		}
	}

	writeValue(obj: number) {
		this.value = obj;
	}

	validate(control: UntypedFormControl) {
		if (this.isDisabled) {
			// if the control is disabled then we do not want to be validated.
			return null;
		}

		if (this.value && +this.value < this.minValue) {
			this.isInvalid = true;
		} else {
			this.isInvalid = false;
		}

		if (this.isInvalid) {
			return {
				formatError: {
					valid: false
				}
			};
		}

		return null;
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed

	onBlur(event: any) {
		if (event.target.value == "0") return;

		event.target.value = event.target.value.replace(/^0+/, "");
	}
}
