// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 767px) {
  button.more-action-button {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/imports/_breakpoints.scss","webpack://./src/app/ocp/shared/components/erx-components/preferred-erx-table/preferred-erx-table.component.scss"],"names":[],"mappings":"AA2BE;ECtBF;IAEI,sBAAA;EAJF;AACF","sourcesContent":["//***********************************************\r\n// ~* BREAKPOINTS\r\n//***********************************************\r\n$global-width: 1060px;\r\n\r\n$xsmall: 0px;\r\n$small: 412px;\r\n$smallMax: 576px;\r\n$medium: 768px;\r\n$large: 960px;\r\n$xlarge: 1060px;\r\n\r\n@mixin screen($res-min, $res-max, $orientation: false) {\r\n  @if $orientation {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max)\r\n    and (orientation:#{$orientation}) {\r\n      @content;\r\n    }\r\n  }\r\n  @else {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max) {\r\n      @content;\r\n    }\r\n  }\r\n}\r\n\r\n@mixin max-screen($res) {\r\n  @media screen and (max-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin min-screen($res) {\r\n  @media screen and (min-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin print() {\r\n  @media print {\r\n    @content;\r\n  }\r\n}\r\n","@import '../../../../../../assets/styles/imports/_breakpoints';\r\n@import '../../../../../../assets/styles/imports/_settings';\r\n@import '../../../../../../assets/styles/imports/_mixins';\r\n@import '../../../../../../assets/styles/imports/_functions';\r\n\r\nbutton.more-action-button {\r\n  @include max-screen($medium - 1px) {\r\n    width: 100% !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
