import {Routes, RouterModule} from "@angular/router";

export const routes: Routes = [

];

export const APP_ROUTER_PROVIDERS: any[] = [

];

export const routing = RouterModule.forRoot(routes, { useHash: false });

