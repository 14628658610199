import { Component, Input, OnInit, EventEmitter, Output, OnDestroy, ViewChild } from "@angular/core";
import { PatientResult } from "../../../shared/models/patient-result.model";
import { PatientService } from "../../../shared/services/patient.service";
import { Subject } from "rxjs";
import { UtilitiesService } from "src/app/ocp/shared/services/utilities.service";
import { PatientInfoModel } from "src/app/ocp/shared/models/patient.model";

@Component({
	selector: "patient-summary",
	templateUrl: "./patient-summary.template.html",
	styleUrls: ["./patient-summary.scss"]
})
export class PatientSummary implements OnInit, OnDestroy {
	isAdmitted: boolean = false;
	isDeceased: boolean = false;
	isPreadmit: boolean = false;

	@Input() patient: PatientResult;
	@Input() hasDosespotAndERxPermissions: boolean;

	@Output() editPatientAdmissions: EventEmitter<any> = new EventEmitter<any>();
	@Output() toggleFavorite: EventEmitter<any> = new EventEmitter<any>();
	@Output() openQuickRefill: EventEmitter<any> = new EventEmitter<any>();
	@Output() deletePreAdmit: EventEmitter<any> = new EventEmitter<any>();
	@Output() dispenseHistory: EventEmitter<PatientResult> = new EventEmitter<PatientResult>();
	@Output() neweRxOrder: EventEmitter<PatientResult> = new EventEmitter<PatientResult>();
	@Output() neweRx: EventEmitter<PatientResult> = new EventEmitter<PatientResult>();

	popoverContent: string;

	private _destroyed: Subject<void> = new Subject<void>();

	get allowNewErx(): boolean {
		return this.hasDosespotAndERxPermissions && !this.isPediatricRestricted;
	}

	get isPediatricRestricted(): boolean {
		let p = this.patient;

		if (!p) return;

		if (p && p.BirthDate && this._util.calcAge(p.BirthDate) < 18) {
			if (!p.WeightLbs || !p.HeightIn) {
				return true;
			}
		}

		return false;
	}

	constructor(private _patientService: PatientService, private _util: UtilitiesService) {}

	ngOnInit() {
		this.isAdmitted = this._patientService.isAdmitted(this.patient.AdmissionStatusCode);
		this.isDeceased = this._patientService.isDeceased(this.patient.AdmissionStatusCode);
		this.isPreadmit = this._patientService.isPreadmit(this.patient.AdmissionStatusCode);

		// Set the tooltip content for each Patient's Icon
		if (this.isAdmitted && this.patient.IsFacilityCare) {
			this.popoverContent = "Currently admitted into facility care.";
		} else if (this.isAdmitted && this.patient.IsHomePatient) {
			this.popoverContent = "Currently admitted into home care.";
		} else if (this.isAdmitted && this.patient.IsIpuGpu) {
			this.popoverContent = "Currently admitted into IPU/GPU.";
		} else if (this.isDeceased) {
			this.popoverContent = "Patient is Deceased.";
		} else if (this.isPreadmit) {
			this.popoverContent = "Patient has not been admitted.";
		} else {
			this.popoverContent = "Currently discharged.";
		}
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	editAdmissions(patientId: number, admissionsType) {
		this.editPatientAdmissions.emit({ patientId: patientId, admissionsType: admissionsType });
	}

	doToggleFavorite(patient: PatientResult) {
		this.toggleFavorite.emit(patient);
	}

	doNewErx(patient) {
		this.neweRx.emit(patient);
	}

	doNewErxOrder(patient) {
		this.neweRxOrder.emit(patient);
	}

	doDispenseHistory(patient) {
		this.dispenseHistory.emit(patient);
	}
}
