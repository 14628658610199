import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ClinicalResourcesService } from "../../../shared/services/clinical-resources.service";
import { DocumentInfoModel } from "../../../shared/models/document-info.model";

@Component({
	selector: "webinar-thumbnail",
	templateUrl: "./webinar-thumbnail.template.html",
	styleUrls: ["./thumbnail-item.scss"]
})
export class WebinarThumbnail implements OnInit {
	@Input() itemName: string;
	@Input() webinarType: string;
	@Input() itemDate: string;
	@Input() itemUrl: string;
	@Input() thumbnailSrc: string;
	@Input() videoId: string;
	@Input() documentId: number;

	@Output() viewRecording: EventEmitter<string> = new EventEmitter<string>();
	@Output() downloadPptFile: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _clinicalResourcesService: ClinicalResourcesService) {}

	ngOnInit() {
		if (this.webinarType === "powerpoint") {
			this._clinicalResourcesService.getThumbnail("", this.documentId, null, null).subscribe((results: any) => {
				this.thumbnailSrc = "data:image/jpeg;base64," + results;
			});
		}
	}

	download() {
		if (!this.documentId) return;

		this._clinicalResourcesService.getDocuments(this.documentId, null).subscribe((result: DocumentInfoModel[]) => {
			if (!result.length) return;
			let doc = result[0];
			this._clinicalResourcesService.downloadDocument(this.documentId, false).then(
				(result: Blob) => {
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(result, doc.OriginalFileName);
					} else {
						let a = document.createElement("a");
						document.body.appendChild(a);
						a.style.display = "none";

						let url = window.URL.createObjectURL(result);
						a.href = url;
						a.setAttribute("download", doc.OriginalFileName);
						a.click();
						window.URL.revokeObjectURL(url);
						document.body.removeChild(a);
					}
				},
				error => {
					console.log(error);
				}
			);
		});
	}
}
