import { Component, Input, Output, EventEmitter, ViewChild, OnInit, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { NgForm } from "@angular/forms";
import { UserProfile } from "../../models/user-profile.model";
import { UserService } from "../../services/user.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { ApiResult } from "../../models/api-result.model";
import { Permissions } from "../../enums/permissions";
import { AuthService } from "../../services/auth.service";
import { MultiSelectItemModel } from "../input-multi-select/multi-select-item.model";
import { PharmacyPermissionModel } from "../../models/pharmacy-permission.model";
import { CustomerService } from "../../services/customer.service";
import { CustomerPermissionModel } from "../../models/customer-permission.model";
import { CustomerSummaryModel } from "../../models/customer-summary.model";
import { CheckClinicianIdResult } from "../../models/check-clinician-id-result.model";
import { ModalComponent } from "../modal/modal";
import { UtilitiesService } from "../../services/utilities.service";
import { PermissionsService } from "../../services/permisisons.service";
import * as _ from "lodash";

const customOrder: any[] = [
	'Nurse',
	'Enhanced eRx',
	'Administrator',
	'Analytics',
	'Prior Authorizer',
	'Physician',
	'Nurse Practitioner',
	'Staff'
];

@Component({
	selector: "user-details",
	templateUrl: "./user-details.template.html",
	styleUrls: ["./user-details.scss"]
})
export class UserDetails implements OnInit, AfterViewChecked {
	saving: boolean = false;
	canEditLoginId: boolean = false;
	pharmacyPermissions: MultiSelectItemModel[] = [];
	selectedPharmacies: number[] = [];
	permissions: any = Permissions;
	customerAccessList: MultiSelectItemModel[] = [];
	selectedCustomers: number[] = [];
	clinicanIdConflicts: CheckClinicianIdResult[] = [];
	invalidEmailsStr: string = null;
	userRoles: MultiSelectItemModel[] = [];

	@Input() userProfile: UserProfile = new UserProfile();
	@Input() mode: string;
	@Input() customerId: number;
	@Input() organizationId: number;
	@Input() isInternal: boolean = false;
	@Input() enableDoseSpot: boolean = false;
	@Input() isAccountSettings: boolean = false;

	@ViewChild("editUserProfileForm") editUserProfileForm: NgForm;
	@ViewChild("confirmConflict") confirmConflict: ModalComponent;

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveSuccess: EventEmitter<any> = new EventEmitter<string>();
	@Output() updateSubHeader: EventEmitter<string> = new EventEmitter<string>();

	get doseSpotEnabled(): boolean {
		return this._authService.hasPermission(this.permissions.viewEditDoseSpot) && this.enableDoseSpot;
	}

	get canEditCustomerPermissions(): boolean {
		return !this.isInternal && !this.isAccountSettings;
	}

	get formChanged(): boolean {
		return !this.editUserProfileForm?.form?.pristine || this.mode === "New";
	}

	get loginIdInvalid(): boolean {
		return !this._utility.validateEmail(this.userProfile.LoginId);
	}

	get alternateEmailInvalid(): boolean {
		return !!this.userProfile.Email && this.userProfile.Email.length !== 0 && !this._utility.validateEmail(this.userProfile.Email);
	}

	get userPhoneInvalid(): boolean {
		let num = (this.userProfile.Phone || "");
		let len = num.length;

		if (this.isInternal) {
			return len !== 0 && len !== 10;
		}

		return len !== 10;
	}

	get selectedCustomersInvalid(): boolean {
		return !this.isInternal && (!this.selectedCustomers || this.selectedCustomers.length === 0)
	}

	get userRoleInvalid(): boolean {
		if (this.mode !== "New") return false;

		return !this.userProfile.userRoles || this.userProfile.userRoles.length === 0;
	}

	get formInvalid(): boolean {
		return this.userRoleInvalid || this.loginIdInvalid || this.alternateEmailInvalid || this.userPhoneInvalid || this.selectedCustomersInvalid || (!this.isInternal && !this.userProfile.BirthDate) || !this.formChanged
	}

	showEmailError: boolean = false;

	constructor(
		private _statusMessageService: StatusMessageService,
		private _userService: UserService,
		private _authService: AuthService,
		private _customerService: CustomerService,
		private _permissionService: PermissionsService,
		private _utility: UtilitiesService,
		private cdr: ChangeDetectorRef) { }

	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}

	ngOnInit() {
		this.updateSubHeader.emit("");
		this.canEditLoginId = this._userService.isInternalUser();

		if (this.isInternal) {
			this.initInternalCustomer();
		} else {
			this.initCustomerAccessList();
		}
	}

	onEmailChange() {
		setTimeout(() => {
			let invalid = [];

			if (!!this.userProfile.LoginId && this.loginIdInvalid) {
				invalid.push("Login ID");
			}

			if (!!this.userProfile.Email && this.alternateEmailInvalid && this.userProfile.Email.length > 0) {
				invalid.push("Alternate Email");
			}

			this.invalidEmailsStr = invalid.length > 0 ? invalid.join(", ") : null;
		}, 200)

	}

	saveButtonText() {
		if (this.mode === "New") {
			return "Save";
		} else if (this.mode === "Edit") {
			return "Save Updates";
		}
	}

	saveProfile() {
		this.saving = true;

		// let userRoles: RoleModel[] = this.getRoleModels();
		let customerPermissions: CustomerPermissionModel[] = this.getCustomerPermissions();
		let updatePermissions: PharmacyPermissionModel[] = this.getPharmacyPermissions();
		// let userTeams: UserTeamModel[] = this.getUserTeamModels();

		if (this.mode === "New") {
			// add new user
			if (this.isInternal) {
				this._userService.addInternalUser(this.userProfile, updatePermissions).subscribe((result: ApiResult) => {
					this.saving = false;
					if (result.Success) {
						this._statusMessageService.changeStatusMessage("success", this.userProfile.LastName + ", " + this.userProfile.FirstName + " successfully added.");
						this.editUserProfileForm.form.markAsUntouched();
						this.editUserProfileForm.form.markAsPristine();
						this.saveSuccess.emit();
					} else {
						this._statusMessageService.changeStatusMessage("error", result.PublicMessage);

						if (result.Result.UserId) {
							this.closeModal.emit(true);
						}
					}
				});
			} else {
				this.userProfile.IsPrescriber = _.some(this.userProfile.userRoles, (roleId: number) => {
					let ocpRole = this._ocpRoles.find(x => x.RoleId == roleId);
					if (ocpRole) {
						return ocpRole.RoleName.toLowerCase().indexOf('physician') > -1 || ocpRole.RoleName.toLowerCase().indexOf('nurse practitioner') > -1;
					}
				});

				this.userProfile.ocpRoleIds = this._ocpRoles.filter(x => {
					return this.userProfile.userRoles.findIndex(r => r == x.RoleId) > -1;
				}).map(x => x.id);

				this._userService.addExternalUser(this.userProfile, this.customerId, customerPermissions).subscribe((result: ApiResult) => {
					this.saving = false;
					if (result.Success) {
						this._statusMessageService.changeStatusMessage("success", this.userProfile.LastName + ", " + this.userProfile.FirstName + " successfully added.");
						this.editUserProfileForm.form.markAsUntouched();
						this.editUserProfileForm.form.markAsPristine();
						this.saveSuccess.emit();
					} else {
						this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
					}
				});
			}
		} else {
			// update current user

			this.clinicanIdConflicts = [];
			if ((this.enableDoseSpot || this.doseSpotEnabled) && this.userProfile.ClinicianId) {
				this._userService.checkClinicianIdExists(this.userProfile.ClinicianId, this.userProfile.UserId).subscribe((result: CheckClinicianIdResult[]) => {
					if (result && result.length > 0) {
						this.clinicanIdConflicts = result;
						this.confirmConflict.open("lg");
						this.saving = false;
						return;
					} else {
						this._userService.setUserProfile(this.userProfile, this.userProfile.UserId, updatePermissions, customerPermissions, this.customerId).subscribe((result: ApiResult) => {
							this.saving = false;
							if (result.Success) {
								this._statusMessageService.changeStatusMessage("success", "Profile for " + this.userProfile.LastName + ", " + this.userProfile.FirstName + " successfully updated.");
								this.editUserProfileForm.form.markAsUntouched();
								this.editUserProfileForm.form.markAsPristine();
								this.saveSuccess.emit();
							} else {
								this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
							}
						});
					}
				})

			} else {
				this._userService.setUserProfile(this.userProfile, this.userProfile.UserId, updatePermissions, customerPermissions, this.customerId).subscribe((result: ApiResult) => {
					this.saving = false;
					if (result.Success) {
						this._statusMessageService.changeStatusMessage("success", "Profile for " + this.userProfile.LastName + ", " + this.userProfile.FirstName + " successfully updated.");
						this.editUserProfileForm.form.markAsUntouched();
						this.editUserProfileForm.form.markAsPristine();
						this.saveSuccess.emit();
					} else {
						this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
					}
				});
			}
		}
	}

	confirmAlthoughConflict() {
		this.confirmConflict.close();
		let customerPermissions: CustomerPermissionModel[] = this.getCustomerPermissions();
		let updatePermissions: PharmacyPermissionModel[] = this.getPharmacyPermissions();
		this.saving = true;
		this._userService.setUserProfile(this.userProfile, this.userProfile.UserId, updatePermissions, customerPermissions, this.customerId).subscribe((result: ApiResult) => {
			this.saving = false;
			if (result.Success) {
				this._statusMessageService.changeStatusMessage("success", "Profile for " + this.userProfile.LastName + ", " + this.userProfile.FirstName + " successfully updated.");
				this.editUserProfileForm.form.markAsUntouched();
				this.editUserProfileForm.form.markAsPristine();
				this.saveSuccess.emit();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	cancelConflict() {
		this.confirmConflict.close();
	}

	private initInternalCustomer() {
		if (this.mode === "New") {
			this._permissionService.getInternalRoleList().then((roles: any) => {
				this.userRoles = this.parseRoles(roles);
			})
		}

		if (!this.userProfile.UserId) {
			this._customerService.getPharmacyList().subscribe((results: any[]) => {
				results.sort((a, b) => {
					if (a.PharmacyId < b.PharmacyId) return -1;

					if (a.PharmacyId > b.PharmacyId) return 1;

					return 0;
				});
				for (let i: number = 0; i < results.length; i++) {
					let permission = results[i];

					this.pharmacyPermissions.push({
						id: permission.PharmacyId,
						text: permission.Name,
						selected: permission.IsPermitted
					});
				}
			});
		} else {
			this._userService.getUserPharmacyPermissions(this.userProfile.UserId).subscribe((results: PharmacyPermissionModel[]) => {
				results.sort((a, b) => {
					if (a.PharmacyId < b.PharmacyId) return -1;

					if (a.PharmacyId > b.PharmacyId) return 1;

					return 0;
				});
				for (let i: number = 0; i < results.length; i++) {
					let permission = results[i];

					this.pharmacyPermissions.push({
						id: permission.PharmacyId,
						text: permission.PharmacyName,
						selected: permission.IsPermitted
					});

					if (permission.IsPermitted) {
						this.selectedPharmacies.push(permission.PharmacyId);
					}
				}
			});
		}
	}

	private initCustomerAccessList() {
		let customerAccessList: MultiSelectItemModel[] = [];
		let selectedCustomers: number[] = [];

		if (this.mode === "New") {
			this._customerService.getCustomerRoles().subscribe((roles: any) => {
				this.userRoles = this.parseRoles(roles);
			})
		}

		if (this.userProfile.UserId) {
			this._userService.getUserCustomerPermissions(this.userProfile.UserId).subscribe((permissions: CustomerPermissionModel[]) => {
				if (!permissions) return;
				permissions.sort((a, b) => {
					if (a.CustomerName < b.CustomerName) return -1;

					if (a.CustomerName > b.CustomerName) return 1;

					return 0;
				});

				permissions.forEach((customer: CustomerPermissionModel) => {
					customerAccessList.push({
						id: customer.CustomerId,
						text: customer.CustomerName,
						location: customer.Location,
						selected: customer.Permitted
					});
					if (customer.Permitted) {
						selectedCustomers.push(customer.CustomerId);
					}
				});

				this.customerAccessList = customerAccessList;
				this.selectedCustomers = selectedCustomers;
			});
		} else {
			this._customerService.getOrganizationCustomerList(this.organizationId).subscribe((customers: CustomerSummaryModel[]) => {
				customers.sort((a, b) => {
					if (a.Name < b.Name) return -1;

					if (a.Name > b.Name) return 1;

					return 0;
				});
				let customerAccessList: MultiSelectItemModel[] = [];

				customers.forEach((customer: CustomerSummaryModel) => {
					let selected: boolean = customer.CustomerId === this.customerId;
					customerAccessList.push({
						id: customer.CustomerId,
						text: customer.Name,
						location: customer.City + ", " + customer.State,
						selected: selected
					});

					if (selected) {
						selectedCustomers.push(customer.CustomerId);
					}
				});

				this.customerAccessList = customerAccessList;
				this.selectedCustomers = selectedCustomers;
			});
		}
	}

	private getPharmacyPermissions() {
		let pharmacyPermissions: PharmacyPermissionModel[] = [];
		for (let item of this.pharmacyPermissions) {
			if (this.selectedPharmacies.indexOf(item.id) > -1) {
				pharmacyPermissions.push({
					PharmacyId: item.id,
					PharmacyName: item.text,
					IsPermitted: true
				});
			} else {
				pharmacyPermissions.push({
					PharmacyId: item.id,
					PharmacyName: item.text,
					IsPermitted: false
				});
			}
		}
		return pharmacyPermissions;
	}

	private getCustomerPermissions() {
		let permissions: CustomerPermissionModel[] = [];

		this.customerAccessList.forEach((x: MultiSelectItemModel) => {
			permissions.push({
				CustomerId: x.id,
				CustomerName: x.text,
				Location: x.location,
				Permitted: x.selected
			});
		});

		return permissions;
	}

	private _ocpRoles: any[] = [];
	private parseRoles(roles: any): MultiSelectItemModel[] {
		if (roles && roles.length) {
			this._ocpRoles = roles;
			roles = this.sortUsersByCustomOrder(roles);
			return roles.map(role => {
				let item: MultiSelectItemModel = { selected: false, text: role.RoleName, id: role.RoleId };
				return item;
			})
		}
		return [];
	}

	private sortUsersByCustomOrder(roles: any[]): any[] {
		return roles.sort((a, b) => {
			const nameA = a.RoleName;
			const nameB = b.RoleName;
			const indexA = customOrder.indexOf(nameA);
			const indexB = customOrder.indexOf(nameB);

			if (indexA > indexB) {
				return 1;
			} else if (indexA < indexB) {
				return -1;
			} else {
				return 0;
			}
		});
	}
}
