import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataTableModel } from "../../shared/components/data-table/data-table.model";
import { DataTableColumnModel } from "../../shared/components/data-table/columns.model";
import { Pharmacy } from "../../shared/models/pharmacy.model";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { PharmacyStore } from "../../shared/services/pharmacy.store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'ct-pharmacies-admin',
  templateUrl: './pharmacies-admin.component.html',
  styleUrls: ['./pharmacies-admin.component.scss']
})
export class PharmaciesAdminTab implements OnInit, OnDestroy {
  expandedPharmacy?: number = null;
  pharmacies: DataTableModel = new DataTableModel([], []);

  private _destroyed: Subject<void> = new Subject<void>();

  @ViewChild("editModal") editModal: ModalComponent;

  constructor(public store: PharmacyStore) { }

  ngOnInit() {
    this.store.refresh();
    this.initTable();
  }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.unsubscribe();
  }

  initTable() {
		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("expander", "", "", false));
    columns.push(new DataTableColumnModel("frameworkPharmId", "Framework ID", "col-framework-id", false));
		columns.push(new DataTableColumnModel("pharmacyName", "Name", "col-pharmacy-name", false));
    columns.push(new DataTableColumnModel("isEnabled", "Enabled", "col-enabled centered", false));
    columns.push(new DataTableColumnModel("city", "Location", "", false));
    columns.push(new DataTableColumnModel("phone", "Phone", "", false));
    columns.push(new DataTableColumnModel("edit", "", "col-edit-button", false));
    
    this.pharmacies = new DataTableModel([], columns);
    this.pharmacies.pageLength = 9999;
    
    this.store.pharmacies.observable
      .pipe(takeUntil(this._destroyed))
      .subscribe((pharms: Pharmacy[]) => {
        this.pharmacies.populate(pharms);
      });
  }

  isExpanded(pharmacyId: number): boolean {
		return this.expandedPharmacy === pharmacyId;
	}

  expand(pharmacyId: number){
		if (!this.expandedPharmacy || this.expandedPharmacy !== pharmacyId){
			this.expandedPharmacy = pharmacyId;
		} 
		else if (this.expandedPharmacy === pharmacyId){
			this.expandedPharmacy = null;
		} else {
			this.expandedPharmacy = pharmacyId;
		}
	}

  editPharmacy(pharmacy: Pharmacy) {
    this.store.selected.set(new Pharmacy().deserialize(pharmacy));
		this.editModal.open("md");
	}
}
