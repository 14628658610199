// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spacer {
  width: 100%;
}

.overflow-show {
  overflow: visible !important;
  min-height: 300px !important;
}

.pharmacy-name {
  padding-left: 1.25rem;
}

.expanded-element {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  .expanded-el .cf-data-table td:before {
    content: none;
  }
}

td.expanded-el::before {
  content: none;
}

td.pharmacy-name::before {
  content: none;
}

.pharmacies-label-container {
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 4px;
}

th.alert-text {
  width: 51%;
}

.action-container {
  display: flex;
}
.action-container .new-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.action-container .new-btn-container .new-btn {
  border-left: 1px solid #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/administration/system-alerts/system-alerts.component.scss"],"names":[],"mappings":"AAKA;EACI,WAAA;AAJJ;;AAOA;EACI,4BAAA;EACA,4BAAA;AAJJ;;AAOA;EACI,qBAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ;;AAQI;EACI;IACI,aAAA;EALV;AACF;;AASA;EACI,aAAA;AANJ;;AASA;EACI,aAAA;AANJ;;AASA;EACI,aAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;AANJ;;AASA;EACI,UAAA;AANJ;;AASA;EACI,aAAA;AANJ;AAQI;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;AANR;AAQQ;EACI,2BAAA;AANZ","sourcesContent":["@import '../../../../assets/styles/imports/breakpoints';\r\n@import '../../../../assets/styles/imports/settings';\r\n@import '../../../../assets/styles/imports/mixins';\r\n@import '../../../../assets/styles/imports/functions';\r\n\r\n.spacer {\r\n    width: 100%;\r\n}\r\n\r\n.overflow-show {\r\n    overflow: visible !important;\r\n    min-height: 300px !important;\r\n}\r\n\r\n.pharmacy-name {\r\n    padding-left: rem-calc(20px);\r\n}\r\n\r\n.expanded-element {\r\n    padding-top: 0;\r\n}\r\n\r\n.expanded-el{\r\n    @media screen and (max-width: 767px) {\r\n        .cf-data-table td:before {\r\n            content: none;\r\n        }\r\n    }\r\n}\r\n\r\ntd.expanded-el::before {\r\n    content: none;\r\n}\r\n\r\ntd.pharmacy-name::before {\r\n    content: none;\r\n}\r\n\r\n.pharmacies-label-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    height: 24px;\r\n    margin-bottom: 4px;\r\n}\r\n\r\nth.alert-text {\r\n    width: 51%;\r\n}\r\n\r\n.action-container {\r\n    display: flex;\r\n\r\n    .new-btn-container {\r\n        display: flex;\r\n        justify-content: center;\r\n        flex-direction: column;\r\n\r\n        .new-btn {\r\n            border-left: 1px solid #ddd;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
