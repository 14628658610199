import { takeUntil } from "rxjs/operators";
import { Component, Output, EventEmitter, OnInit, Input, ViewChild, OnDestroy, ElementRef, AfterViewInit } from "@angular/core";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { PatientAllergyModel } from "../../../shared/models/allergy.model";
import { AllergySearchResultModel } from "../../../shared/models/allergy-search-result.model";
import { ISearchService } from "../../../shared/components/patient-search/search.interface";
import { AllergySearchService } from "../../../shared/services/allergy-search.service";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
	selector: "form-edit-allergy",
	templateUrl: "./form-edit-allergy.template.html",
	providers: [{ provide: ISearchService, useClass: AllergySearchService }],
	styleUrls: ["./form-edit-allergy.scss"]
})
export class FormEditAllergyComponent implements OnInit, OnDestroy, AfterViewInit {
	private _formDisabled: boolean = false;
	reactionTypes = ["Rash", "Shock", "Asthma", "Nausea", "Anemia", "Other"];
	checked: string[] = [];
	showOtherField: boolean = false;
	selectedAllergy: AllergySearchResultModel;
	tableHeaderLabels: string[] = ["Allergy Name"];

	private destroyed: Subject<void> = new Subject<void>();

	@Input()
	set formDisabled(val: boolean) {
		if (!val && this._formDisabled !== val) {
			this.setDefaults();
		}
		this._formDisabled = val;
	}

	get formDisabled() {
		return this._formDisabled;
	}

	@Input() isAddAllergy: boolean = false;
	@Input() focusSearch: EventEmitter<any> = new EventEmitter<any>();
	@Input() patient: PatientInfoModel;
	@Input() allergy: PatientAllergyModel = new PatientAllergyModel();

	@Output() saveAllergy: EventEmitter<PatientAllergyModel> = new EventEmitter<PatientAllergyModel>();
	@Output() formChanged: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild("allergyForm") allergyForm: NgForm;
	@ViewChild("diagnosisSearch") diagnosisSearch: any;

	constructor() { }

	ngOnInit() {
		if (this.isAddAllergy) {
			this.allergy = new PatientAllergyModel();
			this.allergy.IsActive = true;
		} else {
			let self = this;
			this.reactionTypes.forEach((reactionType: string) => {
				if (self.allergy["HasReaction_" + reactionType] === true) {
					self.checked.push(reactionType);
				}
			});
		}
	}

	ngAfterViewInit(): void {
		this.allergyForm.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(data => {
			this.formChanged.emit();
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	setDefaults() {
		this.allergy.OtherReactionDescription = "UNK";
		this.allergy.HasReaction_Other = true;
		this.checked = ["Other"];
		this.showOtherField = true;
	}

	updateChecked(reactionType: string, event: any) {
		let index = this.checked.indexOf(reactionType);
		this.formChanged.emit();
		this.allergy["HasReaction_" + reactionType] = event.target.checked;

		if (event.target.checked) {
			if (index === -1) {
				this.checked.push(reactionType);
			}
		} else {
			if (index !== -1) {
				this.checked.splice(index, 1);
			}
		}

		if (this.checked.indexOf("Other") !== -1) {
			if (!this.showOtherField && !this.allergy.OtherReactionDescription) {
				this.allergy.OtherReactionDescription = "UNK";
			}
			this.showOtherField = true;
		} else {
			this.showOtherField = false;
			this.allergy.OtherReactionDescription = "";
		}
	}

	allergySearchCleared(event: any) {
		this.selectedAllergy = null;
	}

	loadSelectedItem(allergy: AllergySearchResultModel) {
		this.formChanged.emit();
		this.selectedAllergy = allergy;
		this.allergy.AllergyName = this.selectedAllergy.AllergyName;
		this.allergy.MedispanClassID = this.selectedAllergy.MedispanClassId;
		this.allergy.MedispanDrugID = this.selectedAllergy.MedispanDrugId;

		if (!this.checked.length) {
			this.setDefaults();
		}
	}

	save() {
		this.allergy.Reaction = "";
		let self = this;
		this.reactionTypes.forEach((reaction: string) => {
			if (self.checked.indexOf(reaction) !== -1) {
				if (reaction === "Other") {
					self.allergy.Reaction += (!self.allergy.Reaction ? "" : ", ") + this.allergy.OtherReactionDescription;
				} else {
					self.allergy.Reaction += (!self.allergy.Reaction ? "" : ", ") + reaction;
				}
			}
		});

		this.saveAllergy.emit(Object.assign({}, this.allergy));
		this.selectedAllergy = null;
		this.checked = [];
		this.diagnosisSearch.searchText.setValue("");
		this.allergy.OtherReactionDescription = "";
	}

	addAllergyDisabled() {
		return !this.selectedAllergy || !this.checked.length || !this.allergyForm.form.valid;
	}
}
