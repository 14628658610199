import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FadeInAnimation } from "src/app/ocp/animations";

@Component({
	selector: "user-account-menu",
	templateUrl: "./user-account-menu.template.html",
	styleUrls: ["./user-account-menu.scss"],
	animations: [FadeInAnimation]
})
export class UserAccountMenuComponent implements OnInit {
	@Input() showErx: boolean;
	@Input() buildVersion: string = "";

	@Output() onMenuItemClick: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _location: Location) {}

	ngOnInit() {}

	isActiveAccountRoute(path: string) {
		let location: string = this._location.path().toLowerCase();
		let locationBase: string = location.length ? location.split("/")[2] : "";
		return locationBase === path.toLowerCase();
	}

	doAction(action: string) {
		this.onMenuItemClick.emit(action);
	}
};