import { Component, Input, forwardRef, OnInit, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { MultiSelectItemModel } from "./multi-select-item.model";
import * as _ from "lodash";

@Component({
	selector: "input-multi-select",
	templateUrl: "./input-multi-select.template.html",
	styleUrls: ["./input-multi-select.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputMultiSelectComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputMultiSelectComponent),
			multi: true
		}
	]
})
export class InputMultiSelectComponent implements ControlValueAccessor, OnInit, Validator, AfterViewChecked {
	value: any[] = [];
	changed: boolean = false;

	@Input() label: string;
	@Input() isRequired: boolean = false;
	@Input() type: string;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() isInvalid: boolean;
	@Input() additionalLabelText: string;
	@Input() truncateSelections: boolean = false;
	@Input() removeMargin: boolean = false;

	private _items: MultiSelectItemModel[];
	@Input()
	set items(val: MultiSelectItemModel[]) {
		this._items = val;
		this.listUpdated();
	}
	get items(): MultiSelectItemModel[] {
		return this._items;
	}

	multiSelectControl = new UntypedFormControl();

	get selectedItems(): MultiSelectItemModel[] {
		let selectedItems = this.items.filter((x: MultiSelectItemModel) => {
			return x.selected;
		});
		this.multiSelectControl.setValue(selectedItems)
		return selectedItems;
	}

	constructor(private cdr: ChangeDetectorRef) { }

	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}

	ngOnInit() {
		setTimeout(() => {
			if (this.isRequired && (!this.value || this.value.length === 0)) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 250);
	}

	propagateChange = (_: any[]) => { };

	listUpdated() {
		this.value = [];

		for (let index in this.items) {
			if (this.items[index].selected) {
				this.value.push(this.items[index].id);
				this.selectedItems.push(this.items[index].id);
			}
		}

		if (this.isRequired && (!this.value || this.value.length === 0)) {
			this.isInvalid = true;
		} else {
			this.isInvalid = false;
		}

		this.changed = true;
		this.propagateChange(this.value);
		this.writeValue(this.value)
	}

	validate(control: UntypedFormControl) {
		if (this.isDisabled) {
			// if the control is disabled then we do not want to be validated.
			return null;
		}

		if (this.isInvalid) {
			if (this.changed) {
				this.isInvalid = true;
			}

			return {
				formatError: { valid: false }
			};
		}

		return null;
	}

	writeValue(obj: any[]) {
		this.value = obj;
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) { } //Not currently needed

	toggleItem(item: MultiSelectItemModel) {
		if (this.isDisabled) return;

		item.selected = !item.selected;
		this.listUpdated();
	}

	removeItem(item: MultiSelectItemModel) {
		if (this.isDisabled) return;

		const selected = this.selectedItems;
		this.removeFirst(selected, item);
		this.multiSelectControl.setValue(selected);
		item.selected = false;
		this.listUpdated();
	}

	private removeFirst<T>(array: T[], toRemove: T): void {
		const index = array.indexOf(toRemove);
		if (index !== -1) {
			array.splice(index, 1);
		}
	}
}
