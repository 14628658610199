import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { PermissionsService } from "../services/permisisons.service";

@Injectable()
export class AuthGuard  {
	constructor(private authService: AuthService, private router: Router, private _permissionsService: PermissionsService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.authService.isAuthenticated()) {
			this.router.navigate(["/login"]);
			return false;
		}

		if (!this._permissionsService.canNavigateToSection(state.url.toLowerCase())) {
			this.router.navigate(["/access-denied"]);
		}

		return true;
	}
}
