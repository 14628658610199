import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { OrganizationProfile } from "../../models/organization-profile.model";
import { Permissions } from "../../enums/permissions";
import { ModalComponent } from "../modal/modal.component";
import { CustomerService } from "../../services/customer.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { CustomerSummaryModel } from "../../models/customer-summary.model";
import { CustomerProfileModel } from "../../models/customer-profile.model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserListItemModel } from "../../models/user-list-item.model";
import { UntypedFormControl } from "@angular/forms";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { RoutingRedirectService } from "../../services/routing-redirect.service";
import { OrganizationUsersStore } from "../../store/organization-users.store";
import { CustomerAuthenticationModel } from "../../models/customer-authentication.model";
import { SamlService } from "../../services/saml.service";

@Component({
	selector: "organization-profile",
	templateUrl: "./organization-profile.template.html",
	styleUrls: ["./organization-profile.scss", "../../../partner-profile/tabs/partner-profile-tabs.scss"]
})
export class OrganizationProfileComponent implements OnInit, OnDestroy {
	viewMode: string = "all";
	displayTab: string = "profile";
	organizationProfile: OrganizationProfile = new OrganizationProfile();
	orgCustomers: CustomerSummaryModel[] = [];
	editMode: string;
	createMode: string;
	modalTitle: string;
	subHeaderText: string;
	selectedCustomerId: number;
	editOrg: OrganizationProfile = null;
	editCustomer: CustomerProfileModel = null;
	permissions: any = Permissions;
	allUsers: UserListItemModel[];
	filteredUsers: UserListItemModel[] = [];

	customerAuth: CustomerAuthenticationModel = new CustomerAuthenticationModel({});

	usersFilter: string[] = ["active"];

	userSearchText: UntypedFormControl = new UntypedFormControl();

	private _destroyed: Subject<void> = new Subject<void>();

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	@Input() organizationId: number;
	@Input() isAdminSection: boolean = true;
	@Input() isOrganizationProfile: boolean = false;

	@Output() backToList: EventEmitter<string> = new EventEmitter<string>();
	@Output() backToSelectedPartner: EventEmitter<string> = new EventEmitter<string>();

	// @Output() loadCurrentPartner: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		public userStore: OrganizationUsersStore,
		private _customerService: CustomerService,
		private _statusMessageService: StatusMessageService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _redirectService: RoutingRedirectService,
		private _samlService: SamlService
	) {}

	ngOnInit() {
		this.userSearchText.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((term: string) => {
			this.userStore.searchText.set(term);
		});

		this._route.params.pipe(takeUntil(this._destroyed)).subscribe((p: Params) => {
			this.organizationId = +p["id"];

			this.getOrganizationProfile();
			if (!this.isOrganizationProfile) {
				this.refreshUsers();
			}
		});

		if (this.isOrganizationProfile) {
			this.showDashboard();
		} else {
			this._samlService.getAuth(this.organizationId).subscribe((auth: any) => {
				//this.loading = false;
				if (auth) {
					this.customerAuth = new CustomerAuthenticationModel(auth);
					this.customerAuth.type = "saml";
				} else {
					this.customerAuth = new CustomerAuthenticationModel({ type: "default", organizationId: this.organizationId });
				}
			});
		}

		// *ToDo: Currently, I'm using an EventEmitter 'loadSubscriptionsTab' as a way to redirect back to
		//				the 'Subscriptions' tab when cancelling a Create/Edit Subscription process. It uses a
		//				setTimeOut() of 300ms to allow the OrganizationProfile to load first then activate the
		//				tab, but this method is not 100% reliable. Should we revise the tabs in
		// 				OrganizationProfile to use routes like we're doing in account-layout.template.html?
		this._redirectService.loadSubscriptionsTab.pipe(takeUntil(this._destroyed)).subscribe(() => {
			this.showSubscriptions();
		});
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	refreshCustomerList() {
		this._customerService.getOrganizationCustomerList(this.organizationId).subscribe((customers: CustomerSummaryModel[]) => {
			customers.sort((a, b) => {
				if (a.Name < b.Name) return -1;

				if (a.Name > b.Name) return 1;

				return 0;
			});

			this.orgCustomers = customers;
		});
	}

	getOrganizationProfile() {
		if (this.isOrganizationProfile) {
			this._customerService.getMyOrganizationProfile().subscribe((organization: OrganizationProfile) => {
				this.organizationId = organization.OrganizationId;
				this.organizationProfile = organization;
			});
		} else {
			this._customerService.getOrganizationProfile(this.organizationId).subscribe((organization: OrganizationProfile) => {
				this.organizationProfile = organization;
			});
		}
	}

	editOrganization() {
		this.editOrg = Object.assign({}, this.organizationProfile);
		this.editModal.open("md");
		this.editMode = "organization";
		this.modalTitle = "Edit Organization";
	}

	addPartner() {
		this.createModal.open("lg");
		this.createMode = "partner";
		this.modalTitle = "New Partner";
	}

	editPartner(partnerId) {
		this._customerService.getCustomerProfile(partnerId).subscribe((customer: CustomerProfileModel) => {
			this.editCustomer = customer;
			this.editModal.open("md");
			this.editMode = "partner";
			this.modalTitle = "Edit Partner";
		});
	}

	closeEditModal() {
		this.editMode = null;
		this.editModal.dismiss();
	}

	updateCustomerStatusSuccess() {
		this.refreshCustomerList();
	}

	updateOrganizationSuccess() {
		this.editMode = null;
		this.editModal.dismiss();
		this.getOrganizationProfile();
		this._statusMessageService.changeStatusMessage("success", "Organization profile successfully updated.");
	}

	partnerSaveSuccess(partnerName: string) {
		if (this.createMode) {
			this.createModal.dismiss();
			this.createMode = null;
			this._statusMessageService.changeStatusMessage("success", partnerName + " has been added.");
		} else if (this.editMode) {
			this.editModal.dismiss();
			this.editMode = null;
			this._statusMessageService.changeStatusMessage("success", partnerName + " has been updated.");
		}
		this.refreshCustomerList();
	}

	openCustomerProfile(customerId: number) {
		this._router.navigate(["/administration/organizations/" + this.organizationId + "/partners/" + customerId]);
	}

	goBack() {
		this._router.navigate(["../"], { relativeTo: this._route }).then(() => {
			this.viewMode = "all";
		});
	}

	checkIsAdmin() {
		if (this.isAdminSection) {
			return true;
		} else {
			return false;
		}
	}

	editModalClose() {
		this.editOrg = null;
		this.editMode = null;
	}

	showProfile() {
		this.displayTab = "profile";
	}

	showPartners() {
		this.refreshCustomerList();
		this.displayTab = "partners";
	}

	showAllUsers() {
		this.displayTab = "allUsers";
	}

	showDashboard() {
		//this.refreshCustomerList();
		this.displayTab = "dashboard";
	}

	showSubscriptions() {
		this.displayTab = "subscriptions";
	}

	showAuthentication() {
		this.displayTab = "authentication";
	}

	saveUser(user: UserListItemModel) {
		this._statusMessageService.changeStatusMessage("success", "Successfully saved.");
		this.refreshUsers();
	}

	updateUserFilter(checked: boolean, value: string) {
		if (!checked) {
			this.usersFilter.splice(this.usersFilter.indexOf(value), 1);
		} else if (this.usersFilter.indexOf(value) === -1) {
			this.usersFilter.push(value);
		}

		this.userStore.searchText.set(this.userSearchText.value || "");
		this.userStore.activeFilter.set(!this.usersFilter.includes("locked"));
	}

	clearSearch() {
		this.userSearchText.setValue("");
		this.userStore.searchText.set("");
	}

	refreshUsers() {
		this.userStore.loadUsersForOrganization(this.organizationId);
	}

	updateAuth(auth: CustomerAuthenticationModel) {
		this.customerAuth = auth;
	}
}
