import { Component, EventEmitter, Output, OnInit, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { CustomerService } from "../../services/customer.service";
import { UserService } from "../../services/user.service";
import { AuthService } from "../../services/auth.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Permissions } from "../../enums/permissions";
import { MessagesService } from "../../services/messages.service";
import { UserInfoModel } from "../../models/user-info.model";
import { ApiResult } from "../../models/api-result.model";
import { ApplicationService } from "../../services/application.service";
import { CustomerProfileModel } from "../../models/customer-profile.model";
import { Subject } from "rxjs";
import { AlertCounts } from "../../models/alert-counts.model";
import { ContextualPopupService } from "../../services/contextual-popup.service";
import { FadeInAnimation } from "src/app/ocp/animations";
import { ModalComponent } from "../modal/modal.component";
import { AlertsListingComponent } from "../alerts-messages/alerts-listing.component";
import { ErxAlertsListingComponent } from "../alerts-messages/erx-alerts-listing.component";
import { MessagesListingComponent } from "../alerts-messages/messages-listing.component";
import { LocalStorageService } from "../../services/local-storage.service";
import { NewVersionCheckerService } from "src/app/ocp/new-version-checker/new-version-checker.service";

@Component({
	selector: "primary-navigation",
	templateUrl: "./primary-navigation.template.html",
	styleUrls: ["./primary-navigation.scss"],
	animations: [FadeInAnimation]
})
export class PrimaryNavigationComponent implements OnInit, OnDestroy {
	customers: any[] = [];
	accountActions = ["Account Settings", "Change Password", "Logout"];
	userName: string;
	userInitials: string;
	userLoaded: boolean = false;
	customerId: number;
	pharmacyPhone: string;
	numberOfPAAlerts: number = 0;
	numberOfSysAlerts: number = 0;
	numberOfMessages: number = 0;
	numberOfDsAlerts: number = 0;
	isMobile: boolean = false; // not set
	permissions: any = Permissions;
	accountActionSelected: string;
	doseSpotEnabled: boolean;

	showErx: boolean = false;

	private destroyed: Subject<void> = new Subject<void>();

	@Output() toggleNavicon: EventEmitter<string> = new EventEmitter<string>();
	@Output() hideMobileNav: EventEmitter<string> = new EventEmitter<string>();
	@Output() toggleMessageIcon: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeSidePanels: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("notificationAlertsModal") notificationAlertsModal: ModalComponent;
	@ViewChild("erxAlertsModal") erxAlertsModal: ModalComponent;
	@ViewChild("messagesModal") messagesModal: ModalComponent;

	@ViewChild("alerts") alerts: AlertsListingComponent;
	@ViewChild("erx") erx: ErxAlertsListingComponent;
	@ViewChild("messages") messages: MessagesListingComponent;

	get showPatientCare(): boolean {
		return this._authService.hasPermission(this.permissions.patientCare);
	}

	get showReportingTab(): boolean {
		return !this.isMobile;
	}

	get showClinicalToolsTab(): boolean {
		return this._authService.hasPermission(this.permissions.clinicalTools) && !this.isMobile;
	}

	get showPartnerProfileTab(): boolean {
		return this._authService.hasPermission(this.permissions.partnerProfile) || this._authService.hasPermission(this.permissions.viewCustomerIntegrations);
	}

	get showAdministrationTab(): boolean {
		return this._authService.hasPermission(this.permissions.administration) && !this.isMobile;
	}

	get appBuildVersion(): string {
		return 'v' + this._versionCheckService.buildVersion;
	}

	showOrgProfileTab: boolean = false;

	constructor(
		private _customerService: CustomerService,
		private _authService: AuthService,
		private _userService: UserService,
		private _messagesService: MessagesService,
		private _statusMessageService: StatusMessageService,
		private _router: Router,
		private _location: Location,
		public _appService: ApplicationService,
		private _popupService: ContextualPopupService,
		private _localStorageService: LocalStorageService,
		private _versionCheckService: NewVersionCheckerService
	) {}

	ngOnInit() {
		this._customerService.getCustomers().then(
			(results: any[]) => {
				this.customers = results;
			},
			(error: any) => {
				this._statusMessageService.changeStatusMessage("error", error);
			}
		);

		this._userService.userInfo.pipe(takeUntil(this.destroyed)).subscribe((result: UserInfoModel) => {
			this.userName = result.FirstName + " " + result.LastName;
			this.userInitials = this.getUserInitials(result.FirstName, result.LastName);
			this.customerId = result.CustomerId;
			this.userLoaded = true;
			this.pharmacyPhone = result.PharmacyPhone;
			this.doseSpotEnabled = result.DoseSpotEnabled;
			this.showOrgProfileTab = result.OrganizationProfileOn;
			this.showErx = result.DoseSpotClinicianId > 0;
		});

		this._userService.userAlerts.observable.pipe(takeUntil(this.destroyed)).subscribe((result: AlertCounts) => {
			if (result) {
				this.numberOfDsAlerts = result.dsAlertCount;
				this.numberOfPAAlerts = result.paAlertCount;
				this.numberOfSysAlerts = result.sysAlertCount;
			} else {
				[this.numberOfSysAlerts, this.numberOfDsAlerts, this.numberOfPAAlerts] = [0, 0, 0];
			}
		});

		if (this._authService.hasPermission(this.permissions.viewMessages)) {
			this._messagesService.unreadMessageCount.observable.pipe(takeUntil(this.destroyed)).subscribe((result: number) => {
				this.numberOfMessages = result;
			});
			this._messagesService.refreshUnreadMessagesCount();
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	selectCustomer(customerId: number) {
		if (!this.customerId || this._userService.selectedCustomerId === customerId) {
			return;
		}

		this._localStorageService.set("lastCustomer", customerId.toString());

		let self = this;
		this._appService.disablePage = true;
		this._userService.changeCurrentCustomer(customerId).then(
			(results: ApiResult) => {
				if (!results.Success) {
					self._statusMessageService.changeStatusMessage("error", results.PublicMessage);
				} else {
					self._authService.updatePermissions(results.Result.Permissions);
					self._authService.updateRoles(results.Result.Roles);
					self.customerId = customerId;
					self._customerService.getCustomerProfile(customerId).subscribe(
						(customer: CustomerProfileModel) => {
							self._userService.selectedCustomer = customer;
							self._statusMessageService.changeStatusMessage("success", "Active partner changed to '" + customer.Name + "'.");
							self._router.navigate(["/patient-care"]);
							this._appService.disablePage = false;
							self.hideMobileNav.emit();
						},
						error => {
							self._statusMessageService.changeStatusMessage("error", error);
							self._appService.disablePage = false;
						}
					);
				}
			},
			error => {
				self._statusMessageService.changeStatusMessage("error", error);
				self._appService.disablePage = false;
			}
		);
	}

	openModal(notificationType: string) {
		let notificationModal: ModalComponent;

		switch (notificationType) {
			case "systemPA":
				this.alerts.load();
				notificationModal = this.notificationAlertsModal;
				break;
			case "erx":
				this.erx.load();
				notificationModal = this.erxAlertsModal;
				break;
			case "message":
				this.messages.load();
				notificationModal = this.messagesModal;
				break;
		}

		notificationModal.open("md");
	}

	closeModal(notificationType: string) {
		let notificationModal: ModalComponent;

		switch (notificationType) {
			case "systemPA":
				notificationModal = this.notificationAlertsModal;
				break;
			case "erx":
				notificationModal = this.erxAlertsModal;
				break;
			case "message":
				notificationModal = this.messagesModal;
				break;
		}

		notificationModal.close();
	}

	openAlertsOverlay(content: TemplateRef<any>, origin: any) {
		const ref = this._popupService.open<{}>({
			content,
			origin,
			width: "375px",
			height: "510px",
			data: {},
			positionBefore: true
		});

		ref.afterClosed$.subscribe(res => {});
	}

	goToFormularies() {
		this._router.navigate(["/clinical-resources"]);
		this.hideMobileNav.emit();
	}

	goToAccountSettings() {
		this._router.navigate(["/account/settings"]);
		this.hideMobileNav.emit();
	}

	goToChangePassword() {
		this._router.navigate(["/account/change-password"]);
		this.hideMobileNav.emit();
	}

	goToChangeLoginId() {
		this._router.navigate(["/account/change-login"]);
		this.hideMobileNav.emit();
	}

	goToNotificationSettings() {
		this._router.navigate(["/account/notification-settings"]);
		this.hideMobileNav.emit();
	}

	goToMyPreferredErx() {
		this._router.navigate(["/account/manage-preferred-erx"]);
		this.hideMobileNav.emit();
	}

	doLogout() {
		this._userService.logout().subscribe(() => {
			this._authService.doLogout("");
		});
		this.hideMobileNav.emit();
		this.closeSidePanels.emit();
	}

	isTabActive(path: string) {
		let location: string = this._location.path().toLowerCase();
		if (location.startsWith("/")) {
			location = location.substr(1);
		}

		let locationBase: string = location.length ? location.split("/")[0] : "";
		return locationBase === path.toLowerCase();
	}

	closeMobileNav() {
		this.hideMobileNav.emit();
	}

	getUserInitials(firstName, lastName): string {
		if (!firstName && !lastName) return "";
		if (!firstName) return lastName[0];
		if (!lastName) return firstName[0];
		return firstName[0] + lastName[0];
	}

	doAccountAction(action: string) {
		if (action === "formularies") {
			this.goToFormularies();
		} else if (action === "accountSettings") {
			this.goToAccountSettings();
		} else if (action === "notificationSettings") {
			this.goToNotificationSettings();
		} else if (action === "myPreferredErx") {
			this.goToMyPreferredErx();
		} else if (action === "changeLoginId") {
			this.goToChangeLoginId();
		} else if (action === "changePassword") {
			this.goToChangePassword();
		} else if (action === "logout") {
			this.doLogout();
		}
	}
}
