// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th.col-report-name, td.col-report-name {
  width: 24%;
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/report-subscriptions/create-edit-subscription/steps/form-select-report/form-select-report.component.scss"],"names":[],"mappings":"AAME;EACE,UAAA;AALJ","sourcesContent":["@import '../../../../../../../../assets/styles/imports/breakpoints';\r\n@import '../../../../../../../../assets/styles/imports/settings';\r\n@import '../../../../../../../../assets/styles/imports/mixins';\r\n@import '../../../../../../../../assets/styles/imports/functions';\r\n\r\nth, td {\r\n  &.col-report-name {\r\n    width: 24%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
