/**
 * Created by John on 2016-03-02.
 */
import { Component, Output, EventEmitter } from "@angular/core";
import { LocationStrategy } from "@angular/common";

@Component({
	selector: "modal",
	styles: [
		`
   			.enabled {
   				display: block;
   				padding-right: 17px;
   			}
   			.modal {
   				z-index: 99990;
   			}
   			.modal-xs {
   				width: 350px;
   			}
   		`
	],
	templateUrl: "./modal.template.html"
})
export class ModalComponent {
	isOpen: boolean = false;
	isReady: boolean = false;
	id: string = uniqueId("modal_");
	result: ModalResult = ModalResult.None;
	overrideSize: string;
	appContainer = document.getElementById("app-body");
	@Output() onClose: EventEmitter<ModalResult> = new EventEmitter<ModalResult>();
	@Output() onOpen: EventEmitter<any> = new EventEmitter();

	constructor(location: LocationStrategy) {
		location.onPopState(() => {
			if (this.isOpen && this.appContainer.classList.contains("modal-open")) {
				this.appContainer.classList.remove("modal-open");
			}
		});
	}

	open(size?: string) {
		var self = this;
		this.overrideSize = size;
		this.isOpen = true;

		setTimeout(function() {
			self.isReady = true;
			self.onOpen.emit(true);
			self.appContainer.classList.add("modal-open");
		}, 50);
	}

	close() {
		var self = this;
		this.isReady = false;

		setTimeout(function() {
			self.result = ModalResult.Close;
			self.onClose.emit(this.result);
			self.isOpen = false;
			self.appContainer.classList.remove("modal-open");
		}, 100);
	}

	dismiss() {
		var self = this;
		this.isReady = false;

		setTimeout(function() {
			self.result = ModalResult.Dismiss;
			self.onClose.emit(self.result);
			self.isOpen = false;
			self.appContainer.classList.remove("modal-open");
		}, 200);
	}
}

export enum ModalResult {
	None,
	Close,
	Dismiss
}

let id: number = 0;
export function uniqueId(prefix: string): string {
	return prefix + ++id;
}
