import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { MappedClaimModel } from "../models/mapped-claim.model";
import * as _ from "lodash";
import { CustomerAuthenticationModel } from "../models/customer-authentication.model";

@Injectable()
export class SamlService {
	private _getAuthUrl: string = "api/samlauth/getauth";
	private _updateAuthUrl: string = "api/samlauth/updateauth";

	constructor(private _http: HttpService) {}

	getAuth(orgId?: number) {
		return this._http.get(this._getAuthUrl + "?orgId=" + orgId);
	}

	updateAuth(auth: CustomerAuthenticationModel) {
		return this._http.post(this._updateAuthUrl, auth);
	}
}
