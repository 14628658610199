import {Component, OnInit} from '@angular/core';
import {StatusMessageService} from '../shared/components/status-message/status-message.service';
import {AuthService} from "../shared/services/auth.service";
import {ResetPasswordModel} from "../shared/models/reset-password.model";
import {ApiResult} from "../shared/models/api-result.model";

@Component({
  selector: 'login-change-password',
  templateUrl: './change-password.template.html',
  styleUrls: ['./login.scss']
})

export class LoginChangePasswordComponent implements OnInit {
  saving: boolean = false;
  loginId: string = "";

  constructor(private _statusMessageService: StatusMessageService, private _authService: AuthService) {
  }

  ngOnInit() {
    this.loginId = this._authService.getUserName();
  }

  resetPassword(model: ResetPasswordModel) {
    if (model.newPassword !== model.confirmNewPassword) {
      this._statusMessageService.changeStatusMessage('error', 'Confirm password mismatch.');
      return;
    }

    this.saving = true;
    this._authService.changePassword(model).subscribe((x: ApiResult) => {
      this.saving = false;
      if (x.Success) {
        this._statusMessageService.changeStatusMessage('success', 'Your password was successfully reset. You will now be redirected to the login page in 5 seconds...');
        model.currentPassword = '';
        model.newPassword = '';
        model.confirmNewPassword = '';

        setTimeout(() => {
          this._authService.doLogout("");
        }, 5000);
      } else {
        this._statusMessageService.changeStatusMessage('error', x.PublicMessage);
      }
    });
  }
}
