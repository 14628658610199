import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { FormulariesStore } from "src/app/ocp/shared/services/formularies.store";
import { FormularyModel } from "src/app/ocp/shared/models/formulary.model";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DocumentInfoModel } from "src/app/ocp/shared/models/document-info.model";
import { ClinicalResourcesService } from "src/app/ocp/shared/services/clinical-resources.service";

@Component({
	selector: "formularies-tab",
	templateUrl: "./formularies.template.html",
	styleUrls: ["./clinical-resources-tabs.scss"]
})
export class FormulariesTab implements OnInit, OnDestroy {
	loading: boolean = false;
	formularyItems: DataTableModel = new DataTableModel([], []);
	isIos: boolean = false;

	private _destroyed: Subject<void> = new Subject<void>();

	constructor(public formulariesStore: FormulariesStore, private _clinicalResourcesService: ClinicalResourcesService) {}

	ngOnInit() {
		this.formulariesStore.refresh();
		this.initTable();
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	initTable() {
		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "name",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("name", "Name", "", true));
		columns.push(new DataTableColumnModel("dateAdded", "Date Added", "", true));
		columns.push(new DataTableColumnModel("actions", "", "", false));

		this.formularyItems = new DataTableModel(defaultSorting, columns);

		this.formulariesStore.formularies.observable.pipe(takeUntil(this._destroyed)).subscribe((formularies: DocumentInfoModel[]) => {
			this.formularyItems.populate(formularies);
		});
	}

	previewFormulary(formulary: DocumentInfoModel) {
		this._clinicalResourcesService.getDocuments(formulary.DocumentId, null).subscribe((result: DocumentInfoModel[]) => {
			if (!result.length) return;
			let doc = result[0];
			this._clinicalResourcesService.downloadDocument(formulary.DocumentId, true).then(
				(result: Blob) => {
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(result, doc.OriginalFileName);
					} else {
						let fileURL = URL.createObjectURL(result);
						if (this.isIos) {
							window.open(fileURL, "_blank");
						} else {
							window.open(fileURL);
						}
					}
				},
				error => {
					console.log(error);
				}
			);
		});
	}

	downloadFormulary(formulary: DocumentInfoModel) {
		this._clinicalResourcesService.getDocuments(formulary.DocumentId, null).subscribe((result: DocumentInfoModel[]) => {
			if (!result.length) return;
			let doc = result[0];
			this._clinicalResourcesService.downloadDocument(formulary.DocumentId, true).then(
				(result: Blob) => {
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(result, doc.OriginalFileName);
					} else {
						let a = document.createElement("a");
						document.body.appendChild(a);
						a.style.display = "none";

						let url = window.URL.createObjectURL(result);
						a.href = url;
						a.setAttribute("download", doc.OriginalFileName);
						a.click();
						window.URL.revokeObjectURL(url);
						document.body.removeChild(a);
					}
				},
				error => {
					console.log(error);
				}
			);
		});
	}

	detectiOS() {
		const iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
		if (!!navigator.platform) {
			while (iDevices.length) {
				console.log(navigator.platform);
				if (navigator.platform === iDevices.pop()) {
					console.log("isIos = true");
					return (this.isIos = true);
				}
			}
		}
		console.log("isIos = false");
		return (this.isIos = false);
	}
}
