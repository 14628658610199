import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { InputMultiSelectComponent } from "../../shared/components/input-multi-select/input-multi-select.component";
import { InputTextareaComponent } from "../../shared/components/input-textarea/input-textarea.component";
import { InputTimeComponent } from "../../shared/components/input-time/input-time.component";
import { ModalComponent } from "../../shared/components/modal/modal";
import { StatusMessageService } from "../../shared/components/status-message/status-message.service";
import { SystemAlert } from "../../shared/models/system-alert.model";
import { AdminSystemAlertStore } from "../../shared/services/admin-system-alert.store";

@Component({
	selector: "system-alerts-admin",
	templateUrl: "system-alerts.template.html",
	styleUrls: ["../administration-tabs.scss", "system-alerts.component.scss"]
})
export class SystemAlertsAdminTab implements OnInit, OnDestroy {
    @ViewChild("addEditModal") addEditModal: ModalComponent;
    @ViewChild("confirmDisableModal") confirmDisableModal: ModalComponent;
    @ViewChild("select") pharmacySelect: InputMultiSelectComponent;
    @ViewChild("textArea") textArea: InputTextareaComponent;
    @ViewChild("alertForm") form: NgForm;
    @ViewChild("startTime") startTime: InputTimeComponent;
    @ViewChild("endTime") endTime: InputTimeComponent;

    private destroyed = new Subject<void>();

    saving: boolean = false;
    expandedAlertId?: number = null;
    isEdit: boolean;

    constructor(public store: AdminSystemAlertStore, private _status: StatusMessageService) {}
    
    get saveDisabled(): boolean {
        let a: SystemAlert = this.store.alert.get() || {} as SystemAlert;

        return !(a.text && a.text.length > 0 && (((a.pharmacyIds || []).length > 0) || a.enableAllPharmacies) && a.startDate);
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.unsubscribe();
    }

    isExpanded(rowAlertId: number): boolean {
        return this.expandedAlertId === rowAlertId
    }

    enableAllChange(val: boolean){
        if (val){
            this.pharmacySelect.items.forEach(i => {
                i.selected = true;
            });
        } else {
            let a = this.store.alert.get();
            this.pharmacySelect.items.forEach(i => {
                if (a && (a.pharmacyIds || []).some(pid => pid == i.id)){
                    i.selected = true;
                } else {
                    i.selected = false;
                }
            });
        }
    }

    edit(alert: SystemAlert){
        this.isEdit = true;

        this.addEditModal.open("lg");

        this.store.alert.set(new SystemAlert().deserialize(alert));

        this.pharmacySelect.items.forEach(i => {
            if (alert.enableAllPharmacies || (alert.pharmacyIds || []).some(pid => pid == i.id)){
                i.selected = true;
            } else {
                i.selected = false;
            }
        });
    }

    new(){
        this.isEdit = false;

        this.addEditModal.open("lg");

        this.store.alert.set(new SystemAlert());
        let a = this.store.alert.get();
        a.enableAllPharmacies = false;
        this.store.alert.set(a);
        
        this.textArea.value = "";
        this.textArea.inputChanged("");

        this.startTime.value = "";
        this.startTime.inputChanged("");

        this.endTime.value = "";
        this.endTime.inputChanged("");

        this.pharmacySelect.items.forEach(i => i.selected = false)
    }

    disable(alert: SystemAlert){
        this.store.alert.set(new SystemAlert().deserialize(alert));

        this.confirmDisableModal.open("sm");
    }

    private update(){
        this.store.updateAlert().then(result => {
            this.addEditModal.close();
            if (result){
                this._status.changeStatusMessage("success", "System alert successfully updated.");
            } else {
                this._status.changeStatusMessage("error", "There was an error updating this alert.");
            }
        });
    }

    private add(){
        this.store.addAlert().then(result => {
            this.addEditModal.close();
            if (result){
                this._status.changeStatusMessage("success", "System alert successfully added.");
            } else {
                this._status.changeStatusMessage("error", "There was an error adding this alert.");
            }
        });
    }

    save(){
        if (this.isEdit){
            this.update()
        } else {
            this.add();
        }
    }

    cancel(){
        this.addEditModal.close();
    }

    cancelDisable(){
        this.confirmDisableModal.close();
    }

    confirmDisable(){   
        this.store.disableAlert().then(result => {
            this.confirmDisableModal.close();
            if (result){
                this._status.changeStatusMessage("success", "System alert successfully disabled.");
            } else {
                this._status.changeStatusMessage("error", "There was an error disabling this alert.");
            }
        });
    }

    expand(id: number){
        if (!this.expandedAlertId || this.expandedAlertId !== id){
            this.expandedAlertId = id;
        } 

        else if (this.expandedAlertId === id){
            this.expandedAlertId = null;
        } else {
            this.expandedAlertId = id
        }
    }
}
