import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { CustomerAuthenticationModel } from "../../../../models/customer-authentication.model";
import { FileUploadComponent } from "../../../input-file-upload/file-upload.component";
import { DocumentTypeModel } from "../../../../models/document-type.model";
import { UploadFileSuccessModel } from "../../../../models/upload-file-success.model";
import { DocumentInfoModel } from "../../../../models/document-info.model";
import { StatusMessageService } from "../../../status-message/status-message.service";
import { CustomerService } from "src/app/ocp/shared/services/customer.service";
import { SamlService } from "src/app/ocp/shared/services/saml.service";

@Component({
	selector: "configure-authentication-settings",
	templateUrl: "./configure-authentication-settings.template.html",
	styleUrls: ["./configure-authentication-settings.scss"]
})
export class ConfigureAuthenticationSettingsComponent implements OnInit {
	saving: boolean = false;
	currentStep: number = 1;
	authTypeOptions: any[] = [
		{ text: "Default", value: "default" },
		{ text: "SAML", value: "saml" },
		{ text: "Two-Factor", value: "twoFactor" }
	];
	certificateUploadUrl: string = "api/samlauth/UploadPartnerCertificate";
	fileSelected: boolean = false;
	fileTypeList: DocumentTypeModel[] = [];
	fileChanged: boolean = false;
	userListUploadUrl: string = "/";
	uploadSuccess: boolean = false;

	@Input() customerAuth: CustomerAuthenticationModel;
	@Input() file: DocumentInfoModel = new DocumentInfoModel();
	@Input() defaultDocTypeId: number = 1;
	@Input() editMode: string;
	@Input() organizationId: number;
	@Input() customerId: number;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccessful: EventEmitter<CustomerAuthenticationModel> = new EventEmitter<CustomerAuthenticationModel>();
	@Output() certFileUploadSuccess: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("certFileUpload") certFileUpload: FileUploadComponent;
	@ViewChild("userListFileUpload") userListFileUpload: FileUploadComponent;

	get saveButtonText() {
		return "Save";
	}

	constructor(private _statusMessageService: StatusMessageService, private _customerService: CustomerService, private _samlService: SamlService) {}

	ngOnInit() {}

	onUploadComplete(results: any) {
		if (results.success) {
			this._statusMessageService.changeStatusMessage("success", "Certificate successfully uploaded to authentication.");
			this.file.OriginalFileName = results.originalFileName;
			this.file.SystemFileName = results.systemFileName;
			this.uploadSuccess = true;
			if (results.thumbprint) {
				this.customerAuth.certPath = results.thumbprint;
				this.certFileUploadSuccess.emit(results.thumbprint);
			}
		} else {
			this.uploadSuccess = false;
		}
	}

	onFileSelected(fileName: string) {
		if (fileName.toLowerCase().endsWith(".cer")) {
			this.fileChanged = true;
			this.fileSelected = true;
		}
	}

	onUploadError(e: string) {
		this._statusMessageService.changeStatusMessage("error", e);
		this.fileSelected = false;
		this.file.OriginalFileName = "";
		this.file.SystemFileName = "";
	}

	save() {
		if (this.customerAuth.type !== "saml") {
			this.saving = true;

			let self = this;
			setTimeout(function() {
				self.saving = false;
				self.closeModal.emit();
				self._statusMessageService.changeStatusMessage("success", "Authentication settings have been saved.");
			}, 300);
		} else if (this.customerAuth.type === "saml") {
			this._samlService.updateAuth(this.customerAuth).subscribe((result: any) => {
				this.saving = false;
				if (result.Result) {
					this.certFileUpload.upload(result.Result.providerId);
					this._statusMessageService.changeStatusMessage("success", "Authentication successfully saved");
					const customerAuthModel = new CustomerAuthenticationModel(result.Result);
					customerAuthModel.type = "saml";
					this.saveSuccessful.emit(customerAuthModel);
					this.closeModal.emit();
				} else {
					this._statusMessageService.changeStatusMessage("error", "Error saving authentication.");
				}
			});
		}
	}
}
