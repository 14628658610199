import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { PermissionsService } from "../shared/services/permisisons.service";
import { UserInfoModel } from "../shared/models/user-info.model";
import { UserService } from "../shared/services/user.service";
import { LoginResult } from "../shared/models/LoginResult";
import { CustomerService } from "../shared/services/customer.service";
import { CustomerProfileModel } from "../shared/models/customer-profile.model";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { AppStore } from "src/app/app.store";
import { LocalStorageService } from "../shared/services/local-storage.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
	selector: "login",
	templateUrl: "./login.template.html",
	styleUrls: ["./login.scss"]
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
	isAuthenticated: boolean;
	logoutMessage: string = "";
	username: string;
	password: string;
	loading: boolean = false;
	enableBioAuth: boolean = false;
	debug: boolean = false;

	loginResult: LoginResult;
	debugContent: string;

	private _noCaptcha: boolean = false;

	private $destroyed: Subject<void> = new Subject<void>();

	ngOnInit() {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("no-captcha") == "true") {
			this._noCaptcha = true;
		}

		this.debugContent = this._storage.get("log");
		// this._statusMessageService.changeStatusMessage("error", "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
		if (this._authService.isAuthenticated()) {
			if (!this.redirect()) {
				this._authService.doLogout("");
				return;
			}
		}

		this.appStore.OnTokenReceived.pipe(takeUntil(this.$destroyed)).subscribe((data: any) => {
			this._recaptcha.execute("login").subscribe((recaptchaToken: string) => {
				if (this._noCaptcha) {
					recaptchaToken = "notatoken";
				}

				this._authService.bioLogin(this.username, data.token, data.deviceId, recaptchaToken).then(
					(result: LoginResult) => {
						this._zone.run(() => {
							this.loading = false;
							this.finishLogin(result);
						});
					},
					error => {
						this.log("err2");
						this._zone.run(() => {
							this.loading = false;

							if (error == "MORE_DETAILS") {
								this._router.navigate(["/verify-account"]);
								return;
							}
							this._statusMessageService.changeStatusMessage("error", "Unable to login with biometrics");
						});
					}
				);
			});
		});

		this.appStore.OnTokenEstablished.pipe(takeUntil(this.$destroyed)).subscribe(() => {
			this.log("Token established");
			this._zone.run(() => {
				this.loading = false;
				if (this.loginResult) {
					this._storage.set("bioAuthEnabled", "true");
					this.finishLogin(this.loginResult);
					this.loginResult = null;
				}
			});
		});

		this.appStore.OnBioAuthError.pipe(takeUntil(this.$destroyed)).subscribe((msg: string) => {
			this.log("Bio auth error");
			this.log(msg);
			this._zone.run(() => {
				this.enableBioAuth = false;
				this._storage.set("bioAuthEnabled", "false");
				this._statusMessageService.changeStatusMessage("error", msg);
				this.loading = false;
				this._authService.doLogout("");
			});
		});

		let bioEnabled = this._storage.get("bioAuthEnabled") == "true";
		this.enableBioAuth = bioEnabled;

		if (this.enableBioAuth) {
			this.username = this._storage.get("username");
			if (this.username && this.username.trim().length > 0) {
				this.log("Initialized bio login");
				this.loading = true;
				setTimeout(() => {
					this.appStore.doAppLogin();
				}, 100);
			}
		}
	}

	ngAfterViewInit() {
		this._authService.logoutReason.pipe(takeUntil(this.$destroyed)).subscribe((reason: string) => {
			if (reason) {
				this._statusMessageService.changeStatusMessage("error", reason);
			}
		});
	}

	ngOnDestroy(): void {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
	}

	login() {
		this.logoutMessage = "";
		this.loading = true;
		this.loginResult = null;
		this.log("Started login process");

		this._recaptcha.execute("login").subscribe((recaptchaToken: string) => {
			if (this._noCaptcha) {
				recaptchaToken = "notatoken";
			}

			if (this.enableBioAuth) {
				let deviceId = this._storage.get("deviceId");
				this._storage.set("username", this.username);
				this.log("Setting up bio auth");
				this._authService.setupBioLogin(this.username, this.password, deviceId, recaptchaToken).then(
					(result: LoginResult) => {
						this.loginResult = result;
						this.appStore.setupLogin(result.bioAuthToken);
					},
					error => {
						this.loading = false;
						this.log("err1");
						if (error == "MORE_DETAILS") {
							this._router.navigate(["/verify-account"]);
						} else {
							this._statusMessageService.changeStatusMessage("error", error || "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
						}
					}
				);
			} else {
				this._authService.login(this.username, this.password, recaptchaToken).then(
					(result: LoginResult) => {
						this.finishLogin(result);
					},
					error => {
						this.loading = false;
						if (error == "MORE_DETAILS") {
							this._router.navigate(["/verify-account"]);
						} else {
							this._statusMessageService.changeStatusMessage("error", error || "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
						}
					}
				);
			}
		});
	}

	private log(m: string) {
		if (!this.debug) return;

		this._zone.run(() => {
			this.debugContent = `${this.debugContent}\r\n${m}`;
			this._storage.set("log", this.debugContent);
		});
	}

	private finishLogin(result: LoginResult) {
		if (result.requiresPasswordChange.toLowerCase() === "true") {
			this.loading = false;
			this._router.navigate(["/login/change-password"]);
			return;
		}
		this._userService.getUserInfo().subscribe(
			(userInfo: UserInfoModel) => {
				this.loading = false;
				if (userInfo) {
					this._userService.userInfo = userInfo;
					this._userService.updateCustomerInfo(
						userInfo.CustomerMrnNameOverride,
						userInfo.CustomerMrnFormat,
						userInfo.CustomerMrnFormatDescription,
						userInfo.IsMrnRequired,
						userInfo.IsSsnRequired,
						userInfo.IsScreeningEnabled,
						userInfo.HasFormulary,
						userInfo.AllowMultipleFormularies,
						userInfo.DoseSpotEnabled,
						userInfo.OrganizationProfileOn,
						userInfo.IsPrescriber,
						userInfo.DoseSpotClinicianId
					);
				}

				this._customerService.getCustomerProfile(userInfo.CustomerId).subscribe((customer: CustomerProfileModel) => {
					this._userService.selectedCustomer = customer;
				});

				if (!this.redirect()) {
					this._authService.doLogout("");
				}
			},
			error => {
				this.loading = false;
				this._statusMessageService.changeStatusMessage("error", error.error_description || "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
			}
		);
	}

	redirect(): boolean {
		if (this._permissionsService.canNavigateToSection("patient-care")) {
			this._router.navigate(["/patient-care"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("reporting")) {
			this._router.navigate(["/reporting"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("clinical-resources")) {
			this._router.navigate(["/clinical-resources"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("partner-profile")) {
			this._router.navigate(["/customer-profile"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("administration")) {
			this._router.navigate(["/administration"]);
			return true;
		}

		return false;
	}

	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _userService: UserService,
		private _permissionsService: PermissionsService,
		private _customerService: CustomerService,
		private _statusMessageService: StatusMessageService,
		private _storage: LocalStorageService,
		private _zone: NgZone,
		public appStore: AppStore,
		private _recaptcha: ReCaptchaV3Service
	) {}
}
