import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule, MAT_TABS_CONFIG } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";

import { PrimaryNavigationComponent } from "./components/primary-navigation/primary-navigation.component";
import { PatientSearch } from "./components/patient-search/patient-search.component";
import { InputTextBoxComponent } from "./components/input-textbox/input-textbox.component";
import { InputCheckboxComponent } from "./components/input-checkbox/input-checkbox.component";
import { RadioSetComponent } from "./components/radio-set/radio-set.component";
import { InputSelectMenuComponent } from "./components/input-selectmenu/input-selectmenu.component";
import { StatusMessageComponent } from "./components/status-message/status-message.component";
import { IconComponent } from "./components/svg/svg-icons.component";
import { InputTextareaComponent } from "./components/input-textarea/input-textarea.component";
import { AlertsListingComponent } from "./components/alerts-messages/alerts-listing.component";
import { ErxAlertsListingComponent } from "./components/alerts-messages/erx-alerts-listing.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { AlertComponent } from "./components/alerts-messages/alert.component";
import { MessagesListingComponent } from "./components/alerts-messages/messages-listing.component";
import { MessageComponent } from "./components/alerts-messages/message.component";
import { InlineConfirmationComponent } from "./components/inline-confirmation/inline-confirmation.component";
import { POTMedicationsComponent } from "../patient-care/components/plan-of-treatment/tabs/medications-table.component";
import { TherapeuticDuplicationComponent } from "../patient-care/components/plan-of-treatment/tabs/therapeutic-duplication.component";
import { SubmitButton } from "./components/submit-button/submit-button.component";

import { StickyDirective } from "./directives/sticky.directive";

import { DateFormatPipe, LocalTimePipe } from "./pipes/moment.pipe";
import { PhonePipe } from "./pipes/phone.pipe";
import { AgePipe } from "./pipes/age.pipe";
import { GenderPipe } from "./pipes/gender.pipe";
import { CustomerTeamNamePipe } from "./pipes/customer-team-name.pipe";
import { PackagingPreferenceTypeNamePipe } from "./pipes/packaging-peference-name.pipe";
import { PatientStatusNamePipe } from "./pipes/patient-status-name.pipe";
import { InputMaskedTextBoxComponent } from "./components/input-masked-textbox/input-masked-textbox.component";
import { DeliveryStatusPipe } from "./pipes/delivery-status.pipe";
import { InputDatepickerComponent } from "./components/input-datepicker/input-datepicker.component";
import { IfHasPermissionDirective } from "./directives/if-has-permission.directive";
import { IfHasRoleDirective } from "./directives/if-has-role.directive";
import { ValidIfEqualDirective } from "./directives/valid-if-equal.directive";
import { UnsavedChangesAlertComponent } from "./components/unsaved-changes-alert/unsaved-changes-alert.component";
import { PartnerSummary } from "./components/partner-summary/partner-summary.component";
import { OrganizationDetails } from "./components/form-organization-details/organization-details.component";
import { PartnerDetails } from "./components/form-partner-details/partner-details.component";
import { UserDetails } from "./components/form-user-details/user-details.component";
import { UserRole } from "./components/form-user-role/user-role.component";
import { IfHasPermissionAndAlsoDirective } from "./directives/if-has-permissions-and-also.directive";
import { OrganizationProfileComponent } from "./components/organization-profile/organization-profile.component";
import { CustomerTeamSummaryComponent } from "./components/customer-team-summary/customer-team-summary.component";
import { FormTeamDetails } from "./components/form-team-details/form-team-details.component";
import { UserSummary } from "./components/user-summary/user-summary.component";
import { CustomerAuthenticationComponent } from "./components/customer-authentication/customer-authentication.component";
import { FormCustomerAuthenticationComponent } from "./components/form-customer-authentication/form-customer-authentication.component";
import { ConfigureAuthenticationSettingsComponent } from "./components/form-customer-authentication/steps/configure-authentication-settings/configure-authentication-settings.component";

import { SelectMenuComponent } from "./components/select-menu/select-menu.component";
import { CustomerProfileComponent } from "./components/customer-profile/customer-profile.component";
import { CustomerPermissionMatrixComponent } from "./components/customer-permission-matrix/customer-permission-matrix.component";
import { CustomerUsersComponent } from "./components/customer-users/customer-users.component";
import { CustomerTeamsComponent } from "./components/customer-teams/customer-teams.component";
import { InputMinNumComponent } from "./components/input-min-number/input-min-number.component";
import { FocusElementDirective } from "./directives/focus-element.directive";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { FormChangePasswordComponent } from "./components/form-change-password/form-change-password.component";
import { FormEditDoseSpotCustomerComponent } from "./components/form-edit-dosespot-customer/form-edit-dosespot-customer.component";
import { UserReportPermissionsComponent } from "./components/form-user-report-permissions/form-user-report-permissions.component";
import { PermissionMatrixComponent } from "./components/permission-matrix/permission-matrix.component";
import { InputMultiSelectComponent } from "./components/input-multi-select/input-multi-select.component";
import { CustomerPermissionComponent } from "./components/form-user-details/user-customer-permissions/customer-permission/customer-permission.component";
import { UserCustomerPermissionsComponent } from "./components/form-user-details/user-customer-permissions/user-customer-permissions.component";
import { ConfirmMedicationComponent } from "./components/confirm-medication/confirm-medication.component";
import { SessionLoggedOutDialogComponent } from "./components/session-logged-out-dialog/session-logged-out-dialog.component";
import { StepCounter } from "./components/step-counter/step-counter.component";
import { MultiSelectUserRole } from "./components/multi-select-user-role/multi-select-user-role.component";
import { FileUploadComponent } from "./components/input-file-upload/file-upload.component";
import { BulkUserUploadModalComponent } from "./components/bulk-user-upload/bulk-user-upload-modal.component";
import { ConfirmUserListComponent } from "./components/bulk-user-upload/steps/confirm-user-list/confirm-user-list.component";
import { UploadUsersComponent } from "./components/bulk-user-upload/steps/upload-users/upload-users.component";
import { UserLoginIdComponent } from "./components/user-login-id/user-login-id.component";
import { ToggleSwitchComponent } from "./components/toggle-switch/toggle-switch.component";
import { FormAddOverrideComponent } from "./components/form-add-override/form-add-override.component";
import { MODAL_DIRECTIVES } from "./components/modal/modal";
import { AdvancedSearch } from "./components/advanced-search/advanced-search.component";
import { cppdGroupNamePipe } from "./pipes/cppd-group-name.pipe";
import { StoreObjectPipe } from "./pipes/store.pipe";
import { InputTimeComponent } from "./components/input-time/input-time.component";
import { ContextualPopupComponent } from "./components/contextual-popup/contextual-popup.component";
import { SelectRolesComponent } from "./components/bulk-user-upload/steps/select-roles/select-roles.component";
import { CustomerFormulariesComponent } from "./components/customer-formularies/customer-formularies.component";
import { FormFormularyDetailsComponent } from "./components/customer-formularies/form-formulary-details/form-formulary-details.component";
import { HideOnMobileDirective } from "./directives/hide-on-mobile.directive";
import { CustomerIntegrationsComponent } from "./components/customer-integrations/customer-integrations.component";
import { ReportSubscriptionsComponent } from "./components/report-subscriptions/report-subscriptions.component";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { InputAutocompleteComponent } from "./components/input-autocomplete/input-autocomplete.component";
import { PrescriptionItemsCardComponent } from "./components/erx-components/prescription-items-card/prescription-items-card.component";
import { SelectedPharmacyCardComponent } from "./components/erx-components/selected-pharmacy-card/selected-pharmacy-card.component";
import { FormChangePharmacyComponent } from "./components/erx-components/form-change-pharmacy/form-change-pharmacy.component";
import { FormOrderSettingsComponent } from "./components/erx-components/form-order-settings/form-order-settings.component";
import { OrderSettingsCardComponent } from "./components/erx-components/order-settings-card/order-settings-card.component";
import { ErxOrderFormStepOneComponent } from "./components/erx-components/form-erx-step-one/form-erx-step-one.component";
import { SearchMedicationsComponent } from "./components/search-medications/search-medications.component";
import { SearchDosespotMedicationsComponent } from "./components/search-dosespot-medications/search-dosespot-medications.component";
import { FormErxStepTwoComponent } from "./components/erx-components/form-erx-step-two/form-erx-step-two.component";
import { PharmacySummaryComponent } from "./components/erx-components/form-change-pharmacy/pharmacy-summary/pharmacy-summary.component";
import { ModalOrderSettingsComponent } from "./components/erx-components/modal-order-settings/modal-order-settings.component";
import { ErxNewOrderComponent } from "./components/erx-components/erx-new-order/erx-new-order.component";
import { CustomerPreferredErxComponent } from "./components/customer-preferred-erx/customer-preferred-erx.component";
import { PreferredErxTableComponent } from "./components/erx-components/preferred-erx-table/preferred-erx-table.component";
import { CppdDetailsComponent } from "./components/reporting-dashboard/cppd-details/cppd-details.component";
import { ReportingDashboardComponent } from "./components/reporting-dashboard/dashboard.component";
import { CppdBlockComponent } from "./components/reporting-dashboard/cppd-block/cppd-block.component";
import { DosespotStatusPipe } from "./pipes/dosespot-status.pipe";
import { DosespotStatusStylePipe } from "./pipes/dosespot-status-style.pipe";
import { SearchDosespotSupplyComponent } from "./components/search-dosespot-supply/search-dosespot-supply.component";
import { SearchDosespotCompoundComponent } from "./components/search-dosespot-compound/search-dosespot-compound.component";
import { LocalDatePipe } from "./pipes/local-date.pipe";
import { MultiselectActionsRowComponent } from "./components/multiselect-actions-row/multiselect-actions-row.component";
import { CopyPreferencesStepOneComponent } from "./components/customer-preferred-erx/copy-preferences-step-one/copy-preferences-step-one.component";
import { CopyPreferencesStepTwoComponent } from "./components/customer-preferred-erx/copy-preferences-step-two/copy-preferences-step-two.component";
import { FormCustomerErxSettingsComponent } from "./components/form-customer-erx-settings/form-customer-erx-settings.component";
import { CreateEditSubscriptionComponent } from "./components/report-subscriptions/create-edit-subscription/create-edit-subscription.component";
import { UserRoleTagComponent } from "./components/user-role-tag/user-role-tag.component";
import { FormSelectReportComponent } from "./components/report-subscriptions/create-edit-subscription/steps/form-select-report/form-select-report.component";
import { FormConfigureSubscriptionComponent } from "./components/report-subscriptions/create-edit-subscription/steps/form-configure-subscription/form-configure-subscription.component";
import { FormSelectSubscribersComponent } from "./components/report-subscriptions/create-edit-subscription/steps/form-select-subscribers/form-select-subscribers.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { UserAccountMenuComponent } from "./components/primary-navigation/user-account-menu/user-account-menu.component";

@NgModule({
	imports: [
		CommonModule,
		MatExpansionModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		MatTabsModule,
		MatMenuModule,
		MatSelectModule,
		MatChipsModule,
		MatTooltipModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgxMaskDirective,
		NgxMaskPipe,
		NgxMatTimepickerModule,
		MatButtonModule
	],
	declarations: [
		PrimaryNavigationComponent,
		PatientSearch,
		AdvancedSearch,
		PermissionMatrixComponent,
		InputCheckboxComponent,
		InputDatepickerComponent,
		InputTimeComponent,
		InputMaskedTextBoxComponent,
		InputMinNumComponent,
		InputMultiSelectComponent,
		InputTextBoxComponent,
		LoadingSpinnerComponent,
		RadioSetComponent,
		InputSelectMenuComponent,
		StatusMessageComponent,
		FormChangePasswordComponent,
		DataTableComponent,
		FormTeamDetails,
		FormEditDoseSpotCustomerComponent,
		ConfirmMedicationComponent,
		CustomerTeamsComponent,
		CustomerTeamSummaryComponent,
		CustomerUsersComponent,
		StickyDirective,
		SessionLoggedOutDialogComponent,
		FocusElementDirective,
		IfHasPermissionDirective,
		IfHasRoleDirective,
		IfHasPermissionAndAlsoDirective,
		ValidIfEqualDirective,
		IconComponent,
		AgePipe,
		cppdGroupNamePipe,
		DateFormatPipe,
		LocalTimePipe,
		PhonePipe,
		StoreObjectPipe,
		CustomerTeamNamePipe,
		DeliveryStatusPipe,
		GenderPipe,
		PackagingPreferenceTypeNamePipe,
		PatientStatusNamePipe,
		InputTextareaComponent,
		OrganizationProfileComponent,
		CustomerProfileComponent,
		AlertsListingComponent,
		ErxAlertsListingComponent,
		LoadingSpinnerComponent,
		AlertComponent,
		UnsavedChangesAlertComponent,
		PartnerSummary,
		OrganizationDetails,
		PartnerDetails,
		UserDetails,
		CustomerPermissionComponent,
		UserCustomerPermissionsComponent,
		UserReportPermissionsComponent,
		MessagesListingComponent,
		MessageComponent,
		UserRole,
		UserSummary,
		CustomerPermissionMatrixComponent,
		SelectMenuComponent,
		InlineConfirmationComponent,
		POTMedicationsComponent,
		TherapeuticDuplicationComponent,
		SubmitButton,
		StepCounter,
		MultiSelectUserRole,
		FileUploadComponent,
		BulkUserUploadModalComponent,
		ConfirmUserListComponent,
		UploadUsersComponent,
		UserLoginIdComponent,
		ToggleSwitchComponent,
		FormAddOverrideComponent,
		MODAL_DIRECTIVES,
		CustomerAuthenticationComponent,
		FormCustomerAuthenticationComponent,
		ConfigureAuthenticationSettingsComponent,
		ContextualPopupComponent,
		SelectRolesComponent,
		CustomerFormulariesComponent,
		FormFormularyDetailsComponent,
		HideOnMobileDirective,
		CustomerIntegrationsComponent,
		YesNoPipe,
		ReportSubscriptionsComponent,
		InputAutocompleteComponent,
		PrescriptionItemsCardComponent,
		SelectedPharmacyCardComponent,
		FormChangePharmacyComponent,
		FormOrderSettingsComponent,
		OrderSettingsCardComponent,
		ErxOrderFormStepOneComponent,
		SearchMedicationsComponent,
		SearchDosespotMedicationsComponent,
		FormErxStepTwoComponent,
		PharmacySummaryComponent,
		ModalOrderSettingsComponent,
		ErxNewOrderComponent,
		CustomerPreferredErxComponent,
		PreferredErxTableComponent,
		CppdDetailsComponent,
		ReportingDashboardComponent,
		CppdBlockComponent,
		DosespotStatusPipe,
		DosespotStatusStylePipe,
		SearchDosespotSupplyComponent,
		SearchDosespotCompoundComponent,
		LocalDatePipe,
		MultiselectActionsRowComponent,
		CopyPreferencesStepOneComponent,
		CopyPreferencesStepTwoComponent,
		FormCustomerErxSettingsComponent,
		CreateEditSubscriptionComponent,
		UserRoleTagComponent,
		FormSelectReportComponent,
		FormConfigureSubscriptionComponent,
		FormSelectSubscribersComponent,
		UserAccountMenuComponent
	],
	providers: [
		{
			provide: MAT_TABS_CONFIG,
			useValue: {
				animationDuration: "500ms"
			}
		},
		provideNgxMask()
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		PrimaryNavigationComponent,
		PatientSearch,
		AdvancedSearch,
		PermissionMatrixComponent,
		InputCheckboxComponent,
		InputDatepickerComponent,
		InputTimeComponent,
		InputMaskedTextBoxComponent,
		InputMinNumComponent,
		InputMultiSelectComponent,
		InputTextBoxComponent,
		LoadingSpinnerComponent,
		RadioSetComponent,
		InputSelectMenuComponent,
		StatusMessageComponent,
		SessionLoggedOutDialogComponent,
		FormChangePasswordComponent,
		DataTableComponent,
		FormTeamDetails,
		FormEditDoseSpotCustomerComponent,
		ConfirmMedicationComponent,
		CustomerTeamsComponent,
		CustomerTeamSummaryComponent,
		CustomerUsersComponent,
		StickyDirective,
		FocusElementDirective,
		IfHasPermissionDirective,
		IfHasRoleDirective,
		IfHasPermissionAndAlsoDirective,
		ValidIfEqualDirective,
		IconComponent,
		AgePipe,
		cppdGroupNamePipe,
		DateFormatPipe,
		LocalTimePipe,
		PhonePipe,
		StoreObjectPipe,
		CustomerTeamNamePipe,
		DeliveryStatusPipe,
		GenderPipe,
		PackagingPreferenceTypeNamePipe,
		PatientStatusNamePipe,
		InputTextareaComponent,
		OrganizationProfileComponent,
		CustomerProfileComponent,
		AlertsListingComponent,
		ErxAlertsListingComponent,
		LoadingSpinnerComponent,
		AlertComponent,
		UnsavedChangesAlertComponent,
		PartnerSummary,
		OrganizationDetails,
		PartnerDetails,
		UserDetails,
		UserReportPermissionsComponent,
		MessagesListingComponent,
		MessageComponent,
		UserRole,
		UserSummary,
		CustomerPermissionMatrixComponent,
		SelectMenuComponent,
		InlineConfirmationComponent,
		POTMedicationsComponent,
		TherapeuticDuplicationComponent,
		SubmitButton,
		StepCounter,
		MultiSelectUserRole,
		FileUploadComponent,
		BulkUserUploadModalComponent,
		ConfirmUserListComponent,
		UploadUsersComponent,
		UserLoginIdComponent,
		ToggleSwitchComponent,
		FormAddOverrideComponent,
		MODAL_DIRECTIVES,
		CustomerAuthenticationComponent,
		FormCustomerAuthenticationComponent,
		ConfigureAuthenticationSettingsComponent,
		ContextualPopupComponent,
		MatTabsModule,
		SelectRolesComponent,
		MatMenuModule,
		MatSelectModule,
		MatChipsModule,
		CustomerFormulariesComponent,
		FormFormularyDetailsComponent,
		HideOnMobileDirective,
		YesNoPipe,
		MatTooltipModule,
		InputAutocompleteComponent,
		PrescriptionItemsCardComponent,
		SelectedPharmacyCardComponent,
		FormChangePharmacyComponent,
		FormOrderSettingsComponent,
		OrderSettingsCardComponent,
		ErxOrderFormStepOneComponent,
		SearchMedicationsComponent,
		SearchDosespotMedicationsComponent,
		FormErxStepTwoComponent,
		PharmacySummaryComponent,
		ModalOrderSettingsComponent,
		ErxNewOrderComponent,
		CustomerPreferredErxComponent,
		PreferredErxTableComponent,
		CppdDetailsComponent,
		ReportingDashboardComponent,
		CppdBlockComponent,
		DosespotStatusPipe,
		DosespotStatusStylePipe,
		SearchDosespotSupplyComponent,
		SearchDosespotCompoundComponent,
		LocalDatePipe,
		MultiselectActionsRowComponent,
		CopyPreferencesStepOneComponent,
		CopyPreferencesStepTwoComponent,
		CreateEditSubscriptionComponent,
		UserRoleTagComponent,
		FormSelectReportComponent,
		FormConfigureSubscriptionComponent,
		FormSelectSubscribersComponent
	]
})
export class SharedModule {}
