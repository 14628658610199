import {Component} from '@angular/core';
import {StatusMessageService} from '../shared/components/status-message/status-message.service';
import {AuthService} from "../shared/services/auth.service";
import {ApiResult} from "../shared/models/api-result.model";

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.template.html',
  styleUrls: ['./login.scss']
})

export class ForgotPasswordComponent {
  saving: boolean = false;
  username: string;

  constructor(private _statusMessageService: StatusMessageService, private _authService: AuthService) {
  }

  reset() {
    this.saving = true;
    this._authService.forgotPassword(this.username).subscribe((result: ApiResult) => {
      this.saving = false;
      if (result.Success) {
        this._statusMessageService.changeStatusMessage("success", "An email has been sent with instructions for resetting your password.");
      } else {
        this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
      }
    });
  }
}
