/**
 * Created by John on 2016-03-02.
 */
import {Component, Input, OnInit} from "@angular/core";
import {ModalComponent} from "./modal.component";

@Component({
    selector:"modal-body",
    templateUrl: "./modal-body.template.html",
    styles:[`
    .scroll{
    padding-top: 0;    
    overflow:scroll;
    }
    
    .scrollLess{
    padding-top: 0;
    max-height:60%;
    }

    .overflow-visible {
        overflow: visible !important;
    }
    `]
})
export class ModalBodyComponent implements OnInit {
    @Input() disableScroll:boolean = false;
    @Input() overflowVisible:boolean = false;
    @Input() heightOverride?:number = null;

    height:string = "200px";

    constructor(private modal:ModalComponent){}

    ngOnInit(){
        var s = this.windowSize();

        this.height = (this.heightOverride || (s.height * 0.6)) + "px";
    }

    windowSize() {
        var myWidth = 0, myHeight = 0;
        if( typeof( window.innerWidth ) == 'number' ) {
            //Non-IE
            myWidth = window.innerWidth;
            myHeight = window.innerHeight;
        } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
            //IE 6+ in 'standards compliant mode'
            myWidth = document.documentElement.clientWidth;
            myHeight = document.documentElement.clientHeight;
        } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
            //IE 4 compatible
            myWidth = document.body.clientWidth;
            myHeight = document.body.clientHeight;
        }

        return {height: myHeight, width: myWidth};
    }
}