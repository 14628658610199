import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ISearchService } from "./search.interface";

@Component({
	selector: "patient-search",
	templateUrl: "./patient-search.template.html",
	styleUrls: ["./patient-search.scss"]
})
export class PatientSearch implements OnInit {
	@Input() placeholderText: string;
	@Input() textDisplayField: string; //To add multiple keys separate in one string by ';'
	@Input() isDisabled: boolean = false;
	@Input() keySeparator: string = " ";
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() readOnly: boolean = false;
	@Input() searchOnEnter: boolean;

	@Output() resultsReturned: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchCleared: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchTextUsed: EventEmitter<string> = new EventEmitter<string>();

	searchText: UntypedFormControl = new UntypedFormControl();

	searching: boolean = false;
	showingAll: boolean = true;
	enterPressed: boolean = false;
	inputFocused: boolean = false;
	loading: boolean = false;

	results: any[] = [];

	@ViewChild("search") search: any;

	get disableSearchBtn(): boolean {
		if (!this.searchText || !this.searchText.value || this.searchText.value.length === 0) {
			return true;
		}
		return false;
	}

	ngOnInit() {}

	private handleSearchResults(results: any[]) {
		if (results && results[0] == "ignore") return;
		this.results = results;
		this.searching = false;
		if (!this.searchText.value.length) {
			this.searchCleared.emit();
		} else {
			this.resultsReturned.emit(this.results);
		}
	}

	clearSearch() {
		this.searchText.reset("", { emitEvent: false });
		this.searchCleared.emit();
	}

	doSearch(searchText: string) {
		if (!searchText || searchText.length === 0) {
			return;
		}
		this.loading = true;
		this.searchService.search(searchText).then((results: any) => {
			this.showingAll = false;
			this.enterPressed = true;
			this.results = results;
			this.handleSearchResults(this.results);
			this.searchTextUsed.emit(searchText);
			this.loading = false;
		});
	}

	setFocusOn() {
		this.inputFocused = true;
	}

	setFocusOff() {
		this.inputFocused = false;
	}

	constructor(private searchService: ISearchService) {}
}
