
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { StatusMessageModel } from "./status-message.model";
import { StatusMessageService } from "./status-message.service";
import { Subject } from "rxjs";
import { AppStore } from 'src/app/app.store';
import { Router } from '@angular/router';
import { RxService } from '../../services/rx.service';

@Component({
	selector: "status-message", // <status-message></status-message>
	templateUrl: "./status-message.template.html",
	styleUrls: ["./status-message.scss"]
})
export class StatusMessageComponent implements OnInit, OnDestroy {
	alertType: string;
	message: string;
	isOpen: boolean = false;
	pushTopic: string;
	@Input() disableAlertService: boolean = false;
	@Input() isStaticAlert: boolean = false;

	openPriority: number = 0;

	private destroyed: Subject<void> = new Subject<void>();

	ngOnInit() {
		if (!this.disableAlertService) {
			this._statusMessageService
				.getStatusMessageChangedEmitter().pipe(
					takeUntil(this.destroyed))
				.subscribe((message: StatusMessageModel) => this.showMessage(message));

			this._statusMessageService
				.getCloseStatusMessageEmitter().pipe(
					takeUntil(this.destroyed))
				.subscribe(() => this.closeMessage())
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	showMessage(message: StatusMessageModel) {
		this.alertType = message.MessageType;
		this.message = message.Message;
		this.pushTopic = message.PushTopic;

		this.openPriority++;
		this.isOpen = true;

		let self = this;

		if (!this.isStaticAlert && message.Duration > 0) {
			setTimeout(function () {
				self.openPriority--;
				if (self.openPriority === 0) {
					self.isOpen = false;
				}
			}, message.Duration);
		}
	}

	goToERx(e) {
		e.preventDefault();

		if (!this._app.IsMobile.get()) {
			this._router.navigate(["/erx-alerts"]);
		} else {
			this._rxService.getCustomerERxUrl().then(
				(val: any) => {
					let eRxUrl = val.changingThisBreaksApplicationSecurity;
					window.open(eRxUrl, "_blank");
				},
				(error: any) => {

				}
			);
		}

		this.closeMessage();
	}

	closeMessage() {
		this.isOpen = false;
	}

	constructor(private _statusMessageService: StatusMessageService, private _app: AppStore, private _router: Router, private _rxService: RxService) { }

	getPushTopicIcon(type: string) {
		switch (type) {
			case "erx":
				return "local_pharmacy";
			case "pa":
				return "assignment_turned_in";
			case "sys":
				return "devices";
			default:
				return "";
		}
	}
}
