import { Injectable, NgZone } from "@angular/core";
import { interval, Observable, Subscription } from "rxjs";
import { StoreObject } from "../shared/models/store-object.model";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";

@Injectable()
export class NewVersionCheckerService {
	updateAvailable: StoreObject<boolean> = new StoreObject<boolean>(false);

	private readonly intervalSource: Observable<number>;
	private intervalSubscription: Subscription;

	private newVersionSubscription: Subscription;

	public readonly buildVersion: string = "2.9.6.8";

	constructor(private _zone: NgZone, private _config: AppConfig, private _http: HttpClient) {
		const intervalTime = this._config.refreshIntervalMinutes * 60 * 1000;
		this.intervalSource = interval(intervalTime);
		this.registerForUpdates();
	}

	private registerForUpdates(): void {
		this.newVersionSubscription?.unsubscribe();
		this.intervalSubscription?.unsubscribe();

		this.checkBuildVersion().then((version: string) => {
			if (this.requiresUpdate(version)) {
				this.updateAvailable.set(true);
			}
		});

		this._zone.runOutsideAngular(() => {
			this.intervalSubscription = this.intervalSource.subscribe(() => {
				this.checkBuildVersion().then((version: string) => {
					if (this.requiresUpdate(version)) {
						this.updateAvailable.set(true);
					}
				});
			});
		});
	}

	private requiresUpdate(currentVersion: string): boolean {
		if (this.buildVersion === currentVersion) return false;

		const currentVersionParts = currentVersion.split(".");
		const buildVersionParts = this.buildVersion.split(".");

		const maxLength = Math.max(currentVersionParts.length, buildVersionParts.length);

		for (let i = 0; i < maxLength; i++) {
			const currentPart = currentVersionParts[i] || 0;
			const buildPart = buildVersionParts[i] || 0;

			if (currentPart > buildPart) {
				return true;
			} else if (currentPart < buildPart) {
				return false;
			}
		}
	}

	applyUpdate(): void {
		this.updateAvailable.set(false);
		document.location.reload();
	}

	checkBuildVersion(): Promise<string> {
		return new Promise<string>(resolve => {
			var ts = new Date().getTime();

			this._http.get("assets/config/build.txt?ts=" + ts, { responseType: "text" }).subscribe((data: string) => {
				resolve(data);
			});
		});
	}
}
