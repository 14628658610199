import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal";
import { CustomerAuthenticationModel } from "../../models/customer-authentication.model";

@Component({
	selector: "form-customer-authentication",
	templateUrl: "./form-customer-authentication.template.html"
})
export class FormCustomerAuthenticationComponent implements OnInit {
	@Input() customerAuth: CustomerAuthenticationModel;
	@Input() editMode: string;

	@Output() onClose: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<CustomerAuthenticationModel> = new EventEmitter<CustomerAuthenticationModel>();
	@Output() certFileUploadSuccess: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("authEditModal") authEditModal: ModalComponent;

	constructor() {}

	ngOnInit() {}

	showDialog(authModel: CustomerAuthenticationModel) {
		this.customerAuth = authModel;
		this.authEditModal.open("md");
	}

	closeDialog() {
		this.authEditModal.dismiss();
	}
}
