import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {RxService} from "../../../shared/services/rx.service";
import {ApiResult} from "../../../shared/models/api-result.model";
import {DrugInteractionScreeningResultModel} from "../../../shared/models/drug-interaction-screening-result.model";
import {PatientService} from "../../../shared/services/patient.service";
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";

@Component({
  selector: 'drug-screening',
  templateUrl: './drug-screening.template.html',
  styleUrls: ['./drug-screening.scss']
})

export class DrugScreeningComponent implements OnInit {
  loading: boolean = false;
  drugScreeningResults: DrugInteractionScreeningResultModel[] = [];

  @Input() patientId: number;
  @Input() gpi: string;
  @Input() isClinicalTools: boolean = false;

  @Output() doneScreening: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _rxService: RxService,
              private _patientService: PatientService,
              private _statusMessageService:StatusMessageService) {
  }

  ngOnInit() {
    console.log("IN drug screen init");
    this.loading = true;
    if (this.isClinicalTools) {
      this._patientService.getDrugScreening(this.patientId).subscribe((results: DrugInteractionScreeningResultModel[]) => {
        this.loading = false;
        this.drugScreeningResults = results;
        this.doneScreening.emit();
      });
    } else {
      this._rxService.screenDrugInteractions(this.patientId, this.gpi).subscribe((result: ApiResult) => {
        this.loading = false;
        if (result.Success) {
          this.drugScreeningResults = result.Result;
          this.doneScreening.emit();
        } else {
          this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
        }
      });
    }
  }
}
