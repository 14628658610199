export class CustomerAuthenticationModel {
	type: string;
	providerId: number;
	endpoint: string;
	endpointId: string;
	certPath: string;
	subdomain: string;
	notes: string;
	isEnabled: boolean;
	organizationId: number;
	userListFileName: string;
	scimAuthToken: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}
