// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-search-filters-form {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}
.patient-search-filters-form .row.dual-column > div[class*=col-]:first-of-type {
  padding-right: 0.5rem;
}
.patient-search-filters-form .row.dual-column > div[class*=col-]:last-of-type {
  padding-left: 0.5rem;
}
.patient-search-filters-form .preferred-team-checkbox {
  margin: 0.5rem 0 0 0.5rem;
}

:host-context(.modal-body) .patient-search-filters-form {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/patient-care/components/form-filter-patient-search/form-filter-patient-search.component.scss"],"names":[],"mappings":"AAKA;EACE,wBAAA;EACA,uBAAA;AAJF;AAQM;EACE,qBAAA;AANR;AASM;EACE,oBAAA;AAPR;AAYE;EACE,yBAAA;AAVJ;;AAeE;EACE,wBAAA;EACA,uBAAA;AAZJ","sourcesContent":["@import '../../../../../assets/styles/imports/_breakpoints';\r\n@import '../../../../../assets/styles/imports/_settings';\r\n@import '../../../../../assets/styles/imports/_mixins';\r\n@import '../../../../../assets/styles/imports/_functions';\r\n\r\n.patient-search-filters-form {\r\n  margin-right: rem-calc(-15px);\r\n  margin-left: rem-calc(-15px);\r\n\r\n  .row.dual-column {\r\n    > div[class*=\"col-\"] {\r\n      &:first-of-type {\r\n        padding-right: rem-calc(8px);\r\n      }\r\n\r\n      &:last-of-type {\r\n        padding-left: rem-calc(8px);\r\n      }\r\n    }\r\n  }\r\n\r\n  .preferred-team-checkbox {\r\n    margin: rem-calc(8px) 0 0 rem-calc(8px);\r\n  }\r\n}\r\n\r\n:host-context(.modal-body) {\r\n  .patient-search-filters-form {\r\n    margin-right: rem-calc(-5px);\r\n    margin-left: rem-calc(-5px);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
