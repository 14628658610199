import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { CustomerAuthenticationModel } from "../../models/customer-authentication.model";
import { FormCustomerAuthenticationComponent } from "../form-customer-authentication/form-customer-authentication.component";
import { StatusMessageService } from "../status-message/status-message.service";
import * as _ from "lodash";
import { SamlService } from "../../services/saml.service";

@Component({
	selector: "customer-authentication",
	templateUrl: "customer-authentication.template.html",
	styleUrls: ["customer-authentication.scss"]
})
export class CustomerAuthenticationComponent implements OnInit {
	editMode: string;
	@Input() customerAuth: CustomerAuthenticationModel;
	@Output() customerAuthUpdated: EventEmitter<CustomerAuthenticationModel> = new EventEmitter<CustomerAuthenticationModel>();

	@ViewChild("editAuthModal") editAuthModal: FormCustomerAuthenticationComponent;

	get authenticationType() {
		if (this.customerAuth.type === "default") {
			return "Default";
		} else if (this.customerAuth.type === "saml") {
			return "SAML";
		} else if (this.customerAuth.type === "twoFactor") {
			return "Two-Factor";
		}
	}

	constructor(private _statusMessageService: StatusMessageService, private _samlService: SamlService) {}

	ngOnInit() {}

	editAuthentication(authModel) {
		this.editMode = "allSettings";
		this.editAuthModal.showDialog(Object.assign({}, authModel));
	}

	downloadUserList() {
		//
	}

	authEditSuccess(auth: CustomerAuthenticationModel) {
		this.customerAuthUpdated.emit(auth);
	}

	certUploadSuccess(thumbprint: string) {
		this.customerAuth.certPath = thumbprint;
	}
}
