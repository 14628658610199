// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
   			.profile-detail {
   				margin-bottom: 1.5rem;
   			}
   			.profile-detail label {
   				display: block;
   			}
   		`, "",{"version":3,"sources":["webpack://./src/app/ocp/patient-care/components/patient-details-tabs/form-edit-instructions/form-edit-instructions.component.ts"],"names":[],"mappings":";MACM;OACC,qBAAqB;MACtB;MACA;OACC,cAAc;MACf","sourcesContent":["\n   \t\t\t.profile-detail {\n   \t\t\t\tmargin-bottom: 1.5rem;\n   \t\t\t}\n   \t\t\t.profile-detail label {\n   \t\t\t\tdisplay: block;\n   \t\t\t}\n   \t\t"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
