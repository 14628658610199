import { of as observableOf, Observable, Subject } from "rxjs";

import { switchMap, distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ISearchService } from "../patient-search/search.interface";
import * as _ from "lodash";
import { ApplicationService } from "../../services/application.service";

@Component({
	selector: "advanced-search",
	templateUrl: "./advanced-search.template.html",
	styleUrls: ["./advanced-search.scss"]
})
export class AdvancedSearch implements OnInit {
	@Input() showAutocomplete: boolean = false;
	@Input() placeholderText: string;
	@Input() primaryDisplayField: string; //To add multiple keys separate in one string by ';'
	@Input() secondaryTextFields: string[]; // format: [{'fields' : 'property1;property2;...', 'delimiter': ',', 'label': 'Name'}, ...]
	@Input() isDisabled: boolean = false;
	@Input() keySeparator: string = ", ";
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() readOnly: boolean = false;
	@Input() minSearchLength: number = 3;
	@Input() searchOnEnter: boolean;
	@Input() disableFilterOptions: boolean;
	@Input() tableColumnLabels: string[];
	@Input() disableAutoSearch: boolean = false;
	@Input() disableResultsList: boolean = false;
	@Input() numFiltersApplied: number;
	@Input() type: string = null;
	@Input() searchTextToDisplayOnSelectFn: Function = null;
	@Input() isInvalid: boolean = false;
	@Input() defaultResultSet: any[] = null;
	@Input() selectOnEnter: boolean = false;
	@Input() hintText: string = null;
	@Input() showEnterButton: boolean = false;
	@Input() showNoResults: boolean = false;

	@Output() resultsReturned: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchCleared: EventEmitter<any> = new EventEmitter<any>();
	@Output() doFilter: EventEmitter<any> = new EventEmitter<any>();
	@Output() onInputClick: EventEmitter<any> = new EventEmitter<any>();

	searchText: UntypedFormControl = new UntypedFormControl();
	subject = new Subject();

	searching: boolean = false;
	showingAll: boolean = true;
	enterPressed: boolean = false;
	inputFocused: boolean = false;
	itemSelected: boolean = false;

	filterBtnSelected: boolean = false;

	@ViewChild("search") search: any;

	autocompleteResults: any[] = [];
	filteredAutoCompleteResults: any[] = [];

	ngOnInit() {
		if (this.disableAutoSearch) {
			return;
		}
		this.searchText.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged(),
				switchMap(text => {
					if (text.length === 0 && this.defaultResultSet) {
						this.handleSearchResults(this.defaultResultSet);
						return observableOf(["ignore"]);
					}

					if (text.length < this.minSearchLength) {
						this.searching = false;
						if (!this.showingAll) {
							if (this.enterPressed) {
								this.enterPressed = false;
							} else {
								this.searchCleared.emit();
								this.showingAll = true;
							}
						}
						return observableOf(["ignore"]);
					}
					if (!this.showAutocomplete || (text.length >= this.minSearchLength && this.showAutocomplete)) {
						this.searching = true;
						this.showingAll = false;
						this.itemSelected = false;
						return this.searchService.search(this.getSearchParameters(text));
					} else {
						this.showingAll = false;
						this.searching = false;
						return observableOf([]);
					}
				})
			)
			.subscribe((results: any[]) => {
				this.handleSearchResults(results);
				this.resultsReturned.emit();
			});
	}

	filterBtnClick() {
		this.filterBtnSelected = !this.filterBtnSelected;
	}

	handleSearchResults(result: any) {
		let results = [];
		if (result.Result) {
			results = result.Result;
		} else {
			results = result;
		}
		if (results && results[0] == "ignore") return;
		let textVal = this.searchText.value;
		let text = textVal ? textVal : "";
		if ((!this.showAutocomplete && !text.length) || (text.length < this.minSearchLength && this.showAutocomplete)) {
			this.searching = false;

			if (!this.selectOnEnter) {
				this.searchCleared.emit();
			}
		}

		if (this.showAutocomplete) {
			this.searching = false;

			if (!results) {
				return;
			}

			for (let i: number = 0; i < results.length; i++) {
				results[i].focus = new EventEmitter<any>();
			}

			this.autocompleteResults = results;
			if (this.disableFilterOptions) {
				this.filteredAutoCompleteResults = results;
			}
			this.doFilter.emit();
		} else {
			if (this.searchText.value.length) {
				this.searching = false;
				this.resultsReturned.emit(results);
			}
		}
	}

	getPrimaryDisplayField(result: any) {
		const textToDisplay = this.primaryDisplayField ? this.primaryDisplayField.split(";") : null;
		let searchText = "";
		if (textToDisplay && textToDisplay.length > 0) {
			for (let i = 0; i < textToDisplay.length; i++) {
				let last = i === textToDisplay.length - 1;
				let key = textToDisplay[i];
				searchText += last ? result[key] || "" : (result[key] || "") + this.keySeparator;
			}
			return searchText;
		} else {
			return result;
		}
	}

	getSecondaryDisplayField(options: any, result: any) {
		const textToDisplay = options.fields ? options.fields.split(";") : null;
		let searchText = "";
		if (textToDisplay && textToDisplay.length > 0) {
			for (let i = 0; i < textToDisplay.length; i++) {
				let last = i === textToDisplay.length - 1;
				let key = textToDisplay[i];
				searchText += last ? result[key] || "" : (result[key] || "") + (options.delimiter ? options.delimiter : "");
			}
			return searchText;
		} else {
			return result;
		}
	}

	get firstTableColumnLabel(): string {
		return this.tableColumnLabels[0];
	}

	selectItem(obj: any) {
		if (obj.focus) delete obj.focus;
		if (this.searchTextToDisplayOnSelectFn) {
			this.searchText.setValue(this.searchTextToDisplayOnSelectFn(obj));
		} else {
			this.searchText.setValue(this.getPrimaryDisplayField(obj), { emitEvent: false });
		}
		this.autocompleteResults = [];
		this.onItemSelect.emit(obj);
		this.focus.emit();
		this.appService.isFullScreenSearchOpen = false;
		this.appService.hideNavBar = false;
		this.itemSelected = true;
	}

	clearSearch() {
		this.searchText.reset("", { emitEvent: false });
		this.autocompleteResults = [];
		this.resultsReturned.emit(this.autocompleteResults);
		this.searchCleared.emit();
		this.filterBtnSelected = false;
		this.appService.isFullScreenSearchOpen = false;
		this.appService.hideNavBar = false;
		this.itemSelected = false;
	}

	goToPreviousIndex(currentIndex: number) {
		if (currentIndex - 1 > 0) {
			this.autocompleteResults[currentIndex - 1].focus.emit();
		} else {
			this.autocompleteResults[0].focus.emit();
		}
	}

	goToNextIndex(currentIndex: number) {
		if (this.autocompleteResults.length) {
			if (currentIndex + 1 < this.autocompleteResults.length) {
				this.autocompleteResults[currentIndex + 1].focus.emit();
			} else {
				this.autocompleteResults[this.autocompleteResults.length - 1].focus.emit();
			}
		}
	}

	onEnterPressed(searchText: string) {
		if (this.selectOnEnter) {
			this.autocompleteResults = [];
			this.onItemSelect.emit(searchText);
			this.focus.emit();
			this.appService.isFullScreenSearchOpen = false;
			this.appService.hideNavBar = false;
		} else {
			this.searchService.search(this.getSearchParameters(searchText)).subscribe((results: any[]) => {
				this.showingAll = false;
				this.enterPressed = true;
				this.handleSearchResults(results);
			});
		}
	}

	setFocusOn() {
		this.inputFocused = true;
	}

	setFocusOff() {
		this.inputFocused = false;
	}

	setFullScreenOnMobile() {
		this.appService.isFullScreenSearchOpen = true;
		this.appService.hideNavBar = true;
		this.onInputClick.emit();
	}

	private getSearchParameters(text: string): string[] {
		return text.split(",").map(t => t.trim());
	}

	constructor(private searchService: ISearchService, public appService: ApplicationService) {}
}
