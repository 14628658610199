import { takeUntil } from "rxjs/operators";
import { Component, ViewEncapsulation, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./shared/services/auth.service";
import { UserService } from "./shared/services/user.service";
import { PermissionsService } from "./shared/services/permisisons.service";
import { ApplicationService } from "./shared/services/application.service";
import { CustomerProfileModel } from "./shared/models/customer-profile.model";
import { CustomerService } from "./shared/services/customer.service";
import { SessionLoggedOutDialogComponent } from "./shared/components/session-logged-out-dialog/session-logged-out-dialog.component";
import { Subject } from "rxjs";
import { FcmService } from "./shared/services/fcm.service";

@Component({
	selector: "ocp",
	providers: [],
	encapsulation: ViewEncapsulation.None,
	styles: [
		`
   			.menu-container ul li.active {
   				color: #ffffff;
   				background: #5f6162;
   			}
   
   			.menu-container ul li.active a:after {
   				display: none !important;
   			}
   
   			@media screen and (min-width: 768px) {
   				.menu-container ul li.active {
   					color: #555759;
   					background: transparent;
   				}
   				.menu-container ul li.active a {
   					background: #ba0c2f;
   				}
   				.menu-container ul.primary-menu li.active {
   					color: #ffffff !important;
   				}
   			}
   		`
	],
	templateUrl: "./ocp.template.html"
})
export class OcpComponent implements OnInit, OnDestroy {
	mobileNavIsOpen: boolean = false;
	disablePage: boolean = false;
	destroyed: Subject<void> = new Subject<void>();

	@ViewChild("loggedOutModal") loggedOutModal: SessionLoggedOutDialogComponent;

	constructor(
		private _router: Router,
		public _authService: AuthService,
		private _userService: UserService,
		private _customerService: CustomerService,
		private _permissionsService: PermissionsService,
		private _appService: ApplicationService,
		private _fcm: FcmService
	) {}

	ngOnInit() {
		this._authService.onSessionInvalid.pipe(takeUntil(this.destroyed)).subscribe((reason: string) => {
			this.loggedOutModal.showDialog(reason);
		});

		this._appService.disablePage.pipe(takeUntil(this.destroyed)).subscribe((disablePage: boolean) => {
			this.disablePage = disablePage;
		});

		this._router.events.pipe(takeUntil(this.destroyed)).subscribe(evt => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});

		let canUseApp: boolean = false;

		if (this._permissionsService.canNavigateToSection("patient-care")) {
			canUseApp = true;
		} else if (this._permissionsService.canNavigateToSection("reporting")) {
			canUseApp = true;
		} else if (this._permissionsService.canNavigateToSection("clinical-resources")) {
			canUseApp = true;
		} else if (this._permissionsService.canNavigateToSection("partner-profile")) {
			canUseApp = true;
		} else if (this._permissionsService.canNavigateToSection("administration")) {
			canUseApp = true;
		}

		if (!canUseApp && this._authService.isAuthenticated()) {
			this._authService.doLogout("");
			return;
		}

		if (this._authService.isAuthenticated()) {
			this._customerService.getCustomerProfile(null).subscribe((customer: CustomerProfileModel) => {
				this._userService.selectedCustomer = customer;
			});
			this._authService.startSessionCheckInterval();

			this._fcm.receiveMessages();
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	toggleMobileNav() {
		var appContainer = document.getElementById("app-body");

		if (this.mobileNavIsOpen == false) {
			this.mobileNavIsOpen = true;
			appContainer.classList.add("mobile-nav-on");
		} else {
			this.mobileNavIsOpen = false;
			appContainer.classList.remove("mobile-nav-on");
		}
	}

	closeMobileNav() {
		var appContainer = document.getElementById("app-body");

		if (this.mobileNavIsOpen == true) {
			this.mobileNavIsOpen = false;
			appContainer.classList.remove("mobile-nav-on");
		}
	}
}
