// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
   			.menu-container ul li.active {
   				color: #ffffff;
   				background: #5f6162;
   			}
   
   			.menu-container ul li.active a:after {
   				display: none !important;
   			}
   
   			@media screen and (min-width: 768px) {
   				.menu-container ul li.active {
   					color: #555759;
   					background: transparent;
   				}
   				.menu-container ul li.active a {
   					background: #ba0c2f;
   				}
   				.menu-container ul.primary-menu li.active {
   					color: #ffffff !important;
   				}
   			}
   		`, "",{"version":3,"sources":["webpack://./src/app/ocp/ocp.component.ts"],"names":[],"mappings":";MACM;OACC,cAAc;OACd,mBAAmB;MACpB;;MAEA;OACC,wBAAwB;MACzB;;MAEA;OACC;QACC,cAAc;QACd,uBAAuB;OACxB;OACA;QACC,mBAAmB;OACpB;OACA;QACC,yBAAyB;OAC1B;MACD","sourcesContent":["\n   \t\t\t.menu-container ul li.active {\n   \t\t\t\tcolor: #ffffff;\n   \t\t\t\tbackground: #5f6162;\n   \t\t\t}\n   \n   \t\t\t.menu-container ul li.active a:after {\n   \t\t\t\tdisplay: none !important;\n   \t\t\t}\n   \n   \t\t\t@media screen and (min-width: 768px) {\n   \t\t\t\t.menu-container ul li.active {\n   \t\t\t\t\tcolor: #555759;\n   \t\t\t\t\tbackground: transparent;\n   \t\t\t\t}\n   \t\t\t\t.menu-container ul li.active a {\n   \t\t\t\t\tbackground: #ba0c2f;\n   \t\t\t\t}\n   \t\t\t\t.menu-container ul.primary-menu li.active {\n   \t\t\t\t\tcolor: #ffffff !important;\n   \t\t\t\t}\n   \t\t\t}\n   \t\t"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
