import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReportSubscriptionStore } from "../../../services/report-subscription.store";
import { SubscribableReport } from "../../../models/report-subscription/subscribable-report.model";
import { FadeInAnimation } from "src/app/ocp/animations";
import { RoutingRedirectService } from "../../../services/routing-redirect.service";
import * as _ from "lodash";
import { StatusMessageService } from "../../status-message/status-message.service";
import { ModalComponent } from "../../modal/modal.component";
import { OrganizationUsersStore } from "../../../store/organization-users.store";

@Component({
	selector: "create-edit-subscription",
	templateUrl: "./create-edit-subscription.component.html",
	animations: [FadeInAnimation]
})
export class CreateEditSubscriptionComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	saving: boolean;
	organizationId: number;
	mode: string;
	step: number;
	reports: SubscribableReport[] = [];

	@ViewChild("confirmCancelModal") confirmCancelModal: ModalComponent;

	private _destroyed: Subject<void> = new Subject<void>();

	get saveButtonText(): string {
		if (this.mode === "new") {
			return "Save Subscription";
		} else {
			return "Save Changes";
		}
	}

	get isEditing(): boolean {
		return this.mode === "edit";
	}

	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
		public store: ReportSubscriptionStore,
		public userStore: OrganizationUsersStore,
		private _redirectService: RoutingRedirectService,
		private _statusMessageService: StatusMessageService
	) {
		// If no 'step' is set, redirect back to Report Subscriptions listing
		if (!this.store.step.get()) {
			this.backToSubscriptionsList(true);
		}
	}

	ngOnInit(): void {
		this.loading = true;

		this._route.params.pipe(takeUntil(this._destroyed)).subscribe((p: Params) => {
			this.organizationId = +p["id"];
		});

		this.store.createMode.observable.pipe(takeUntil(this._destroyed)).subscribe((createMode: string) => {
			this.mode = createMode;
		});

		this.store.step.observable.pipe(takeUntil(this._destroyed)).subscribe((step: number) => {
			this.step = step;
		});

		if (this.step == 1) {
			this.loadReports();
		}

		if (this.mode === "edit" && (this.step == 2 || this.step == 3)) {
			setTimeout(() => {
				this.loading = false;
			}, 600);
		}
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	reportChanged(selected: SubscribableReport) {
		this.store.selectedReport.set(selected);
	}

	// For step 1
	loadReports() {
		this.store.reports.observable.pipe(takeUntil(this._destroyed)).subscribe((reports: SubscribableReport[]) => {
			this.reports = reports;

			setTimeout(() => {
				this.loading = false;
			}, 600);
		});
	}

	goBack() {
		let newStep = this.step - 1;
		this.store.step.set(newStep);
	}

	cancelSub() {
		this.confirmCancelModal.open("xs");
	}

	confirmCancelSub() {
		this.confirmCancelModal.dismiss();
		this.backToSubscriptionsList(true);
	}

	goToStepTwo() {
		this.loading = true;
		let report = this.store.selectedReport.get();
		this.store.newSubscription(report.ReportId, report.ReportName);
		this.store.step.set(2);

		setTimeout(() => {
			this.loading = false;
		}, 600);
	}

	goToStepThree() {
		this.loading = true;
		this.userStore.clearFilters();
		this.store.step.set(3);

		setTimeout(() => {
			this.loading = false;
		}, 600);
	}

	save() {
		this.saving = true;

		this.store.save(this.isEditing).then(() => {
			setTimeout(() => {
				this.backToSubscriptionsList(false);
				if (!this.isEditing) {
					this._statusMessageService.changeStatusMessage("success", "A new subscription “" + this.store.subscription.get().SubscriptionName + "” was successfully created.");
				}
			}, 600);
		});
	}

	backToSubscriptionsList(clearStoredData: boolean) {
		this._router.navigate(["../"], { relativeTo: this._route });

		if (clearStoredData) {
			this.store.cancel();
		}

		setTimeout(() => {
			this._redirectService.loadSubscriptionsTab.emit();
		}, 300);
	}
}
