import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Component, ViewChild, OnInit } from "@angular/core";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { CustomerService } from "../../shared/services/customer.service";
import { UserProfile } from "../../shared/models/user-profile.model";
import { Permissions } from "../../shared/enums/permissions";
import { StatusMessageService } from "../../shared/components/status-message/status-message.service";
import { UserListItemModel } from "../../shared/models/user-list-item.model";
import { CustomerProfileModel } from "../../shared/models/customer-profile.model";
import { UntypedFormControl } from "@angular/forms";
import { BulkUserUploadModalComponent } from "../../shared/components/bulk-user-upload/bulk-user-upload-modal.component";
import { UserService } from "../../shared/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "users-tab",
	templateUrl: "./users.template.html",
	styleUrls: ["./partner-profile-tabs.scss"]
})
export class UsersTab implements OnInit {
	enableDoseSpot: boolean = false;
	usersFilter: string[] = ["active"];
	modalTitle: string;
	subHeaderText: string;
	users: UserListItemModel[] = [];
	filteredUsers: UserListItemModel[] = [];
	userToEdit: UserProfile = null;
	editMode: string;
	customerName: string;
	userRolesEdit: UserListItemModel = null;
	permissions: any = Permissions;
	customerId: number;
	userSearchText: UntypedFormControl = new UntypedFormControl();
	isUserTeamEnabled: boolean;

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	@ViewChild("bulkUserUploadModal") bulkUserUploadModal: BulkUserUploadModalComponent;

	constructor(private _userService: UserService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.userSearchText.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged()
			)
			.subscribe((term: string) => {
				this.filterUserList();
			});

		this.refreshUsers();
	}

	refreshUsers() {
		this._userService.getInternalUsers().subscribe((users: UserListItemModel[]) => {
			this.users = users;
			this.filterUserList();
		});
	}

	filterUserList() {
		let self = this;
		let filteredUsers: UserListItemModel[] = this.users.filter((user: UserListItemModel) => {
			let nameMatches: boolean = false;
			let searchText: string = self.userSearchText.value;

			if (!searchText) {
				nameMatches = true;
			} else {
				searchText = searchText
					.trim()
					.replace(/\s\s+/g, " ")
					.replace(",", "")
					.toLowerCase();
				let searchNameParts: string[] = searchText.split(" ");
				let matchPartsCount: number = 0;
				searchNameParts.forEach((part: string) => {
					if (user.FirstName.toLowerCase().indexOf(part) > -1 || user.LastName.toLowerCase().indexOf(part) > -1 || user.LoginId.toLowerCase().indexOf(part) > -1) {
						matchPartsCount++;
					}
				});

				if (matchPartsCount === searchNameParts.length) {
					nameMatches = true;
				}
			}

			if (nameMatches) {
				if (self.usersFilter.indexOf("active") !== -1 && !user.IsLocked) {
					return true;
				}
				if (self.usersFilter.indexOf("locked") !== -1 && user.IsLocked) {
					return true;
				}
			}

			return false;
		});

		filteredUsers.sort((a, b) => {
			if (a.LastName < b.LastName) return -1;

			if (a.LastName > b.LastName) return 1;

			return 0;
		});

		this.filteredUsers = filteredUsers;
	}

	updateFilter(checked: boolean, value: string) {
		if (!checked) {
			this.usersFilter.splice(this.usersFilter.indexOf(value), 1);
		} else if (this.usersFilter.indexOf(value) === -1) {
			this.usersFilter.push(value);
		}

		this.filterUserList();
	}

	clearSearch() {
		this.userSearchText.setValue("");
	}

	addUser() {
		this.userToEdit = new UserProfile();
		this.createModal.open("md");
		this.modalTitle = "New User";
		this.subHeaderText = "";
	}

	bulkUpload() {
		this.bulkUserUploadModal.showDialog();
	}

	onEditUserClose() {
		this.userToEdit = null;
	}

	userSaveSuccess() {
		this.refreshUsers();
	}

	addUserSuccess() {
		this._statusMessageService.changeStatusMessage("success", this.userToEdit.LastName + ", " + this.userToEdit.FirstName + " successfully added.");
		this.createModal.dismiss();
		this.userSaveSuccess();
	}

	updateSubHeaderText(event) {
		this.subHeaderText = event;
	}

	createModalDismissed(refreshUsers: boolean){
		if (refreshUsers) this.refreshUsers();
		this.createModal.dismiss();
	}

}
