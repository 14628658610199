import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {CustomerTeamModel} from "../../models/customer-team.model";
import {CustomerService} from "../../services/customer.service";
import {ApiResult} from "../../models/api-result.model";
import {StatusMessageService} from "../status-message/status-message.service";
import {UserService} from "../../services/user.service";
import {CustomerProfileModel} from "../../models/customer-profile.model";
import {ModalComponent} from "../modal/modal.component";

@Component({
  selector: 'form-team-details',
  templateUrl: './form-team-details.template.html',
  styleUrls: ['./form-team-details.scss']
})

export class FormTeamDetails {
  currentDefaultTeamName: string;
  private currentDefaultId: number;
  hasTeams: boolean = false;
  saving: boolean = false;
  confirmDefault: boolean = false;
  statusList = [{text: 'Active', value: true}, {text: "Inactive", value: false}];
  team: CustomerTeamModel;

  @Input() mode: string;
  @Input() customerId: number;

  @Input() set currentTeams(teams: CustomerTeamModel[]) {
    let defaultTeam: CustomerTeamModel = teams.filter((team: CustomerTeamModel) => {
      return team && team.IsDefault;
    })[0];

    if (defaultTeam) {
      this.currentDefaultTeamName = defaultTeam.TeamName;
      this.currentDefaultId = defaultTeam.CustomerTeamId;
    } else {
      this.currentDefaultTeamName = "";
      this.currentDefaultId = 0;
    }
  }

  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('confirmChangeDefault') confirmChangeDefault: ModalComponent;
  @ViewChild('teamEditModal') teamEditModal: ModalComponent;

  constructor(private _userService: UserService,
              private _customerService: CustomerService,
              private _statusMessageService: StatusMessageService) {
  }

  showDialog(team: CustomerTeamModel) {
    if (this.mode === 'New') {
      this.team = new CustomerTeamModel();
    } else {
      if(!team) {
        // don't bother if there is no team passed in...
        return;
      }
      this.team = team;
    }

    if(this.customerId){
      this._customerService.getCustomerProfile(this.customerId).subscribe((customer: CustomerProfileModel) => {
        this.hasTeams = customer.HasTeams;
        this.confirmDefault = !customer.HasTeams && !this.team.IsDefault;
        this.teamEditModal.open('md');
      });
    } else {
      this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
        if(!this.team){
          // team is null if the dialog closes and the customer changes before navigating away.
          return;
        }
        this.hasTeams = customer.HasTeams;
        this.confirmDefault = !customer.HasTeams && !this.team.IsDefault;
        this.teamEditModal.open('md');
      });
    }
  }

  save() {
    if (this.confirmDefault && this.team.IsDefault && this.currentDefaultId
        && this.team.CustomerTeamId !== this.currentDefaultId) {
      this.confirmChangeDefault.open();
    } else {
      this.doSave();
    }
  }

  doSave() {
    this.saving = true;
    this.confirmChangeDefault.dismiss();
    this._customerService.setCustomerTeam(this.team, this.customerId).subscribe((results: ApiResult) => {
      this.saving = false;
      if (results.Success) {
        if (this.mode === 'New') {
          this.saveSuccess.emit('"' + this.team.TeamName + '" has been successfully added.');
        } else {
          this.saveSuccess.emit('"' + this.team.TeamName + '" has been successfully updated.');
        }
        this.teamEditModal.dismiss();
      } else {
        this._statusMessageService.changeStatusMessage('error', results.PublicMessage)
      }
    });
  }
}
