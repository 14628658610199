import { Component, Input, forwardRef, EventEmitter, OnInit, ElementRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MobileService } from "../../services/mobile.service";

@Component({
	selector: "input-time",
	templateUrl: "./input-time.template.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputTimeComponent),
			multi: true
		}
	]
})
export class InputTimeComponent implements ControlValueAccessor, OnInit {
	public elementRef;
	value: string;
	timeRegex: string = "((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))";
	isMobile: boolean = false;

	@Input() label: string;
	@Input() isRequired: boolean = false;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() overrideValidation: boolean = false;
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() isInvalid: boolean;
	@Input() removeMargin: boolean = false;

	constructor(myElement: ElementRef, private _mobile: MobileService) {
		this.elementRef = myElement;
	}

	ngOnInit() {
		if (this._mobile.isMobile()) {
			this.isMobile = true;
		}
	}

	propagateChange = (_: any) => {};

	inputChanged(value: string) {
		this.value = value;
		if (!this.overrideValidation) {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}

		this.propagateChange(this.value);
	}

	writeValue(obj: string) {
		if (obj !== undefined) {
			this.value = obj;
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
