import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToggleSwitchComponent } from 'src/app/ocp/shared/components/toggle-switch/toggle-switch.component';
import { PharmacyStore } from 'src/app/ocp/shared/services/pharmacy.store';
import { Pharmacy } from "../../../shared/models/pharmacy.model";

@Component({
  selector: 'ct-form-pharmacy-details',
  templateUrl: './form-pharmacy-details.component.html',
  styleUrls: ['./form-pharmacy-details.component.scss']
})
export class FormPharmacyDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("enabled")
  enabledToggle: ToggleSwitchComponent

  private _destroyed: Subject<void> = new Subject<void>();

  get percentThresholdInvalid(): boolean {
    let p = this.store.selected.get();
    let val = p.refillThreshold;

    return (
      !Number.isInteger(+val) ||
      !/^-?[\d.]+(?:e-?\d+)?$/.test(""+val) ||
      val < 0 || val > 100 
    );
  }

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(public store: PharmacyStore) {}

  ngOnInit() {
    this.store.selected.observable.pipe(takeUntil(this._destroyed)).subscribe(p => {
      if (this.enabledToggle){
        this.enabledToggle.set((p || {isEnabled: false}).isEnabled)
      }
    });
  }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.unsubscribe();
  }

  save() {
    this.closeModal.emit();
    this.store.update().then(() => {
      this.store.selected.set(new Pharmacy());
    });
  }
}
