import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { StatusMessageService } from "../../status-message/status-message.service";
import { Pharmacy } from "../../../models/pharmacy.model";
import { FadeInQuickAnimation } from "src/app/ocp/animations";
import { DataTableColumnModel } from "../../../components/data-table/columns.model";
import { DataTableModel } from "../../../components/data-table/data-table.model";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PharmacyStore } from "../../../services/pharmacy.store";
import { MatTabGroup } from "@angular/material/tabs";
import { UntypedFormControl } from "@angular/forms";
import { PatientCareStore } from "src/app/ocp/patient-care/services/patient-care.store";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { PharmacySearchResult } from "../../../models/pharmacy-search-result.model";
import { PharmacySearchFiltersModel } from "../../../models/pharmacy-search-filters.model";

@Component({
	selector: "ocp-form-change-pharmacy",
	templateUrl: "./form-change-pharmacy.component.html",
	styleUrls: ["./form-change-pharmacy.component.scss"],
	animations: [FadeInQuickAnimation]
})
export class FormChangePharmacyComponent implements OnInit {
	saving: boolean = false;
	savedPharmacies: DataTableModel = new DataTableModel([], []);
	states: string[] = [
		"",
		"AL",
		"AK",
		"AS",
		"AZ",
		"AR",
		"CA",
		"CO",
		"CT",
		"DE",
		"DC",
		"FM",
		"FL",
		"GA",
		"GU",
		"HI",
		"ID",
		"IL",
		"IN",
		"IA",
		"KS",
		"KY",
		"LA",
		"ME",
		"MH",
		"MD",
		"MA",
		"MI",
		"MN",
		"MS",
		"MO",
		"MT",
		"NE",
		"NV",
		"NH",
		"NJ",
		"NM",
		"NY",
		"NC",
		"ND",
		"MP",
		"OH",
		"OK",
		"OR",
		"PW",
		"PA",
		"PR",
		"RI",
		"SC",
		"SD",
		"TN",
		"TX",
		"UT",
		"VT",
		"VI",
		"VA",
		"WA",
		"WV",
		"WI",
		"WY"
	];
	selected = new UntypedFormControl(0);
	searchPerformed: boolean = false;
	setAsDefault: boolean = false;

	@Input() selectedPharmacy: Pharmacy;
	@Input() patientId: number;
	@Input() dosespotPatientId?: number;
	@Input() patientZip: string;
	@Input() defaultOPPC: boolean;

	@Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onPharmacyChange: EventEmitter<Pharmacy> = new EventEmitter<Pharmacy>();

	@ViewChild("tabGroup") private _tabGroup: MatTabGroup;

	private _destroyed: Subject<void> = new Subject<void>();

	constructor(public store: ErxOrderStore, private _statusMessageService: StatusMessageService, private _patientStore: PatientCareStore) {
		this._patientStore.realignTabInkBar.pipe(takeUntil(this._destroyed)).subscribe(() => {
			this._tabGroup.realignInkBar();
		});
	}

	ngOnInit(): void {
		if (this.patientId) {
			this.store.getPatientPharmacies(this.patientId, this.defaultOPPC);
			this.initTable();
		}
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	preferred: PharmacySearchResult[] = [];
	initTable() {
		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("select", "", "", false));
		columns.push(new DataTableColumnModel("pharmacyName", "Pharmacy Name", "", false));
		columns.push(new DataTableColumnModel("streetAddress", "Street Address", "", false));
		columns.push(new DataTableColumnModel("cityState", "City/State", "", false));
		columns.push(new DataTableColumnModel("actions", "", "col-edit-button", false));

		this.savedPharmacies = new DataTableModel([], columns);
		this.savedPharmacies.pageLength = 9999;

		this.store.preferredPharmacies.observable.pipe(takeUntil(this._destroyed)).subscribe((pharms: PharmacySearchResult[]) => {
			this.preferred = pharms;
			this.savedPharmacies.populate(pharms.slice(0, 10));
		});
	}

	showSearchForm() {
		this.searchPerformed = false;
	}

	searchNearbyPharmacies() {
		var filter = new PharmacySearchFiltersModel();
		filter.zipCode = this.patientZip;
		this.store.pharmacySearchFilter.set(filter);

		this.searchPharmacies();
	}

	searchPharmacies() {
		this.store.applySearchFilter();
		this.searchPerformed = true;
	}

	pharmacyChanged(selected: Pharmacy) {
		this.selectedPharmacy = selected;
	}

	remove(pharmacy: any, idx: number) {
		if (this.dosespotPatientId > 0) {
			this.store.removePharmacy(this.dosespotPatientId, pharmacy.id);
		}

		this.savedPharmacies.data.splice(idx, 1);
	}

	dismiss() {
		this.onClose.emit();
		this.store.clearSearchFilter();
		this.tabChange(0);
	}

	save() {
		this.saving = true;

		if (this.dosespotPatientId > 0) {
			this.store.addPharmacy(this.dosespotPatientId, this.selectedPharmacy.id, this.setAsDefault).then(() => {
				this.finish();
			});
		} else {
			this.finish();
		}
	}

	private finish() {
		this.onPharmacyChange.emit(this.selectedPharmacy);
		this.saving = false;
		this.dismiss();
		this._statusMessageService.changeStatusMessage("success", '"' + this.selectedPharmacy.pharmacyName + '" has been assigned as the pharmacy for this order.');
	}

	tabSelected(tabIndex: number) {
		return this.selected.value === tabIndex;
	}

	tabChange(e: any) {
		this.selected.setValue(e.index);
	}
}
