import { Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef } from "@angular/core";
import { ReportSubscriptionStore } from "../../services/report-subscription.store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataTableModel } from "../data-table/data-table.model";
import { DataTableColumnModel } from "../data-table/columns.model";
import { SubscriptionSummary } from "../../models/report-subscription/subscription-summary.model";
import { ModalComponent } from "../modal/modal.component";
import { ContextualPopupService } from "../../services/contextual-popup.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "report-subscriptions",
	templateUrl: "report-subscriptions.template.html",
	styleUrls: ["report-subscriptions.scss"]
})
export class ReportSubscriptionsComponent implements OnInit, OnDestroy {
	subscriptions: DataTableModel = new DataTableModel(
		[
			{
				column: "SubscriptionName",
				direction: "asc"
			}
		],
		[
			new DataTableColumnModel("SubscriptionName", "Name", "pl-10px", true),
			new DataTableColumnModel("IsActive", "Enabled", "centered", false),
			new DataTableColumnModel("Report.ReportName", "Report", "", false),
			new DataTableColumnModel("FrequencyType", "Frequency", "", false),
			new DataTableColumnModel("actions", "", "", false)
		]
	);
	filterReportOptions: string[] = [];

	@Input() organizationId: number;

	@ViewChild("confirmDisableModal") confirmDisableModal: ModalComponent;

	get filtering(): boolean {
		return this.store.filtersApplied;
	}

	get filterBtnText(): string {
		if (this.filtering) {
			return "Filter Applied";
		}
		return "Add Filter";
	}

	constructor(public store: ReportSubscriptionStore, private _popupService: ContextualPopupService, private _router: Router, private _activeRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.store.filteredSubscriptions.observable.pipe(takeUntil(this.$destroyed)).subscribe((subscriptions: SubscriptionSummary[]) => {
			this.subscriptions.populate(subscriptions);
		});

		this.store.load(this.organizationId);

		this.store.reports.observable.pipe(takeUntil(this.$destroyed)).subscribe(reports => {
			this.filterReportOptions = reports.map(r => r.ReportName);
		});
	}

	private $destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy(): void {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
	}

	newSubscription() {
		this.store.createMode.set("new");
		this.store.step.set(1);
		this._router.navigate(["create-subscription"], { relativeTo: this._activeRoute });
	}

	editSettings(subscription: SubscriptionSummary) {
		this.store.createMode.set("edit");
		this.store.editSubscription(subscription);
		this.store.step.set(2);
		this._router.navigate(["edit-subscription"], { relativeTo: this._activeRoute });
	}

	editSubscribers(subscription: SubscriptionSummary) {
		this.store.createMode.set("edit");
		this.store.editSubscribers(subscription);
		this.store.step.set(3);
		this._router.navigate(["edit-subscription"], { relativeTo: this._activeRoute });
	}

	private _subId: number;
	disable(subscription: SubscriptionSummary) {
		this._subId = subscription.ReportSubscriptionId;
		this.confirmDisableModal.open("xs");
	}

	enable(subscription: SubscriptionSummary) {
		this._subId = subscription.ReportSubscriptionId;
		this.store.enable(this._subId);
	}

	closeDisable() {
		this.confirmDisableModal.close();
		this._subId = 0;
	}

	doDisable() {
		this.store.disable(this._subId).then(() => {
			this.closeDisable();
		});
	}

	private _filterRef: any;
	openFilters(content: TemplateRef<any>, origin: any) {
		this._filterRef = this._popupService.open<{}>({
			content,
			origin,
			width: "320px",
			data: {},
			positionBefore: true
		});
	}

	reportFilterChanged(e: string) {
		this.store.setReportFilter(e);
	}

	statusFilterChanged(e: boolean) {
		this.store.setStatusFilter(e);
	}

	resetFilters() {
		this.store.clearFilter();
		if (this._filterRef) {
			this._filterRef.close();
		}
	}
}
