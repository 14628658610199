import { Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { UserService } from "../../services/user.service";
import { Permissions } from "../../enums/permissions";
import { RxOrderModel } from "../../models/rx-order.model";
import { ModalComponent } from "../modal/modal.component";
import { StatusMessageService } from "../status-message/status-message.service";
import { FadeInAnimation } from "../../../animations";
import { AlertCounts } from "../../models/alert-counts.model";

@Component({
	selector: "alerts-listing",
	templateUrl: "./alerts-listing.template.html",
	styleUrls: ["./alerts-messages.scss", "./alert.scss"],
	animations: [FadeInAnimation]
})
export class AlertsListingComponent implements OnInit, OnDestroy {
	subHeaderText: string;
	alertsShown: string = "system";
	systemAlerts: any[] = [];
	priorAuthAlerts: any[] = [];
	permissions: any = Permissions;
	rxOrder: RxOrderModel;
	numberOfPAAlerts: number = 0;
	numberOfSysAlerts: number = 0;
	isLoading: boolean = true;
	isMobile: boolean = false; // not set
	designatedTeams: string[] = ["Green Team", "Red Team", "Blue Team", "Orange Team"];

	private destroyed: Subject<void> = new Subject<void>();

	@ViewChild("priorAuthConfirmModal") priorAuthConfirmModal: ModalComponent;

	@Output() closePanel: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _userService: UserService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this._userService.userAlerts.observable.pipe(takeUntil(this.destroyed)).subscribe((result: AlertCounts) => {
			if (result) {
				this.numberOfPAAlerts = result.paAlertCount;
				this.numberOfSysAlerts = result.sysAlertCount;
			} else {
				[this.numberOfSysAlerts, this.numberOfPAAlerts] = [0, 0];
			}
		});

		this._userService.customerChanged.pipe(takeUntil(this.destroyed)).subscribe(() => {
			this.load();
		});

		this.load();
	}

	load() {
		this.isLoading = true;
		Promise.all([this.loadSystem(), this.loadPriorAuth()]).then(() => {
			this.isLoading = false;
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	loadSystem(): Promise<void> {
		return new Promise<void>(resolve => {
			this._userService.getSystemAlertsForUser().subscribe((results: any[]) => {
				if (results) {
					results.forEach(r => {
						r.NumPharmacies = 0;
						if (r.PharmacyList) {
							r.NumPharmacies = r.PharmacyList.split(", ").length;
						}
					});
				}

				this.systemAlerts = results;
				this._userService.refreshUserAlertCount();
				resolve();
			});
		});
	}

	loadPriorAuth(): Promise<void> {
		return new Promise<void>(resolve => {
			this._userService.getPendingAuthorizationsForUser().subscribe((results: any[]) => {
				if (!results) {
					this.priorAuthAlerts = [];
				} else {
					this.priorAuthAlerts = results.map(a => Object.assign(new RxOrderModel(), a));
				}
				resolve();
			});
		});
	}

	refreshUserAlertCount() {
		this._userService.refreshUserAlertCount();
	}

	priorAuthClose() {
		this.rxOrder = null;
	}

	showConfirmPriorAuth(rxOrder: RxOrderModel) {
		this.rxOrder = rxOrder;
		this.subHeaderText = rxOrder.PatientName;
		this.priorAuthConfirmModal.open("lg");
	}

	authSuccess(message: string) {
		this.loadPriorAuth();
		this._userService.refreshUserAlertCount();
		this.priorAuthConfirmModal.dismiss();
		this._statusMessageService.changeStatusMessage("success", message);
	}
}
