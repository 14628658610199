import {Component, ViewChild} from "@angular/core";
import {ModalComponent} from "../../../../shared/components/modal/modal.component";
import {VimeoService} from "../../../../shared/services/vimeo.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: "video-modal",
  templateUrl: "./video-modal.template.html",
  styleUrls: ["./video-modal.scss"]
})

export class VideoModal {
  @ViewChild("videoModal")
  videoModal:ModalComponent;

  videoContent:any;
  videoTitle:string;

  constructor(private _domSanitizer: DomSanitizer,
              private _vimeoService: VimeoService){
  }

  openVideo(videoId:string, videoTitle:string){
    this.videoTitle = videoTitle;
    this._vimeoService.getEmbeddedHtml(videoId).subscribe((results: string)=>{
      this.videoContent = this._domSanitizer.bypassSecurityTrustHtml(results);
      this.videoModal.open("md");
    });
  }

  videoClosed() {
    this.videoContent = null;

  }

  closeVideo() {
    this.videoModal.close();
  }
}
