// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row.full-width-grid {
  margin-right: -8px;
  margin-left: -8px;
}
@media screen and (min-width: 768px) {
  .row.full-width-grid {
    margin-bottom: 150px;
  }
}

user-summary {
  padding-right: 8px;
  padding-left: 8px;
}

:host-context(.customer-profile-page) .full-width-grid .col-xs-12 {
  padding-right: 0;
  padding-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/customer-users/customer-users.scss","webpack://./src/assets/styles/imports/_breakpoints.scss"],"names":[],"mappings":"AAKA;EACE,kBAAA;EACA,iBAAA;AAJF;AC8BE;ED5BF;IAKI,oBAAA;EAHF;AACF;;AAMA;EACE,kBAAA;EACA,iBAAA;AAHF;;AAOE;EACE,gBAAA;EACA,eAAA;AAJJ","sourcesContent":["@import '../../../../../assets/styles/imports/breakpoints';\r\n@import '../../../../../assets/styles/imports/settings';\r\n@import '../../../../../assets/styles/imports/mixins';\r\n@import '../../../../../assets/styles/imports/functions';\r\n\r\n.row.full-width-grid {\r\n  margin-right: -8px;\r\n  margin-left: -8px;\r\n\r\n  @include min-screen(768px) {\r\n    margin-bottom: 150px;\r\n  }\r\n}\r\n\r\nuser-summary {\r\n  padding-right: 8px;\r\n  padding-left: 8px;\r\n}\r\n\r\n:host-context(.customer-profile-page) {\r\n  .full-width-grid .col-xs-12 {\r\n    padding-right: 0;\r\n    padding-left: 0;\r\n  }\r\n}\r\n","//***********************************************\r\n// ~* BREAKPOINTS\r\n//***********************************************\r\n$global-width: 1060px;\r\n\r\n$xsmall: 0px;\r\n$small: 412px;\r\n$smallMax: 576px;\r\n$medium: 768px;\r\n$large: 960px;\r\n$xlarge: 1060px;\r\n\r\n@mixin screen($res-min, $res-max, $orientation: false) {\r\n  @if $orientation {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max)\r\n    and (orientation:#{$orientation}) {\r\n      @content;\r\n    }\r\n  }\r\n  @else {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max) {\r\n      @content;\r\n    }\r\n  }\r\n}\r\n\r\n@mixin max-screen($res) {\r\n  @media screen and (max-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin min-screen($res) {\r\n  @media screen and (min-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin print() {\r\n  @media print {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
