// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.override-alert {
  padding-top: 7px;
}

:host modal-body .modal-body {
  padding: 1rem 0.3125rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/confirm-medication/confirm-medication.scss"],"names":[],"mappings":"AAKA;EACE,gBAAA;AAJF;;AASI;EACE,kCAAA;AANN","sourcesContent":["@import '../../../../../assets/styles/imports/_breakpoints';\r\n@import '../../../../../assets/styles/imports/_settings';\r\n@import '../../../../../assets/styles/imports/_mixins';\r\n@import '../../../../../assets/styles/imports/_functions';\r\n\r\n.override-alert {\r\n  padding-top: 7px;\r\n}\r\n\r\n:host {\r\n  modal-body {\r\n    .modal-body {\r\n      padding: rem-calc(16px) rem-calc(5px) !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
