import {Component, OnInit, ViewChild} from '@angular/core';
import {WebinarModel} from '../../../shared/models/webinar.model';
import {VideoModal} from "./video-modal/video-modal.component";
import {VimeoService} from "../../../shared/services/vimeo.service";
import {DocumentTypeModel} from "../../../shared/models/document-type.model";
import {ClinicalResourcesService} from "../../../shared/services/clinical-resources.service";

@Component({
  selector: 'webinars-tab',
  templateUrl: './webinars.template.html',
  styleUrls: ['./clinical-resources-tabs.scss']
})

export class WebinarsTab implements OnInit {
  private _documentTypeId: number = 5; // Webinar
  webinar: WebinarModel[] = [];
  webinarsFilter: string[] = ['Recordings'];
  displayWebinars: WebinarModel[] = [];

  videos: any[] = [];
  documents: DocumentTypeModel[] = [];

  @ViewChild("videoModal")
  videoModal: VideoModal;

  constructor(private _vimeoService: VimeoService, private _clinicalResourcesService: ClinicalResourcesService) {

  }

  ngOnInit() {
    this.filterWebinars();

    this._clinicalResourcesService.getDocuments(null, this._documentTypeId).subscribe((documents: DocumentTypeModel[]) => {
      this.documents = documents;
    });
    this._vimeoService.getVideos().subscribe((videos: any[]) => {
      this.videos = videos;
    });
  }

  filterWebinars() {
    if (this.webinarsFilter.indexOf('Recordings') !== -1) {
      return true;
    }

    if (this.webinarsFilter.indexOf('Powerpoints') !== -1) {
      return true;
    }

    return false;
  }

  updateFilter(checked: boolean, value: string) {
    if (!checked) {
      this.webinarsFilter.splice(this.webinarsFilter.indexOf(value), 1);
    } else if (this.webinarsFilter.indexOf(value) === -1) {
      this.webinarsFilter.push(value);
    }

    this.filterWebinars();
  }

  downloadPowerpoint() {

  }
}
