import { StoreObject } from "./ocp/shared/models/store-object.model";
import { Injectable, EventEmitter } from "@angular/core";
import { LocalStorageService } from "./ocp/shared/services/local-storage.service";
import { MobilePushConfig } from "./ocp/shared/models/mobile-push-config.model";

@Injectable({
	providedIn: "root"
})
export class AppStore {
	public IsMobile: StoreObject<boolean> = new StoreObject<boolean>(false);

	public OnTokenReceived: EventEmitter<any> = new EventEmitter<any>();
	public OnTokenEstablished: EventEmitter<void> = new EventEmitter<void>();
	public OnBioAuthError: EventEmitter<string> = new EventEmitter<string>();

	public OnNotificationsChanged: EventEmitter<MobilePushConfig> = new EventEmitter<MobilePushConfig>();
	public OnNotification: EventEmitter<string> = new EventEmitter<string>();

	private wvBridge: any;

	constructor(private _storage: LocalStorageService) {
		// This code is to setup communication with the mobile app wrapper
		// If this is not running in the mobile app, this will not be fired.
		window.addEventListener("ns-bridge-ready", (e: any) => {
			this.wvBridge = e.detail;

			this.IsMobile.set(true);

			this.wvBridge.on("bio-check-result", (data: any) => {
				if (data.success) {
					this._storage.set("deviceId", data.deviceId);
				} else {
					this.IsMobile.set(false);
				}
			});

			this.wvBridge.on("token-established", (data: any) => {
				if (data.success) {
					if (data.encryptedText) {
						this._storage.set("decryptText", data.encryptedText);
						this._storage.set("iv", data.iv);
					}

					this.OnTokenEstablished.next();
				} else {
					this.OnBioAuthError.emit("An error occurred");
				}
			});

			this.wvBridge.on("auth-provided", (data: any) => {
				if (data.success) {
					if (data.token) {
						this.OnTokenReceived.next({ token: data.token, deviceId: data.deviceId });
					}
				} else {
					this.OnBioAuthError.emit("An error occurred");
				}
			});

			this.wvBridge.on("biometric-error", (data: any) => {
				this.OnBioAuthError.emit(data.message);
			});

			this.wvBridge.on("push-notification-changed", (data: MobilePushConfig) => {
				this.OnNotificationsChanged.next(data);
			});

			this.wvBridge.on("push-notification", (data: any) => {
				this.OnNotification.next(data);
			});

			this.bioCheck();
		});
	}

	doAppLogin() {
		if (!this.wvBridge) return; // Not a mobile app

		this.wvBridge.emit("do-login", {
			decryptText: this._storage.get("decryptText"),
			iv: this._storage.get("iv")
		});
	}

	setupLogin(token: string) {
		if (!this.wvBridge) return;

		this.wvBridge.emit("setup-login", {
			token: token
		});
	}

	disableNotifications() {
		if (!this.wvBridge) return;

		this.wvBridge.emit("disable-notifications", {});
	}

	enableNotifications() {
		if (!this.wvBridge) return;

		this.wvBridge.emit("enable-notifications", {});
	}

	bioCheck = () => (this.wvBridge ? this.wvBridge.emit("bio-check", {}) : null);

	notificationCheck = () => (this.wvBridge ? this.wvBridge.emit("notification-check", {}) : null);
}
