import { takeUntil } from "rxjs/operators";
import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { RxService } from "../../../shared/services/rx.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { PatientCareStore } from "../../services/patient-care.store";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { AppConfig } from "src/app/ocp/app.config";
import { CacheService } from "src/app/ocp/shared/services/cache.service";
import { Location } from "@angular/common";
import { AuthService } from "src/app/ocp/shared/services/auth.service";
import { Permissions } from "src/app/ocp/shared/enums/permissions";

@Component({
	selector: "erx-iframe",
	templateUrl: "./erx-iframe.template.html",
	styleUrls: ["./erx-iframe.scss"]
})
export class ErxIframeComponent implements OnInit, OnDestroy {
	@ViewChild("eLink") eLink: ElementRef;

	permissions: any = Permissions;
	cusERx: boolean = false;

	isLoading: boolean = false;
	erxUrl: any;
	sourceUrlSet: boolean = false;
	patient: PatientInfoModel;

	showLogin: boolean = true;

	isIos: boolean;

	private destroyed: Subject<void> = new Subject<void>();

	@Output() public erxClosed: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _rxService: RxService,
		private _domSanitizer: DomSanitizer,
		private _statusMessageService: StatusMessageService,
		private _store: PatientCareStore,
		private _router: Router,
		private _route: ActivatedRoute,
		private _cache: CacheService,
		private _config: AppConfig,
		private _location: Location,
		private _auth: AuthService
	) {}

	ngOnInit() {
		const pref = this._cache.get(this._config.SessionKeys.preferences.showDosespotLogin);

		if (pref && pref.toLowerCase() === "false") {
			this.showLogin = false;
		}

		let url = this._router.url;
		let urlParts = url.split("/");

		if (urlParts && urlParts.length > 0 && urlParts.indexOf("erx-alerts") > 0) {
			if (this._auth.hasPermission(this.permissions.viewPendingERxAlerts)) {
				this.cusERx = true;
				this.erxUrl = this._domSanitizer.bypassSecurityTrustResourceUrl("");
				this.isLoading = true;
				this.getCusERxUrl();
			}
		} else {
			this._store.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient: PatientInfoModel) => {
				this.patient = patient;

				if (this.patient.patientId) {
					this.erxUrl = this._domSanitizer.bypassSecurityTrustResourceUrl("");
					this.isLoading = true;
					this.getUrl();
				}
			});
		}

		this.detectiOS();
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	hideLogin() {
		this.showLogin = false;
		this._cache.set(this._config.SessionKeys.preferences.showDosespotLogin, "false");
	}

	getUrl() {
		this._rxService.getDoseSpotURLForNewERX(this.patient.patientId).then(
			(url: any) => {
				this.erxUrl = url;
				this.sourceUrlSet = true;
			},
			(error: any) => {
				this.isLoading = false;
				let msg: string = error;

				// OPPC DoseSpot API returns a verbose & amiguous error message in this case
				// instead of re-writing the error parsing logic on the OPPC DS API,
				// we can just handle this specific error case
				if ((msg || "").toLowerCase().includes("unknown user")){
					msg = "ERROR: User is not configured for eRx. For more assistance, contact OneConnectPoint support."
				}
				
				this._statusMessageService.changeStatusMessage("error", msg, 15000);
			}
		);
	}

	getCusERxUrl() {
		this._rxService.getCustomerERxUrl().then(
			(url: any) => {
				this.erxUrl = url;
				this.sourceUrlSet = true;
			},
			(error: any) => {
				this.isLoading = false;
				this._statusMessageService.changeStatusMessage("error", error);
			}
		);
	}

	onLoad() {
		if (this.sourceUrlSet) {
			this.isLoading = false;
		}
	}

	isSafari(): boolean {
		return true;
		// const userAgent = window.navigator.userAgent.toLowerCase();
		// let isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
		//
		// isSafari = isSafari || /iphone|ipod|ipad/.test(userAgent);
		//
		// console.log("Is Safari - " + isSafari);
		// return isSafari;
	}

	detectiOS() {
		const iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
		if (!!navigator.platform) {
			while (iDevices.length) {
				if (navigator.platform === iDevices.pop()) {
					console.log("isIos = true");
					return (this.isIos = true);
				}
			}
		}
		console.log("isIos = false");
		return (this.isIos = false);
	}

	closeModal() {
		this._router.navigate(["../detail"], { relativeTo: this._route });
	}

	goBack() {
		this._location.back();
	}
}
