
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {PatientCareStore} from "./services/patient-care.store";
import {ActivatedRoute, Params} from "@angular/router";
import {Subject} from "rxjs";
import { UtilitiesService } from '../shared/services/utilities.service';
import { StatusMessageService } from '../shared/components/status-message/status-message.service';

@Component({
    template: "<router-outlet></router-outlet>"
})
export class PatientLayoutComponent implements OnInit, OnDestroy{

    private _patientId:number;
    private destroyed: Subject<void> = new Subject<void>();

    constructor(
        private _store:PatientCareStore,
        private _activeRoute:ActivatedRoute,
        private _util:UtilitiesService,
        private _statusMessageService:StatusMessageService
    ){}

    ngOnInit(){
        this._activeRoute.params.pipe(takeUntil(this.destroyed)).subscribe((params:Params) => {
            this._patientId = params["patientId"];
            this._store.loadPatientProfile(this._patientId);
        });

        this._store.PediatricRestricted.pipe(takeUntil(this.destroyed)).subscribe(v => {
            if (v){
                this._statusMessageService.changeStatusMessage("error", "eRx is disabled for this pediatric patient due to missing height/weight info.");
            }
        })
    }

    ngOnDestroy(){
        this.destroyed.next();
        this.destroyed.unsubscribe();
    }
}