import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { FormulariesStore } from "src/app/ocp/shared/services/formularies.store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DocumentInfoModel } from "src/app/ocp/shared/models/document-info.model";
import { ClinicalResourcesService } from "../../services/clinical-resources.service";
import { ModalComponent } from "../modal/modal";
import { StatusMessageService } from "../status-message/status-message.service";
import { ApiResult } from "../../models/api-result.model";

@Component({
	selector: "customer-formularies",
	templateUrl: "./customer-formularies.component.html",
	styleUrls: ["./customer-formularies.component.scss"]
})
export class CustomerFormulariesComponent implements OnInit, OnDestroy {
	formularies: DataTableModel = new DataTableModel([], []);
	isIos: boolean = false;
	documentToEdit: DocumentInfoModel;

	@ViewChild("editModal") editModal: ModalComponent;
	@ViewChild("confirmDeleteModal") confirmDeleteModal: ModalComponent;

	constructor(public formulariesStore: FormulariesStore, private _clinicalResourcesService: ClinicalResourcesService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.formulariesStore.refresh();
		this.initTable();
	}

	private _destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	initTable() {
		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "OriginalFileName",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("OriginalFileName", "Name", "", true));
		columns.push(new DataTableColumnModel("PublicationDate", "Published", "", true));
		columns.push(new DataTableColumnModel("UploadedDateTimeUtc", "Uploaded", "", true));
		columns.push(new DataTableColumnModel("actions", "", "", false));

		this.formularies = new DataTableModel(defaultSorting, columns);

		this.formulariesStore.formularies.observable.pipe(takeUntil(this._destroyed)).subscribe((formularies: DocumentInfoModel[]) => {
			this.formularies.populate(formularies);
		});
	}

	downloadFormulary(formulary) {
		this._clinicalResourcesService.getDocuments(formulary.DocumentId, null).subscribe((result: DocumentInfoModel[]) => {
			if (!result.length) return;
			let doc = result[0];
			this._clinicalResourcesService.downloadDocument(formulary.DocumentId, true).then(
				(result: Blob) => {
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(result, doc.OriginalFileName);
					} else {
						let a = document.createElement("a");
						document.body.appendChild(a);
						a.style.display = "none";

						let url = window.URL.createObjectURL(result);
						a.href = url;
						a.setAttribute("download", doc.OriginalFileName);
						a.click();
						window.URL.revokeObjectURL(url);
						document.body.removeChild(a);
					}
				},
				error => {
					console.log(error);
				}
			);
		});
	}

	previewFormulary(formulary: DocumentInfoModel) {
		this._clinicalResourcesService.getDocuments(formulary.DocumentId, null).subscribe((result: DocumentInfoModel[]) => {
			if (!result.length) return;
			let doc = result[0];
			this._clinicalResourcesService.downloadDocument(formulary.DocumentId, true).then(
				(result: Blob) => {
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(result, doc.OriginalFileName);
					} else {
						let fileURL = URL.createObjectURL(result);
						if (this.isIos) {
							window.open(fileURL, "_blank");
						} else {
							window.open(fileURL);
						}
					}
				},
				error => {
					console.log(error);
				}
			);
		});
	}

	editFormulary(formulary: DocumentInfoModel) {
		this.formulariesStore.mode.set("edit");
		this.formulariesStore.selected.set(Object.assign(new DocumentInfoModel(), formulary));
		this.editModal.open("md");
	}

	newFormulary() {
		this.formulariesStore.mode.set("new");
		this.formulariesStore.selected.set(new DocumentInfoModel());
		this.editModal.open("md");
	}

	deleteFormulary(formulary: DocumentInfoModel) {
		this.formulariesStore.selected.set(formulary);
		this.confirmDeleteModal.open("xs");
	}

	confirmDelete() {
		this.confirmDeleteModal.close();
		let id = this.formulariesStore.selected.get().DocumentId;
		this._clinicalResourcesService.removeDocument(id).subscribe((result: ApiResult) => {
			if (result && result.Success) {
				this._statusMessageService.changeStatusMessage("success", "Formulary deleted successfully.");
			} else {
				this._statusMessageService.changeStatusMessage("error", "Error deleting formulary.");
			}
			this.formulariesStore.refresh();
		});
	}

	fileEditSuccess(fileName: string) {
		let mode = this.formulariesStore.mode.get();
		if (mode === "new") {
			this.formulariesStore.mode.set(null);
			this.editModal.dismiss();
			this._statusMessageService.changeStatusMessage("success", '"' + fileName + '" successfully added.');
		} else {
			this.closeEditModal();
			this._statusMessageService.changeStatusMessage("success", '"' + fileName + '" successfully updated.');
		}
		this.formulariesStore.refresh();
	}

	closeEditModal() {
		this.formulariesStore.mode.set(null);
		this.editModal.dismiss();
	}

	detectiOS() {
		const iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
		if (!!navigator.platform) {
			while (iDevices.length) {
				console.log(navigator.platform);
				if (navigator.platform === iDevices.pop()) {
					console.log("isIos = true");
					return (this.isIos = true);
				}
			}
		}
		console.log("isIos = false");
		return (this.isIos = false);
	}
}
