// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .scroll{
    padding-top: 0;    
    overflow:scroll;
    }
    
    .scrollLess{
    padding-top: 0;
    max-height:60%;
    }

    .overflow-visible {
        overflow: visible !important;
    }
    `, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/modal/modal-body.component.ts"],"names":[],"mappings":";IACI;IACA,cAAc;IACd,eAAe;IACf;;IAEA;IACA,cAAc;IACd,cAAc;IACd;;IAEA;QACI,4BAA4B;IAChC","sourcesContent":["\n    .scroll{\n    padding-top: 0;    \n    overflow:scroll;\n    }\n    \n    .scrollLess{\n    padding-top: 0;\n    max-height:60%;\n    }\n\n    .overflow-visible {\n        overflow: visible !important;\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
