import {Component, OnInit} from '@angular/core';
import {DocumentTypeModel} from "../../../shared/models/document-type.model";
import {ClinicalResourcesService} from "../../../shared/services/clinical-resources.service";

@Component({
  selector: 'symptom-guide-tab',
  templateUrl: './symptom-guide.template.html',
  styleUrls: ['./clinical-resources-tabs.scss']
})

export class SymptomGuideTab  implements OnInit {
  private _documentTypeId: number = 4; // SymptomGuide
  documents: DocumentTypeModel[] = [];

  constructor(private _clinicalResourcesService: ClinicalResourcesService) {}

  ngOnInit() {
    this._clinicalResourcesService.getDocuments(null, this._documentTypeId).subscribe((documents: DocumentTypeModel[])=> {
      this.documents = documents;
    });
  }
  viewDocument() {

  }
}
