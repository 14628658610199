// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1.375rem;
  vertical-align: top;
}
@media screen and (min-width: 768px) {
  .block {
    width: 49.7%;
    margin-bottom: 1.125rem;
    padding: 0 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/ocp/shared/components/form-user-details/user-customer-permissions/customer-permission/customer-permission.scss","webpack://./src/assets/styles/imports/_breakpoints.scss"],"names":[],"mappings":"AAKA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;AAJF;AC2BE;ED5BF;IAQI,YAAA;IACA,uBAAA;IACA,iBAAA;EAHF;AACF","sourcesContent":["@import '../../../../../../../assets/styles/imports/breakpoints';\r\n@import '../../../../../../../assets/styles/imports/settings';\r\n@import '../../../../../../../assets/styles/imports/mixins';\r\n@import '../../../../../../../assets/styles/imports/functions';\r\n\r\n.block {\r\n  position: relative;\r\n  display: inline-block;\r\n  width: 100%;\r\n  margin-bottom: rem-calc(22px);\r\n  vertical-align: top;\r\n\r\n  @include min-screen($medium) {\r\n    width: 49.7%;\r\n    margin-bottom: rem-calc(18px);\r\n    padding: 0 rem-calc(8px);\r\n  }\r\n}\r\n","//***********************************************\r\n// ~* BREAKPOINTS\r\n//***********************************************\r\n$global-width: 1060px;\r\n\r\n$xsmall: 0px;\r\n$small: 412px;\r\n$smallMax: 576px;\r\n$medium: 768px;\r\n$large: 960px;\r\n$xlarge: 1060px;\r\n\r\n@mixin screen($res-min, $res-max, $orientation: false) {\r\n  @if $orientation {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max)\r\n    and (orientation:#{$orientation}) {\r\n      @content;\r\n    }\r\n  }\r\n  @else {\r\n    @media screen and (min-width: $res-min) and (max-width: $res-max) {\r\n      @content;\r\n    }\r\n  }\r\n}\r\n\r\n@mixin max-screen($res) {\r\n  @media screen and (max-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin min-screen($res) {\r\n  @media screen and (min-width: $res) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin print() {\r\n  @media print {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
