import { Component, ViewChild, OnInit, OnDestroy, Input, ChangeDetectorRef } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { CustomerService } from "../../../shared/services/customer.service";
import { CustomerProfileModel } from "../../../shared/models/customer-profile.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { Permissions } from "../../enums/permissions";
import { UserListItemModel } from "../../models/user-list-item.model";
import { UserProfile } from "../../models/user-profile.model";
import { CustomerTeamModel } from "../../models/customer-team.model";
import { AuthService } from "../../services/auth.service";
import { FormTeamDetails } from "../form-team-details/form-team-details.component";
import { UserService } from "../../services/user.service";
import { BulkUserUploadModalComponent } from "../bulk-user-upload/bulk-user-upload-modal.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { OrganizationProfile } from "../../models/organization-profile.model";
import { MatTabGroup, MatTabChangeEvent } from "@angular/material/tabs";
import { MobileService } from "../../services/mobile.service";
import * as _ from "lodash";
import { RoutingRedirectService } from "../../services/routing-redirect.service";

@Component({
	selector: "customer-profile",
	templateUrl: "./customer-profile.template.html",
	styleUrls: ["./customer-profile.scss", "../../../partner-profile/tabs/partner-profile-tabs.scss"]
})
export class CustomerProfileComponent implements OnInit, OnDestroy {
	isInternalUser: boolean = false;
	displayTab: string = "profile";
	teamFilter: string[] = ["active"];
	customerTeams: CustomerTeamModel[] = [];
	filteredTeams: CustomerTeamModel[] = [];
	usersFilter: string[] = ["active"];
	userSearchText: UntypedFormControl = new UntypedFormControl();
	users: UserListItemModel[] = [];
	filteredUsers: UserListItemModel[] = [];
	modalTitle: string;
	subHeaderText: string;
	permissions: any = Permissions;
	userToEdit: UserProfile = null;
	customerProfile: CustomerProfileModel = new CustomerProfileModel();
	updateProfile: CustomerProfileModel;
	editMode: string;
	enableDoseSpot: boolean = false;
	loading: boolean = true;
	private _destroyed: Subject<void> = new Subject<void>();

	organizationId: number;
	orgName: string = "";
	customerId: number;
	isAdminSection: boolean;
	links = ["profile", "users", "teams", "roles"];
	activeLink = this.links[0];

	sales: boolean = false;

	@Input() canGoBack: boolean = true;

	@ViewChild("editModal") editModal: ModalComponent;
	@ViewChild("createModal") createModal: ModalComponent;
	@ViewChild("bulkUserUploadModal") bulkUserUploadModal: BulkUserUploadModalComponent;
	@ViewChild("createTeamModal") createTeamModal: FormTeamDetails;
	@ViewChild("confirmUpdateStatus") confirmUpdateStatus: ModalComponent;
	@ViewChild("tabGroup")
	private _tabGroup: MatTabGroup;

	get canAddTeams(): boolean {
		return this._authService.hasPermission(this.permissions.editCustomerTeams) || this._authService.hasPermission(this.permissions.administration);
	}

	constructor(
		private _customerService: CustomerService,
		private _authService: AuthService,
		private _userService: UserService,
		private _statusMessageService: StatusMessageService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _mobile: MobileService,
		private _cdr: ChangeDetectorRef,
		private _redirectService: RoutingRedirectService
	) {
		this._redirectService.loadErxTab.pipe(takeUntil(this._destroyed)).subscribe(() => {
			setTimeout(() => {
				this.changeToErxTab();
			}, 600);
		});
	}

	ngOnInit() {
		if (!this._authService.hasPermission(this.permissions.partnerProfile)) {
			if (this._authService.hasPermission(this.permissions.viewCustomerIntegrations)) {
				this.links = ["integrations"];
				this.displayTab = "integrations";
				this.sales = true;
			}

			this.loading = false;
		} else {
			if (this._authService.hasPermission(this.permissions.viewCustomerIntegrations)) {
				this.links.push("integrations");
			}

			if (!this._mobile.isMobile()) {
				this.links.push("formularies");
			}
		}

		this.userSearchText.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((term: string) => {
			this.filterUserList(term);
		});

		this._route.params.pipe(takeUntil(this._destroyed)).subscribe((p: Params) => {
			this.organizationId = +p["id"];
			this.customerId = +p["partnerId"];

			if (this.organizationId && this.customerId) {
				this.isAdminSection = true;
			} else {
				this.isAdminSection = false;
			}

			if (this.isAdminSection) {
				this.links = _.filter(this.links, (link: string) => {
					return link !== "integrations";
				});
			}

			this.loadSelectedCustomer(false);
			this.refreshTab();
			this.isInternalUser = this._userService.isInternalUser();
		});
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	refreshTab() {
		if (this.isAdminSection) {
			if (this.displayTab === "users") {
				this.refreshUsers();
			}

			if (this.displayTab === "teams") {
				this.refreshTeams();
			}
		}
	}

	loadSelectedCustomer(updateCustomer: boolean) {
		this._customerService.getCustomerProfile(this.customerId).subscribe((profile: CustomerProfileModel) => {
			if (profile) {
				this.customerProfile = profile;

				if (this.customerProfile.IsDoseSpotActive && this.isAdminSection) {
					this.links.push("erx");
					if (this._shouldGoToErxTab) {
						this.changeToErxTab();
					}
				}

				this.customerId = profile.CustomerId;
				if (profile.OrganizationId && !this.sales) {
					this._customerService.getOrganizationProfile(profile.OrganizationId).subscribe((orgProfile: OrganizationProfile) => {
						this.orgName = orgProfile.Name;
					});
				}
				if (this.customerProfile.ClinicId > 0) {
					this.enableDoseSpot = true;
				}

				if (updateCustomer) {
					this._userService.selectedCustomer = profile;
				}
			}
		});
	}

	refreshUsers() {
		this._customerService.getCustomerUsers(this.customerId).subscribe((users: UserListItemModel[]) => {
			this.users = users;
			this.filterUserList(this.userSearchText.value || "");
		});
	}

	filterUserList(nameFilter: string) {
		nameFilter = nameFilter || "";
		let self = this;
		let users: UserListItemModel[] = this.users.filter((user: UserListItemModel) => {
			let nameMatches: boolean = false;

			if (!nameFilter) {
				nameMatches = true;
			} else {
				nameFilter = nameFilter
					.trim()
					.replace(/\s\s+/g, " ")
					.replace(",", "")
					.toLowerCase();
				let searchNameParts: string[] = nameFilter.split(" ");
				let matchPartsCount: number = 0;
				searchNameParts.forEach((part: string) => {
					if (user.FirstName.toLowerCase().indexOf(part) > -1 || user.LastName.toLowerCase().indexOf(part) > -1 || user.LoginId.toLowerCase().indexOf(part) > -1) {
						matchPartsCount++;
					}
				});

				if (matchPartsCount === searchNameParts.length) {
					nameMatches = true;
				}
			}

			if (nameMatches) {
				if (self.usersFilter.indexOf("active") !== -1 && !user.IsLocked) {
					return true;
				}

				if (self.usersFilter.indexOf("locked") !== -1 && user.IsLocked) {
					return true;
				}
			}

			return false;
		});

		users.sort((a, b) => {
			if (a.LastName < b.LastName) return -1;

			if (a.LastName > b.LastName) return 1;

			return 0;
		});

		this.filteredUsers = users;
	}

	updateUserFilter(checked: boolean, value: string) {
		if (!checked) {
			this.usersFilter.splice(this.usersFilter.indexOf(value), 1);
		} else if (this.usersFilter.indexOf(value) === -1) {
			this.usersFilter.push(value);
		}

		this.filterUserList(this.userSearchText.value || "");
	}

	refreshTeams() {
		this._customerService.getCustomerTeamDetails(this.customerId, true, true).subscribe((teams: any[]) => {
			this.customerTeams = teams;
			this.filterTeamList();
		});
	}

	filterTeamList() {
		let self = this;
		let filteredTeams: CustomerTeamModel[] = this.customerTeams.filter((x: CustomerTeamModel) => {
			if (self.teamFilter.indexOf("active") !== -1 && x.IsActive) {
				return true;
			}

			if (self.teamFilter.indexOf("inactive") !== -1 && !x.IsActive) {
				return true;
			}

			return false;
		});

		filteredTeams.sort((a, b) => {
			if (a.TeamName < b.TeamName) return -1;

			if (a.TeamName > b.TeamName) return 1;

			return 0;
		});

		this.filteredTeams = filteredTeams;
	}

	updateTeamFilter(checked: boolean, value: string) {
		if (!checked) {
			this.teamFilter.splice(this.teamFilter.indexOf(value), 1);
		} else if (this.teamFilter.indexOf(value) === -1) {
			this.teamFilter.push(value);
		}

		this.filterTeamList();
	}

	updatePartner() {
		this.editMode = "editPartner";
		this.editModal.open("lg");
		this.modalTitle = "Edit Partner";
		this.subHeaderText = "for " + this.customerProfile.Name;
		this.updateProfile = Object.assign({}, this.customerProfile);
	}

	editDoseSpot() {
		this.editMode = "editDoseSpot";
		this.editModal.open("xs");
		this.modalTitle = "Edit DoseSpot";
		this.subHeaderText = "for " + this.customerProfile.Name;
	}

	closeEditModal() {
		this.editModal.dismiss();
	}

	updateProfileSuccess() {
		this.closeEditModal();
		this._statusMessageService.changeStatusMessage("success", 'Profile for "' + this.customerProfile.Name + '" updated successfully.');
		this.loadSelectedCustomer(this._userService.selectedCustomerId === this.customerProfile.CustomerId);
	}

	confirmUpdateCustomerStatus() {
		this.confirmUpdateStatus.open("xs");
	}

	updateCustomerStatus(isActive: boolean) {
		this._customerService.updateCustomerStatus(this.customerProfile.CustomerId, isActive).subscribe((result: any) => {
			if (result.Success) {
				this.customerProfile.IsActive = isActive;
				this._statusMessageService.changeStatusMessage("success", this.customerProfile.Name + " has been " + (isActive ? "activated" : "deactivated") + ".");
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
		this.confirmUpdateStatus.dismiss();
	}

	addUser() {
		this.userToEdit = new UserProfile();
		this.createModal.open("md");
		this.modalTitle = "New User";
		this.editMode = "addUser";
	}

	userSaveSuccess() {
		this.userToEdit = null;
		this.createModal.dismiss();
		this.refreshUsers();
	}

	addTeam() {
		this.editMode = "addTeam";
		this.createTeamModal.showDialog(null);
	}

	teamSaveSuccess(successMessage: string) {
		if (successMessage) {
			this._statusMessageService.changeStatusMessage("success", successMessage);
		}
		this.createModal.dismiss();
		this.refreshTeams();
	}

	updateTab(tabChangeEvent: MatTabChangeEvent) {
		switch (tabChangeEvent.tab.textLabel) {
			case "profile":
				this.showProfile();
				break;
			case "users":
				this.showUsers();
				break;
			case "teams":
				this.showTeams();
				break;
			case "roles":
				this.showRoles();
				break;
			case "integrations":
				this.showIntegrations();
				break;
			case "formularies":
				this.showFormularies();
				break;
			case "subscriptions":
				this.showSubscriptions();
				break;
			case "erx":
				this.showPreferredErx();
				break;
		}
	}

	showProfile() {
		this.displayTab = "profile";
	}

	showUsers() {
		this.refreshUsers();
		this.displayTab = "users";
	}

	showTeams() {
		this.refreshTeams();
		this.displayTab = "teams";
	}

	showRoles() {
		this.displayTab = "roles";
	}

	showAuthentication() {
		this.displayTab = "authentication";
	}

	showFormularies() {
		this.displayTab = "formularies";
	}

	showIntegrations() {
		this.displayTab = "integrations";
	}

	showSubscriptions() {
		this.displayTab = "subscriptions";
	}

	showPreferredErx() {
		this.displayTab = "erx";
	}

	private _shouldGoToErxTab: boolean = false;
	changeToErxTab() {
		console.log("CHANGE");
		let idx = this.links.indexOf("erx");
		if (idx >= 0) {
			this._shouldGoToErxTab = false;
			this._tabGroup.selectedIndex = idx;
		} else {
			this._shouldGoToErxTab = true;
		}
	}

	onEditClose() {
		this.userToEdit = null;
		this.editMode = null;
	}

	updateSubHeaderText(event) {
		this.subHeaderText = event;
		this._cdr.detectChanges();
	}

	bulkUpload() {
		this.bulkUserUploadModal.showDialog();
	}

	clearSearch() {
		this.userSearchText.setValue("");
	}

	goBack() {
		this._router.navigate(["../../"], { relativeTo: this._route });
	}

	loadPreferredErxForm(e: any) {
		console.log(e);
		this._router.navigate(["preferred-erx"], { relativeTo: this._route, queryParams: { nickname: e.nickname, preferenceId: e.preferenceId } });
	}

	editErxSettings() {
		this.editMode = "erxSettings";
		this.editModal.open("xs");
		this.modalTitle = "Hospice eRx Settings";
		this.subHeaderText = "";
	}

	updateErxSettingsSuccess() {
		this.closeEditModal();
		this._statusMessageService.changeStatusMessage("success", 'eRx settings for "' + this.customerProfile.Name + '" updated successfully.');
	}
}
