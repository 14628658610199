import {Component, Input, OnInit} from "@angular/core";
import {DrugGroupSummaryModel} from "../../../../shared/models/drug-group-summary.model";

@Component({
  selector: "therapeutic-duplication",
  templateUrl: "./therapeutic-duplication.template.html",
  styleUrls: ["./plan-of-treatment-tabs.scss"]
})

export class TherapeuticDuplicationComponent implements OnInit {
  drugGroups: any[] = [];

  @Input() drugGroupSummary: DrugGroupSummaryModel[] = [];

  constructor() {

  }

  ngOnInit() {
    let self = this;
    this.drugGroupSummary.forEach((summary: DrugGroupSummaryModel) => {
      let drugGroup = self.drugGroups.find(x => {
        return x.gpiDrugGroup === summary.GPIDrugGroup
      });

      if (!drugGroup) {
        drugGroup = {
          gpiDrugGroup: summary.GPIDrugGroup,
          gpiDrugGroupName: summary.GPIDrugGroupName,
          drugClasses: []
        };

        self.drugGroups.push(drugGroup);
        self.drugGroups.sort((a, b) => {
          return a.gpiDrugGroup.localeCompare(b.gpiDrugGroup);
        });
      }

      let drugClass = drugGroup.drugClasses.find(x => {
        return x.gpiDrugClass === summary.GPIDrugClass;
      });

      if (!drugClass) {
        drugClass = {
          gpiDrugClass: summary.GPIDrugClass,
          gpiDrugClassName: summary.GPIDrugClassName,
          drugList: []
        };

        drugGroup.drugClasses.push(drugClass);
        drugGroup.drugClasses.sort((a: any, b: any) => {
          return a.gpiDrugClass.localeCompare(b.gpiDrugClass);
        });
      }

      if (!drugClass.drugList.find(x => {
          return x.drugName === summary.DrugName;
        })) {
        drugClass.drugList.push({
          drugName: summary.DrugName,
          rxType: summary.RxType
        });

        drugClass.drugList.sort((a: any, b: any) => {
          return a.drugName.localeCompare(b.drugName);
        });
      }
    });
  }
}
