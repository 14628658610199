import { Component, Input, ViewChild, OnInit, TemplateRef, OnDestroy } from "@angular/core";
import { ModalComponent } from "../shared/components/modal/modal.component";
import { PatientInfoModel } from "../shared/models/patient.model";
import { ISearchService } from "../shared/components/patient-search/search.interface";
import { PatientService } from "../shared/services/patient.service";
import { PatientSearchService } from "./patient-search.service";
import { UserService } from "../shared/services/user.service";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { PatientResult } from "../shared/models/patient-result.model";
import { PatientCareService } from "../shared/services/patient-care.service";
import { AdmitPatientModalComponent } from "./components/admit-patient-modal/admit-patient-modal.component";
import { ApiResult } from "../shared/models/api-result.model";
import { CustomerProfileModel } from "../shared/models/customer-profile.model";
import { PatientSearch } from "../shared/components/patient-search/patient-search.component";
import { ContextualPopupService } from "../shared/services/contextual-popup.service";
import { AuthService } from "../shared/services/auth.service";
import { PatientCareStore } from "./services/patient-care.store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RxService } from "../shared/services/rx.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Permissions } from "../shared/enums/permissions";
import { MobileService } from "../shared/services/mobile.service";
import { ErxOrderStore } from "../shared/services/erx-order.store";

@Component({
	selector: "patient-care",
	providers: [{ provide: ISearchService, useClass: PatientSearchService }],
	templateUrl: "./patient-care.template.html",
	styleUrls: ["./patient-care.scss"]
})
export class PatientCare implements OnInit, OnDestroy {
	@ViewChild("filterSearchPopup") filterSearchPopup: any; // desktop
	@ViewChild("mobileDoseSpotPopup") mobileDoseSpotPopup: any;
	@ViewChild("patientAdmissionsModal") patientAdmissionsModal: ModalComponent;
	@ViewChild("quickRefill") quickRefill: ModalComponent;
	@ViewChild("admitPatientModal") admitPatientModal: AdmitPatientModalComponent;
	@ViewChild("confirmDeletePreAdmit") confirmDeletePreAdmit: ModalComponent;
	@ViewChild("filterSearchModal") filterSearchModal: ModalComponent;
	@ViewChild("dispenseHistoryModal") dispenseHistoryModal: ModalComponent;
	@ViewChild("search") search: PatientSearch;
	@ViewChild("doseSpotLink") doseSpotLink: any;

	currentCustomerId: number;
	isLoading: boolean = false;
	patientAdmissionsModalLoading: boolean = false;
	step: string = "Five";
	subHeaderText: string = "";
	customers: any[] = [];
	results: PatientResult[] = [];
	favoriteResults: PatientResult[] = [];
	patientSearchPlaceholder: string = "";
	showingFavorites: boolean = true;
	admissionsModalHeaderText: string;
	admissionsModalHeaderSubText: string;
	editPatientAdmissionsType: string;
	selectedPatient: PatientResult = null;
	editAdmissionsPatient: PatientInfoModel = null;
	noResultsFound: boolean = false;
	searchTextUsed: string = "";

	showFilterBtn: boolean = false;
	showTeamFilter: boolean = true;

	doseSpotEnabled: boolean = false;
	newErxURLError: string = null;
	doseSpotUrl: any;
	permissions: any = Permissions;

	isMobile: boolean = false;

	private _destroyed: Subject<void> = new Subject<void>();

	get allowNewErx(): boolean {
		return this.hasDosespotAndERxPermissions && !this.patientCareStore.getIsPediatricRestricted();
	}

	get hasDosespotAndERxPermissions(): boolean {
		return this.doseSpotEnabled && this._authService.hasPermission(this.permissions.submitERx);
	}

	get disableFilter(): boolean {
		return this.results.length === 0 && !this.showingFavorites;
	}

	get filtering(): boolean {
		return this.numFiltersApplied === 0;
	}

	get numFiltersApplied(): number {
		return this.patientCareStore.numFiltersApplied;
	}

	get filterBtnText(): string {
		if (this.numFiltersApplied > 0) {
			return "Filters (" + this.numFiltersApplied + ")";
		}
		return "Add Filter";
	}

	get showNoResultsText(): boolean {
		if (this.isLoading || this.showingFavorites) return false;

		let numDisplayed = (this.patientCareStore.displayedPatients.get() || []).length;

		return numDisplayed === 0;
	}

	@Input() patient: PatientInfoModel = new PatientInfoModel();

	constructor(
		public patientCareStore: PatientCareStore,
		private _userService: UserService,
		private _statusMessageService: StatusMessageService,
		private _patientService: PatientService,
		private _patientCareService: PatientCareService,
		private _popupService: ContextualPopupService,
		private _authService: AuthService,
		private _rxService: RxService,
		private _router: Router,
		private _activeRoute: ActivatedRoute,
		private _mobile: MobileService,
		private _erxStore: ErxOrderStore
	) {
		this.isMobile = this._mobile.isMobile();
	}

	ngOnInit() {
		this.patientCareStore.clearPatient();
		this._userService.selectedCustomer.pipe(takeUntil(this._destroyed)).subscribe((customer: CustomerProfileModel) => {
			this.searchCleared();
			this.clearFilters();
			if (customer) {
				this.showTeamFilter = customer.HasTeams;
			} else {
				this.showTeamFilter = false;
			}
			if (customer && this.currentCustomerId !== customer.CustomerId) {
				this.currentCustomerId = customer.CustomerId;
				this.loadFavorites();
			}
		});

		if (window.innerWidth < 500) {
			this.patientSearchPlaceholder = "Search patients...";
		} else {
			this.patientSearchPlaceholder = "Search patients by name and/or MRN...";
		}

		this._userService.doseSpotEnabled.pipe(takeUntil(this._destroyed)).subscribe((enabled: boolean) => {
			this.doseSpotEnabled = enabled;
		});
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	getFilterIcon(type: string) {
		switch (type) {
			case "team":
				return "group";
			case "firstName":
				return "person";
			case "lastName":
				return "person";
			case "city":
				return "location_on";
			case "state":
				return "location_on";
			case "county":
				return "location_on";
			default:
				return "";
		}
	}

	openMobileFilters() {
		this.filterSearchModal.open("md");
	}

	private _filterRef: any;
	openDesktopFilters(content: TemplateRef<any>, origin: any) {
		this._filterRef = this._popupService.open<{}>({
			content,
			origin,
			width: "375px",
			data: {}
		});

		this._filterRef.afterClosed$.pipe(takeUntil(this._destroyed)).subscribe(res => {
			if (res.data != "ignore") this.patientCareStore.setPreferredSearchTeams();
			this.patientCareStore.applySearchFilter();
		});
	}

	clearFilters() {
		this.patientCareStore.clearSearchFilter();
	}

	resetMobileFilters() {
		this.clearFilters();
		this.filterSearchModal.dismiss();
	}

	resetDesktopFilters() {
		this.clearFilters();
		if (this._filterRef) {
			this._filterRef.close();
		}
	}

	loadFavorites() {
		this.isLoading = true;
		this.noResultsFound = false;
		this._userService.getFavorites().subscribe((results: any[]) => {
			this.isLoading = false;
			this.results = this.sortPatients(results);
			this.search.results = results;
			this.favoriteResults = results;
			this.showingFavorites = true;
		});
	}

	openAddNewPatient() {
		this._patientCareService.setComplete(false);
		this.admitPatientModal.showDialog(null);
	}

	editPatientAdmissions(params: any) {
		this.editPatientAdmissionsType = params.admissionsType;

		if (this.editPatientAdmissionsType === "admit") {
			this.admitPatientModal.showDialog(params.patientId);
		} else {
			this._patientService.getProfileModel(params.patientId).subscribe((patientModel: PatientInfoModel) => {
				this.editAdmissionsPatient = patientModel;
				this.admissionsModalHeaderText = "Discharge Patient";
				this.admissionsModalHeaderSubText = " - " + patientModel.fullName();
				this.patientAdmissionsModal.open("xs");
			});
		}
	}

	closePatientAdmissions() {
		this.editAdmissionsPatient = null;
		this.patientAdmissionsModal.dismiss();
	}

	reloadList() {
		this.noResultsFound = false;
		if (this.showingFavorites) {
			this.loadFavorites();
		}
	}

	searchCleared() {
		this.loadFavorites();
		this.showFilterBtn = false;
		this.clearFilters();
	}

	showPatients(patients: PatientResult[]) {
		this.noResultsFound = false;
		if (patients && patients.length === 0) {
			this.noResultsFound = true;
		}

		this.showingFavorites = false;
		this.results = this.sortPatients(patients);
		this.showFilterBtn = true;
	}

	toggleFavorite(patient: any) {
		let originalValue = patient.IsFavorite;
		patient.IsFavorite = !patient.IsFavorite;
		this._patientService.updateFavoritePatient(patient.PatientID, patient.IsFavorite).subscribe((results: ApiResult) => {
			if (results.Success) {
				if (patient.IsFavorite) {
					this._statusMessageService.changeStatusMessage("success", patient.FirstName + " " + patient.LastName + " has been added to your Favorites list.");
				} else {
					this._statusMessageService.changeStatusMessage("success", patient.FirstName + " " + patient.LastName + " has been removed from your Favorites list.");
				}
			} else {
				patient.IsFavorite = originalValue;
				this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
			}
		});
	}

	manageERxUrl: any;
	manageERxError: string;

	@ViewChild("manageERxLink")
	manageERxLink: any;
	openNewERxOrder(patient: PatientResult) {
		this.isLoading = true;
		if (this._mobile.isMobile()) {
			this.manageERxUrl = null;
			this.manageERxError = null;

			this.mobileDoseSpotPopup.open("md");
			this.subHeaderText = " for " + patient.FirstName + " " + patient.LastName;

			this._rxService.getDoseSpotURLForNewERX(patient.PatientID).then(
				(url: any) => {
					if (url) {
						this.manageERxUrl = url.changingThisBreaksApplicationSecurity;
						this.manageERxLink.nativeElement.href = this.doseSpotUrl;
						this.manageERxError = null;
					} else {
						this.manageERxError = "Unknown error";
					}

					if (this.manageERxError) {
						this._statusMessageService.changeStatusMessage("error", this.manageERxError, 15000);
						this.mobileDoseSpotPopup.dismiss();
					}

					this.isLoading = false;
				},
				err => {
					this.isLoading = false;
					this.manageERxError = err;
					this._statusMessageService.changeStatusMessage("error", this.manageERxError, 15000);
					this.mobileDoseSpotPopup.dismiss();
				}
			);
		} else {
			this._router.navigate(["./patient-details/" + patient.PatientID + "/erx"], { relativeTo: this._activeRoute });
		}
	}

	openNewERx(patient: PatientResult) {
		this._erxStore.resetSelectedErx();
		this._erxStore.mode.set("new");
		this._router.navigate(["./patient-details/" + patient.PatientID + "/new-erx-order"], { relativeTo: this._activeRoute });
	}

	goToMobileERx() {
		this.manageERxLink.nativeElement.click();
		this.mobileDoseSpotPopup.dismiss();
	}

	openDispenseHistory(patient: PatientResult) {
		this.dispenseHistoryModal.open("lg");
		this.selectedPatient = patient;
		this.subHeaderText = " for " + this.selectedPatient.FirstName + " " + this.selectedPatient.LastName;
	}

	closeDispenseHistory() {
		this.dispenseHistoryModal.close();
	}

	openQuickRefill(patient: PatientResult) {
		this.selectedPatient = patient;
		this.quickRefill.open("md");
		this.subHeaderText = " for " + this.selectedPatient.FirstName + " " + this.selectedPatient.LastName;
	}

	quickRefillClose() {
		this.selectedPatient = null;
	}

	openDeletePreAdmit(patient: PatientResult) {
		this.selectedPatient = patient;
		this.confirmDeletePreAdmit.open("xs");
	}

	cancelDeletePreAdmit() {
		this.confirmDeletePreAdmit.dismiss();
		this.selectedPatient = null;
	}

	deletePreAdmit(patientId: number) {
		this._patientService.removePreAdmitPatient(patientId).subscribe((result: any) => {
			if (result.Success) {
				this._statusMessageService.changeStatusMessage("success", "Preadmit patient successfully removed.");
				this.loadFavorites();
			} else this._statusMessageService.changeStatusMessage("error", "Error removing preadmit patient");
		});
		this.confirmDeletePreAdmit.dismiss();
	}

	closeMobilSearchFilterModal() {
		this.filterSearchModal.close();
		this.patientCareStore.applySearchFilter();
		this.patientCareStore.setPreferredSearchTeams();
	}

	private sortPatients(patients: PatientResult[]): PatientResult[] {
		if (!patients || patients.length === 0) {
			return [];
		}

		const sortedByName = patients.sort((a: PatientResult, b: PatientResult) => {
			return (a.LastName || "").toLowerCase().localeCompare((b.LastName || "").toLowerCase()) !== 0
				? (a.LastName || "").toLowerCase().localeCompare((b.LastName || "").toLowerCase())
				: (a.FirstName || "").toLowerCase().localeCompare((b.FirstName || "").toLowerCase()) !== 0
				? (a.FirstName || "").toLowerCase().localeCompare((b.FirstName || "").toLowerCase())
				: (a.MiddleName || "").toLowerCase().localeCompare((b.MiddleName || "").toLowerCase()) !== 0
				? (a.MiddleName || "").toLowerCase().localeCompare((b.MiddleName || "").toLowerCase())
				: (a.MedicalRecordNumber || "").localeCompare(b.MedicalRecordNumber || "");
		});

		return sortedByName.sort((a: PatientResult, b: PatientResult) => {
			return this.mapAdmissionStatusCode(b.AdmissionStatusCode) - this.mapAdmissionStatusCode(a.AdmissionStatusCode);
		});
	}

	// map admission codes into meaningful, sortable numbers
	private mapAdmissionStatusCode(code: number): number {
		if (code === 2 || code === 3) {
			return 0;
		} else if (code === 0) {
			return 1;
		} else {
			return 2;
		}
	}
}
